// source: account_operation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.account_operation_endpoint.DepositReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.DepositRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.DepositResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.DepositResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportStatementReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportStatementRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportStatementResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportStatementResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionsReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionsRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionsResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExportTransactionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExternalTransferReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExternalTransferRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExternalTransferResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.ExternalTransferResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.FeeResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetBalanceReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetBalanceRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetBalanceResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetBalanceResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetStatementReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetStatementRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetStatementResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetStatementResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionSummary', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionSummaryReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionSummaryRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionSummaryResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionSummaryResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionsReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionsRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionsResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.GetTransactionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.Label', null, global);
goog.exportSymbol('proto.account_operation_endpoint.Party', null, global);
goog.exportSymbol('proto.account_operation_endpoint.TParty', null, global);
goog.exportSymbol('proto.account_operation_endpoint.Transaction', null, global);
goog.exportSymbol('proto.account_operation_endpoint.TransactionSummary', null, global);
goog.exportSymbol('proto.account_operation_endpoint.TransferReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.TransferRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.TransferResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.TransferResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.UpdateTransactionReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.UpdateTransactionRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.UpdateTransactionResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.UpdateTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_operation_endpoint.WithdrawReplay', null, global);
goog.exportSymbol('proto.account_operation_endpoint.WithdrawRequest', null, global);
goog.exportSymbol('proto.account_operation_endpoint.WithdrawResponse', null, global);
goog.exportSymbol('proto.account_operation_endpoint.WithdrawResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.GetBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetBalanceRequest.displayName = 'proto.account_operation_endpoint.GetBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionRequest.displayName = 'proto.account_operation_endpoint.GetTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.DepositRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.DepositRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.DepositRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.DepositRequest.displayName = 'proto.account_operation_endpoint.DepositRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.GetTransactionSummaryRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionSummaryRequest.displayName = 'proto.account_operation_endpoint.GetTransactionSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionSummary.displayName = 'proto.account_operation_endpoint.GetTransactionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.GetStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetStatementRequest.displayName = 'proto.account_operation_endpoint.GetStatementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionsRequest.displayName = 'proto.account_operation_endpoint.GetTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.Label.displayName = 'proto.account_operation_endpoint.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.ExportStatementRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.ExportStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportStatementRequest.displayName = 'proto.account_operation_endpoint.ExportStatementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.ExportTransactionsRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.ExportTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportTransactionsRequest.displayName = 'proto.account_operation_endpoint.ExportTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.TransferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.TransferRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.TransferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.TransferRequest.displayName = 'proto.account_operation_endpoint.TransferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.WithdrawRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.WithdrawRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.WithdrawRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.WithdrawRequest.displayName = 'proto.account_operation_endpoint.WithdrawRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.UpdateTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.UpdateTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.UpdateTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.UpdateTransactionRequest.displayName = 'proto.account_operation_endpoint.UpdateTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.ExportTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.ExportTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportTransactionRequest.displayName = 'proto.account_operation_endpoint.ExportTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExternalTransferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.ExternalTransferRequest.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.ExternalTransferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExternalTransferRequest.displayName = 'proto.account_operation_endpoint.ExternalTransferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetBalanceReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.GetBalanceReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetBalanceReplay.displayName = 'proto.account_operation_endpoint.GetBalanceReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.FeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.FeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.FeeResponse.displayName = 'proto.account_operation_endpoint.FeeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.TransactionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.TransactionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.TransactionSummary.displayName = 'proto.account_operation_endpoint.TransactionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.GetTransactionSummaryReplay.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionSummaryReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionSummaryReplay.displayName = 'proto.account_operation_endpoint.GetTransactionSummaryReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.TransferReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.TransferReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.TransferReplay.displayName = 'proto.account_operation_endpoint.TransferReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExternalTransferReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.ExternalTransferReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExternalTransferReplay.displayName = 'proto.account_operation_endpoint.ExternalTransferReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.UpdateTransactionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.UpdateTransactionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.UpdateTransactionReplay.displayName = 'proto.account_operation_endpoint.UpdateTransactionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.DepositReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.DepositReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.DepositReplay.displayName = 'proto.account_operation_endpoint.DepositReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.WithdrawReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.WithdrawReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.WithdrawReplay.displayName = 'proto.account_operation_endpoint.WithdrawReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.Party = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.Party, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.Party.displayName = 'proto.account_operation_endpoint.Party';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.TParty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.TParty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.TParty.displayName = 'proto.account_operation_endpoint.TParty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.Transaction.displayName = 'proto.account_operation_endpoint.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetStatementReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.GetStatementReplay.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.GetStatementReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetStatementReplay.displayName = 'proto.account_operation_endpoint.GetStatementReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.GetTransactionsReplay.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionsReplay.displayName = 'proto.account_operation_endpoint.GetTransactionsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_operation_endpoint.GetTransactionReplay.repeatedFields_, null);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionReplay.displayName = 'proto.account_operation_endpoint.GetTransactionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportStatementReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.ExportStatementReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportStatementReplay.displayName = 'proto.account_operation_endpoint.ExportStatementReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportTransactionsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.ExportTransactionsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportTransactionsReplay.displayName = 'proto.account_operation_endpoint.ExportTransactionsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportTransactionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_operation_endpoint.ExportTransactionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportTransactionReplay.displayName = 'proto.account_operation_endpoint.ExportTransactionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.GetBalanceResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.GetBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetBalanceResponse.displayName = 'proto.account_operation_endpoint.GetBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.DepositResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.DepositResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.DepositResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.DepositResponse.displayName = 'proto.account_operation_endpoint.DepositResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetStatementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.GetStatementResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.GetStatementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetStatementResponse.displayName = 'proto.account_operation_endpoint.GetStatementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportStatementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.ExportStatementResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.ExportStatementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportStatementResponse.displayName = 'proto.account_operation_endpoint.ExportStatementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.UpdateTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.UpdateTransactionResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.UpdateTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.UpdateTransactionResponse.displayName = 'proto.account_operation_endpoint.UpdateTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.GetTransactionResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionResponse.displayName = 'proto.account_operation_endpoint.GetTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.TransferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.TransferResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.TransferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.TransferResponse.displayName = 'proto.account_operation_endpoint.TransferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.WithdrawResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.WithdrawResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.WithdrawResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.WithdrawResponse.displayName = 'proto.account_operation_endpoint.WithdrawResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.ExportTransactionResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.ExportTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportTransactionResponse.displayName = 'proto.account_operation_endpoint.ExportTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.GetTransactionSummaryResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionSummaryResponse.displayName = 'proto.account_operation_endpoint.GetTransactionSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExportTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.ExportTransactionsResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.ExportTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExportTransactionsResponse.displayName = 'proto.account_operation_endpoint.ExportTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.GetTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.GetTransactionsResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.GetTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.GetTransactionsResponse.displayName = 'proto.account_operation_endpoint.GetTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_operation_endpoint.ExternalTransferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_operation_endpoint.ExternalTransferResponse.oneofGroups_);
};
goog.inherits(proto.account_operation_endpoint.ExternalTransferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_operation_endpoint.ExternalTransferResponse.displayName = 'proto.account_operation_endpoint.ExternalTransferResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetBalanceRequest}
 */
proto.account_operation_endpoint.GetBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetBalanceRequest;
  return proto.account_operation_endpoint.GetBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetBalanceRequest}
 */
proto.account_operation_endpoint.GetBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string accountId = 1;
 * @return {string}
 */
proto.account_operation_endpoint.GetBalanceRequest.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.GetBalanceRequest} returns this
 */
proto.account_operation_endpoint.GetBalanceRequest.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionRequest}
 */
proto.account_operation_endpoint.GetTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionRequest;
  return proto.account_operation_endpoint.GetTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionRequest}
 */
proto.account_operation_endpoint.GetTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_operation_endpoint.GetTransactionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.GetTransactionRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.DepositRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.DepositRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.DepositRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.DepositRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.DepositRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    treasury: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.DepositRequest}
 */
proto.account_operation_endpoint.DepositRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.DepositRequest;
  return proto.account_operation_endpoint.DepositRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.DepositRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.DepositRequest}
 */
proto.account_operation_endpoint.DepositRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasury(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.DepositRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.DepositRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.DepositRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.DepositRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTreasury();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string amount = 1;
 * @return {string}
 */
proto.account_operation_endpoint.DepositRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
 */
proto.account_operation_endpoint.DepositRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.account_operation_endpoint.DepositRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
 */
proto.account_operation_endpoint.DepositRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string treasury = 3;
 * @return {string}
 */
proto.account_operation_endpoint.DepositRequest.prototype.getTreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
 */
proto.account_operation_endpoint.DepositRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_operation_endpoint.DepositRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
*/
proto.account_operation_endpoint.DepositRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_operation_endpoint.DepositRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
 */
proto.account_operation_endpoint.DepositRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.DepositRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
*/
proto.account_operation_endpoint.DepositRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
 */
proto.account_operation_endpoint.DepositRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.DepositRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue message = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.DepositRequest.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
*/
proto.account_operation_endpoint.DepositRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
 */
proto.account_operation_endpoint.DepositRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.DepositRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue description = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.DepositRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
*/
proto.account_operation_endpoint.DepositRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.DepositRequest} returns this
 */
proto.account_operation_endpoint.DepositRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.DepositRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.account_operation_endpoint.GetTransactionSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryRequest}
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionSummaryRequest;
  return proto.account_operation_endpoint.GetTransactionSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryRequest}
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.GetTransactionSummary;
      reader.readMessage(value,proto.account_operation_endpoint.GetTransactionSummary.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account_operation_endpoint.GetTransactionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetTransactionSummary transactions = 1;
 * @return {!Array<!proto.account_operation_endpoint.GetTransactionSummary>}
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.account_operation_endpoint.GetTransactionSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_operation_endpoint.GetTransactionSummary, 1));
};


/**
 * @param {!Array<!proto.account_operation_endpoint.GetTransactionSummary>} value
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionSummaryRequest.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account_operation_endpoint.GetTransactionSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_operation_endpoint.GetTransactionSummary}
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account_operation_endpoint.GetTransactionSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionSummaryRequest.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    debtor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionSummary}
 */
proto.account_operation_endpoint.GetTransactionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionSummary;
  return proto.account_operation_endpoint.GetTransactionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionSummary}
 */
proto.account_operation_endpoint.GetTransactionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDebtor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string amount = 1;
 * @return {string}
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.GetTransactionSummary} returns this
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string creditor = 2;
 * @return {string}
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.getCreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.GetTransactionSummary} returns this
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.setCreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string debtor = 3;
 * @return {string}
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.getDebtor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.GetTransactionSummary} returns this
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.setDebtor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 operation = 4;
 * @return {number}
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.getOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.GetTransactionSummary} returns this
 */
proto.account_operation_endpoint.GetTransactionSummary.prototype.setOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    secondparty: (f = msg.getSecondparty()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    treasury: (f = msg.getTreasury()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    numofresults: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetStatementRequest}
 */
proto.account_operation_endpoint.GetStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetStatementRequest;
  return proto.account_operation_endpoint.GetStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetStatementRequest}
 */
proto.account_operation_endpoint.GetStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSecondparty(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasury(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSecondparty();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTreasury();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue account = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue secondParty = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getSecondparty = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setSecondparty = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearSecondparty = function() {
  return this.setSecondparty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasSecondparty = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue treasury = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getTreasury = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearTreasury = function() {
  return this.setTreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasTreasury = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue nextTo = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue search = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
*/
proto.account_operation_endpoint.GetStatementRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool order = 10;
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 numOfResults = 11;
 * @return {number}
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.GetStatementRequest} returns this
 */
proto.account_operation_endpoint.GetStatementRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    secondparty: (f = msg.getSecondparty()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    treasury: (f = msg.getTreasury()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    numofresults: jspb.Message.getFieldWithDefault(msg, 11, 0),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest}
 */
proto.account_operation_endpoint.GetTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionsRequest;
  return proto.account_operation_endpoint.GetTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest}
 */
proto.account_operation_endpoint.GetTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSecondparty(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasury(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSecondparty();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTreasury();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue account = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue secondParty = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getSecondparty = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setSecondparty = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearSecondparty = function() {
  return this.setSecondparty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasSecondparty = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue treasury = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getTreasury = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearTreasury = function() {
  return this.setTreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasTreasury = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue nextTo = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue search = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool order = 10;
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 numOfResults = 11;
 * @return {number}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.StringValue currency = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
*/
proto.account_operation_endpoint.GetTransactionsRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsRequest} returns this
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    propartyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelvalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.Label}
 */
proto.account_operation_endpoint.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.Label;
  return proto.account_operation_endpoint.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.Label}
 */
proto.account_operation_endpoint.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropartyname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelvalue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropartyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelvalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string propartyName = 1;
 * @return {string}
 */
proto.account_operation_endpoint.Label.prototype.getPropartyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.Label} returns this
 */
proto.account_operation_endpoint.Label.prototype.setPropartyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string labelValue = 2;
 * @return {string}
 */
proto.account_operation_endpoint.Label.prototype.getLabelvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.Label} returns this
 */
proto.account_operation_endpoint.Label.prototype.setLabelvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue value = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.Label.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.Label} returns this
*/
proto.account_operation_endpoint.Label.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Label} returns this
 */
proto.account_operation_endpoint.Label.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Label.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.ExportStatementRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    account: jspb.Message.getFieldWithDefault(msg, 3, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.account_operation_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 6, 0),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    secondparty: (f = msg.getSecondparty()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    treasury: (f = msg.getTreasury()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest}
 */
proto.account_operation_endpoint.ExportStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportStatementRequest;
  return proto.account_operation_endpoint.ExportStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest}
 */
proto.account_operation_endpoint.ExportStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 4:
      var value = new proto.account_operation_endpoint.Label;
      reader.readMessage(value,proto.account_operation_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSecondparty(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasury(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.account_operation_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSecondparty();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTreasury();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
*/
proto.account_operation_endpoint.ExportStatementRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp to = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
*/
proto.account_operation_endpoint.ExportStatementRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string account = 3;
 * @return {string}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Label labels = 4;
 * @return {!Array<!proto.account_operation_endpoint.Label>}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.account_operation_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_operation_endpoint.Label, 4));
};


/**
 * @param {!Array<!proto.account_operation_endpoint.Label>} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
*/
proto.account_operation_endpoint.ExportStatementRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.account_operation_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_operation_endpoint.Label}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.account_operation_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 5;
 * @return {number}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 numOfResults = 6;
 * @return {number}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue authCode = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
*/
proto.account_operation_endpoint.ExportStatementRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue secondParty = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getSecondparty = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
*/
proto.account_operation_endpoint.ExportStatementRequest.prototype.setSecondparty = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.clearSecondparty = function() {
  return this.setSecondparty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.hasSecondparty = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue treasury = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getTreasury = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
*/
proto.account_operation_endpoint.ExportStatementRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.clearTreasury = function() {
  return this.setTreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.hasTreasury = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
*/
proto.account_operation_endpoint.ExportStatementRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementRequest} returns this
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.ExportTransactionsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.account_operation_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    treasury: (f = msg.getTreasury()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    secondparty: (f = msg.getSecondparty()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportTransactionsRequest;
  return proto.account_operation_endpoint.ExportTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 4:
      var value = new proto.account_operation_endpoint.Label;
      reader.readMessage(value,proto.account_operation_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasury(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSecondparty(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.account_operation_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTreasury();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSecondparty();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp to = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue account = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Label labels = 4;
 * @return {!Array<!proto.account_operation_endpoint.Label>}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.account_operation_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_operation_endpoint.Label, 4));
};


/**
 * @param {!Array<!proto.account_operation_endpoint.Label>} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.account_operation_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_operation_endpoint.Label}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.account_operation_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 5;
 * @return {number}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue search = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue treasury = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getTreasury = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearTreasury = function() {
  return this.setTreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasTreasury = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue secondParty = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getSecondparty = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setSecondparty = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearSecondparty = function() {
  return this.setSecondparty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasSecondparty = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue currency = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool order = 12;
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.TransferRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.TransferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.TransferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.TransferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    debtor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.TransferRequest}
 */
proto.account_operation_endpoint.TransferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.TransferRequest;
  return proto.account_operation_endpoint.TransferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.TransferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.TransferRequest}
 */
proto.account_operation_endpoint.TransferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtor(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.TransferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.TransferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.TransferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDebtor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string amount = 1;
 * @return {string}
 */
proto.account_operation_endpoint.TransferRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
 */
proto.account_operation_endpoint.TransferRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string creditor = 2;
 * @return {string}
 */
proto.account_operation_endpoint.TransferRequest.prototype.getCreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
 */
proto.account_operation_endpoint.TransferRequest.prototype.setCreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string debtor = 3;
 * @return {string}
 */
proto.account_operation_endpoint.TransferRequest.prototype.getDebtor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
 */
proto.account_operation_endpoint.TransferRequest.prototype.setDebtor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_operation_endpoint.TransferRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
*/
proto.account_operation_endpoint.TransferRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_operation_endpoint.TransferRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
 */
proto.account_operation_endpoint.TransferRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TransferRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
*/
proto.account_operation_endpoint.TransferRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
 */
proto.account_operation_endpoint.TransferRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransferRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue message = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TransferRequest.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
*/
proto.account_operation_endpoint.TransferRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
 */
proto.account_operation_endpoint.TransferRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransferRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue description = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TransferRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
*/
proto.account_operation_endpoint.TransferRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransferRequest} returns this
 */
proto.account_operation_endpoint.TransferRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransferRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.WithdrawRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.WithdrawRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.WithdrawRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.WithdrawRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    treasury: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.WithdrawRequest}
 */
proto.account_operation_endpoint.WithdrawRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.WithdrawRequest;
  return proto.account_operation_endpoint.WithdrawRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.WithdrawRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.WithdrawRequest}
 */
proto.account_operation_endpoint.WithdrawRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasury(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.WithdrawRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.WithdrawRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.WithdrawRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTreasury();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string amount = 1;
 * @return {string}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string treasury = 3;
 * @return {string}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.getTreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
*/
proto.account_operation_endpoint.WithdrawRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
*/
proto.account_operation_endpoint.WithdrawRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue message = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
*/
proto.account_operation_endpoint.WithdrawRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue description = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
*/
proto.account_operation_endpoint.WithdrawRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.WithdrawRequest} returns this
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.WithdrawRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.UpdateTransactionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.UpdateTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.UpdateTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.UpdateTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.UpdateTransactionRequest}
 */
proto.account_operation_endpoint.UpdateTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.UpdateTransactionRequest;
  return proto.account_operation_endpoint.UpdateTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.UpdateTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.UpdateTransactionRequest}
 */
proto.account_operation_endpoint.UpdateTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.UpdateTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.UpdateTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.UpdateTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.UpdateTransactionRequest} returns this
 */
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated common.ExtraField extraFields = 2;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 2));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_operation_endpoint.UpdateTransactionRequest} returns this
*/
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.UpdateTransactionRequest} returns this
 */
proto.account_operation_endpoint.UpdateTransactionRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.ExportTransactionRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iscredit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.account_operation_endpoint.Label.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportTransactionRequest}
 */
proto.account_operation_endpoint.ExportTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportTransactionRequest;
  return proto.account_operation_endpoint.ExportTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportTransactionRequest}
 */
proto.account_operation_endpoint.ExportTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscredit(value);
      break;
    case 3:
      var value = new proto.account_operation_endpoint.Label;
      reader.readMessage(value,proto.account_operation_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIscredit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.account_operation_endpoint.Label.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.ExportTransactionRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isCredit = 2;
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.getIscredit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_operation_endpoint.ExportTransactionRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.setIscredit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Label labels = 3;
 * @return {!Array<!proto.account_operation_endpoint.Label>}
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.account_operation_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_operation_endpoint.Label, 3));
};


/**
 * @param {!Array<!proto.account_operation_endpoint.Label>} value
 * @return {!proto.account_operation_endpoint.ExportTransactionRequest} returns this
*/
proto.account_operation_endpoint.ExportTransactionRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.account_operation_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_operation_endpoint.Label}
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.account_operation_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.ExportTransactionRequest} returns this
 */
proto.account_operation_endpoint.ExportTransactionRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.ExternalTransferRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExternalTransferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExternalTransferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExternalTransferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    debtor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    endcreditor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest}
 */
proto.account_operation_endpoint.ExternalTransferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExternalTransferRequest;
  return proto.account_operation_endpoint.ExternalTransferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExternalTransferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest}
 */
proto.account_operation_endpoint.ExternalTransferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtor(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndcreditor(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExternalTransferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExternalTransferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExternalTransferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDebtor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEndcreditor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string amount = 1;
 * @return {string}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string creditor = 2;
 * @return {string}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getCreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setCreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string debtor = 3;
 * @return {string}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getDebtor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setDebtor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
*/
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
*/
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue message = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
*/
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string endCreditor = 7;
 * @return {string}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getEndcreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setEndcreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue description = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
*/
proto.account_operation_endpoint.ExternalTransferRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExternalTransferRequest} returns this
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExternalTransferRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetBalanceReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetBalanceReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetBalanceReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    freebalance: (f = msg.getFreebalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    reservedbalance: (f = msg.getReservedbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    feetransaction: (f = msg.getFeetransaction()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    totalbalance: (f = msg.getTotalbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetBalanceReplay}
 */
proto.account_operation_endpoint.GetBalanceReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetBalanceReplay;
  return proto.account_operation_endpoint.GetBalanceReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetBalanceReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetBalanceReplay}
 */
proto.account_operation_endpoint.GetBalanceReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFreebalance(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReservedbalance(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFeetransaction(value);
      break;
    case 4:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotalbalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetBalanceReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetBalanceReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetBalanceReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFreebalance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getReservedbalance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFeetransaction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalbalance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue freeBalance = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.getFreebalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
*/
proto.account_operation_endpoint.GetBalanceReplay.prototype.setFreebalance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.clearFreebalance = function() {
  return this.setFreebalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.hasFreebalance = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue reservedBalance = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.getReservedbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
*/
proto.account_operation_endpoint.GetBalanceReplay.prototype.setReservedbalance = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.clearReservedbalance = function() {
  return this.setReservedbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.hasReservedbalance = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue feeTransaction = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.getFeetransaction = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
*/
proto.account_operation_endpoint.GetBalanceReplay.prototype.setFeetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.clearFeetransaction = function() {
  return this.setFeetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.hasFeetransaction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.CurrencyResponse currency = 4;
 * @return {?proto.common.CurrencyResponse}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 4));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
*/
proto.account_operation_endpoint.GetBalanceReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue totalBalance = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.getTotalbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
*/
proto.account_operation_endpoint.GetBalanceReplay.prototype.setTotalbalance = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetBalanceReplay} returns this
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.clearTotalbalance = function() {
  return this.setTotalbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetBalanceReplay.prototype.hasTotalbalance = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.FeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.FeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.FeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.FeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    fee: (f = msg.getFee()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.FeeResponse}
 */
proto.account_operation_endpoint.FeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.FeeResponse;
  return proto.account_operation_endpoint.FeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.FeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.FeeResponse}
 */
proto.account_operation_endpoint.FeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.FeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.FeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.FeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.FeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.FeeResponse.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.FeeResponse} returns this
*/
proto.account_operation_endpoint.FeeResponse.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.FeeResponse} returns this
 */
proto.account_operation_endpoint.FeeResponse.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.FeeResponse.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.FeeResponse.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.FeeResponse} returns this
*/
proto.account_operation_endpoint.FeeResponse.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.FeeResponse} returns this
 */
proto.account_operation_endpoint.FeeResponse.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.FeeResponse.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue fee = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.FeeResponse.prototype.getFee = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.FeeResponse} returns this
*/
proto.account_operation_endpoint.FeeResponse.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.FeeResponse} returns this
 */
proto.account_operation_endpoint.FeeResponse.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.FeeResponse.prototype.hasFee = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.TransactionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.TransactionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransactionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    creditorfee: (f = msg.getCreditorfee()) && proto.account_operation_endpoint.FeeResponse.toObject(includeInstance, f),
    debtorfee: (f = msg.getDebtorfee()) && proto.account_operation_endpoint.FeeResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.TransactionSummary}
 */
proto.account_operation_endpoint.TransactionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.TransactionSummary;
  return proto.account_operation_endpoint.TransactionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.TransactionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.TransactionSummary}
 */
proto.account_operation_endpoint.TransactionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 3:
      var value = new proto.account_operation_endpoint.FeeResponse;
      reader.readMessage(value,proto.account_operation_endpoint.FeeResponse.deserializeBinaryFromReader);
      msg.setCreditorfee(value);
      break;
    case 4:
      var value = new proto.account_operation_endpoint.FeeResponse;
      reader.readMessage(value,proto.account_operation_endpoint.FeeResponse.deserializeBinaryFromReader);
      msg.setDebtorfee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.TransactionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.TransactionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransactionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreditorfee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.account_operation_endpoint.FeeResponse.serializeBinaryToWriter
    );
  }
  f = message.getDebtorfee();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.account_operation_endpoint.FeeResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue amount = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
*/
proto.account_operation_endpoint.TransactionSummary.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
 */
proto.account_operation_endpoint.TransactionSummary.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.CurrencyResponse currency = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
*/
proto.account_operation_endpoint.TransactionSummary.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
 */
proto.account_operation_endpoint.TransactionSummary.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FeeResponse creditorFee = 3;
 * @return {?proto.account_operation_endpoint.FeeResponse}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.getCreditorfee = function() {
  return /** @type{?proto.account_operation_endpoint.FeeResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.FeeResponse, 3));
};


/**
 * @param {?proto.account_operation_endpoint.FeeResponse|undefined} value
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
*/
proto.account_operation_endpoint.TransactionSummary.prototype.setCreditorfee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
 */
proto.account_operation_endpoint.TransactionSummary.prototype.clearCreditorfee = function() {
  return this.setCreditorfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.hasCreditorfee = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FeeResponse debtorFee = 4;
 * @return {?proto.account_operation_endpoint.FeeResponse}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.getDebtorfee = function() {
  return /** @type{?proto.account_operation_endpoint.FeeResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.FeeResponse, 4));
};


/**
 * @param {?proto.account_operation_endpoint.FeeResponse|undefined} value
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
*/
proto.account_operation_endpoint.TransactionSummary.prototype.setDebtorfee = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransactionSummary} returns this
 */
proto.account_operation_endpoint.TransactionSummary.prototype.clearDebtorfee = function() {
  return this.setDebtorfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransactionSummary.prototype.hasDebtorfee = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionSummaryReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsummaryList: jspb.Message.toObjectList(msg.getTransactionsummaryList(),
    proto.account_operation_endpoint.TransactionSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryReplay}
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionSummaryReplay;
  return proto.account_operation_endpoint.GetTransactionSummaryReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryReplay}
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.TransactionSummary;
      reader.readMessage(value,proto.account_operation_endpoint.TransactionSummary.deserializeBinaryFromReader);
      msg.addTransactionsummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionSummaryReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account_operation_endpoint.TransactionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionSummary transactionSummary = 1;
 * @return {!Array<!proto.account_operation_endpoint.TransactionSummary>}
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.prototype.getTransactionsummaryList = function() {
  return /** @type{!Array<!proto.account_operation_endpoint.TransactionSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_operation_endpoint.TransactionSummary, 1));
};


/**
 * @param {!Array<!proto.account_operation_endpoint.TransactionSummary>} value
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionSummaryReplay.prototype.setTransactionsummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account_operation_endpoint.TransactionSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_operation_endpoint.TransactionSummary}
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.prototype.addTransactionsummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account_operation_endpoint.TransactionSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionSummaryReplay.prototype.clearTransactionsummaryList = function() {
  return this.setTransactionsummaryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.TransferReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.TransferReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.TransferReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransferReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactioninternalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.TransferReplay}
 */
proto.account_operation_endpoint.TransferReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.TransferReplay;
  return proto.account_operation_endpoint.TransferReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.TransferReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.TransferReplay}
 */
proto.account_operation_endpoint.TransferReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactioninternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.TransferReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.TransferReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.TransferReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransferReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactioninternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionInternalId = 1;
 * @return {string}
 */
proto.account_operation_endpoint.TransferReplay.prototype.getTransactioninternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.TransferReplay} returns this
 */
proto.account_operation_endpoint.TransferReplay.prototype.setTransactioninternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 transactionId = 2;
 * @return {number}
 */
proto.account_operation_endpoint.TransferReplay.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.TransferReplay} returns this
 */
proto.account_operation_endpoint.TransferReplay.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.TransferReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.TransferReplay} returns this
*/
proto.account_operation_endpoint.TransferReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransferReplay} returns this
 */
proto.account_operation_endpoint.TransferReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransferReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExternalTransferReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExternalTransferReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExternalTransferReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactioninternalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExternalTransferReplay}
 */
proto.account_operation_endpoint.ExternalTransferReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExternalTransferReplay;
  return proto.account_operation_endpoint.ExternalTransferReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExternalTransferReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExternalTransferReplay}
 */
proto.account_operation_endpoint.ExternalTransferReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactioninternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExternalTransferReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExternalTransferReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExternalTransferReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactioninternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionInternalId = 1;
 * @return {string}
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.getTransactioninternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.ExternalTransferReplay} returns this
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.setTransactioninternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 transactionId = 2;
 * @return {number}
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.ExternalTransferReplay} returns this
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.ExternalTransferReplay} returns this
*/
proto.account_operation_endpoint.ExternalTransferReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExternalTransferReplay} returns this
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExternalTransferReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.UpdateTransactionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.UpdateTransactionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.UpdateTransactionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.UpdateTransactionReplay}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.UpdateTransactionReplay;
  return proto.account_operation_endpoint.UpdateTransactionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.UpdateTransactionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.UpdateTransactionReplay}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.UpdateTransactionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.UpdateTransactionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.UpdateTransactionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.UpdateTransactionReplay} returns this
*/
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.UpdateTransactionReplay} returns this
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_operation_endpoint.UpdateTransactionReplay} returns this
*/
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.UpdateTransactionReplay} returns this
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.UpdateTransactionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.DepositReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.DepositReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.DepositReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.DepositReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactioninternalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.DepositReplay}
 */
proto.account_operation_endpoint.DepositReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.DepositReplay;
  return proto.account_operation_endpoint.DepositReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.DepositReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.DepositReplay}
 */
proto.account_operation_endpoint.DepositReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactioninternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.DepositReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.DepositReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.DepositReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.DepositReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactioninternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionInternalId = 1;
 * @return {string}
 */
proto.account_operation_endpoint.DepositReplay.prototype.getTransactioninternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.DepositReplay} returns this
 */
proto.account_operation_endpoint.DepositReplay.prototype.setTransactioninternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 transactionId = 2;
 * @return {number}
 */
proto.account_operation_endpoint.DepositReplay.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.DepositReplay} returns this
 */
proto.account_operation_endpoint.DepositReplay.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.DepositReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.DepositReplay} returns this
*/
proto.account_operation_endpoint.DepositReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.DepositReplay} returns this
 */
proto.account_operation_endpoint.DepositReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.DepositReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.WithdrawReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.WithdrawReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.WithdrawReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactioninternalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.WithdrawReplay}
 */
proto.account_operation_endpoint.WithdrawReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.WithdrawReplay;
  return proto.account_operation_endpoint.WithdrawReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.WithdrawReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.WithdrawReplay}
 */
proto.account_operation_endpoint.WithdrawReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactioninternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransactionid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.WithdrawReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.WithdrawReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.WithdrawReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactioninternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionInternalId = 1;
 * @return {string}
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.getTransactioninternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.WithdrawReplay} returns this
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.setTransactioninternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 transactionId = 2;
 * @return {number}
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.WithdrawReplay} returns this
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.WithdrawReplay} returns this
*/
proto.account_operation_endpoint.WithdrawReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.WithdrawReplay} returns this
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.WithdrawReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.Party.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.Party.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.Party} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.Party.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    internalid: (f = msg.getInternalid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balancebefore: (f = msg.getBalancebefore()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balanceafter: (f = msg.getBalanceafter()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    istreasury: (f = msg.getIstreasury()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.Party}
 */
proto.account_operation_endpoint.Party.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.Party;
  return proto.account_operation_endpoint.Party.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.Party} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.Party}
 */
proto.account_operation_endpoint.Party.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalancebefore(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalanceafter(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIstreasury(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.Party.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.Party.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.Party} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.Party.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalancebefore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalanceafter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIstreasury();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int64Value id = 1;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.account_operation_endpoint.Party.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.account_operation_endpoint.Party} returns this
*/
proto.account_operation_endpoint.Party.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Party} returns this
 */
proto.account_operation_endpoint.Party.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Party.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue internalId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.Party.prototype.getInternalid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.Party} returns this
*/
proto.account_operation_endpoint.Party.prototype.setInternalid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Party} returns this
 */
proto.account_operation_endpoint.Party.prototype.clearInternalid = function() {
  return this.setInternalid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Party.prototype.hasInternalid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.Party.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.Party} returns this
*/
proto.account_operation_endpoint.Party.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Party} returns this
 */
proto.account_operation_endpoint.Party.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Party.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue description = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.Party.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.Party} returns this
*/
proto.account_operation_endpoint.Party.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Party} returns this
 */
proto.account_operation_endpoint.Party.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Party.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue balanceBefore = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.Party.prototype.getBalancebefore = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.Party} returns this
*/
proto.account_operation_endpoint.Party.prototype.setBalancebefore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Party} returns this
 */
proto.account_operation_endpoint.Party.prototype.clearBalancebefore = function() {
  return this.setBalancebefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Party.prototype.hasBalancebefore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue balanceAfter = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.Party.prototype.getBalanceafter = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.Party} returns this
*/
proto.account_operation_endpoint.Party.prototype.setBalanceafter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Party} returns this
 */
proto.account_operation_endpoint.Party.prototype.clearBalanceafter = function() {
  return this.setBalanceafter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Party.prototype.hasBalanceafter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isTreasury = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_operation_endpoint.Party.prototype.getIstreasury = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_operation_endpoint.Party} returns this
*/
proto.account_operation_endpoint.Party.prototype.setIstreasury = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Party} returns this
 */
proto.account_operation_endpoint.Party.prototype.clearIstreasury = function() {
  return this.setIstreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Party.prototype.hasIstreasury = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.TParty.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.TParty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.TParty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TParty.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    internalid: (f = msg.getInternalid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balancebefore: (f = msg.getBalancebefore()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balanceafter: (f = msg.getBalanceafter()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    note: (f = msg.getNote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    istreasury: (f = msg.getIstreasury()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    feetransaction: (f = msg.getFeetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    receivedmessage: (f = msg.getReceivedmessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.TParty}
 */
proto.account_operation_endpoint.TParty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.TParty;
  return proto.account_operation_endpoint.TParty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.TParty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.TParty}
 */
proto.account_operation_endpoint.TParty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalancebefore(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalanceafter(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIstreasury(value);
      break;
    case 9:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setFeetransaction(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReceivedmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.TParty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.TParty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.TParty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TParty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalancebefore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalanceafter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIstreasury();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getFeetransaction();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getReceivedmessage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int64Value id = 1;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.account_operation_endpoint.TParty.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue internalId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TParty.prototype.getInternalid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setInternalid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearInternalid = function() {
  return this.setInternalid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasInternalid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TParty.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue description = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TParty.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue balanceBefore = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TParty.prototype.getBalancebefore = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setBalancebefore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearBalancebefore = function() {
  return this.setBalancebefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasBalancebefore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue balanceAfter = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TParty.prototype.getBalanceafter = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setBalanceafter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearBalanceafter = function() {
  return this.setBalanceafter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasBalanceafter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue note = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TParty.prototype.getNote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasNote = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue isTreasury = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_operation_endpoint.TParty.prototype.getIstreasury = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setIstreasury = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearIstreasury = function() {
  return this.setIstreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasIstreasury = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.TransactionResponse feeTransaction = 9;
 * @return {?proto.common.TransactionResponse}
 */
proto.account_operation_endpoint.TParty.prototype.getFeetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 9));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setFeetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearFeetransaction = function() {
  return this.setFeetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasFeetransaction = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue receivedMessage = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.TParty.prototype.getReceivedmessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.TParty} returns this
*/
proto.account_operation_endpoint.TParty.prototype.setReceivedmessage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TParty} returns this
 */
proto.account_operation_endpoint.TParty.prototype.clearReceivedmessage = function() {
  return this.setReceivedmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TParty.prototype.hasReceivedmessage = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    debtor: (f = msg.getDebtor()) && proto.account_operation_endpoint.Party.toObject(includeInstance, f),
    creditor: (f = msg.getCreditor()) && proto.account_operation_endpoint.Party.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.Transaction}
 */
proto.account_operation_endpoint.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.Transaction;
  return proto.account_operation_endpoint.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.Transaction}
 */
proto.account_operation_endpoint.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new proto.account_operation_endpoint.Party;
      reader.readMessage(value,proto.account_operation_endpoint.Party.deserializeBinaryFromReader);
      msg.setDebtor(value);
      break;
    case 5:
      var value = new proto.account_operation_endpoint.Party;
      reader.readMessage(value,proto.account_operation_endpoint.Party.deserializeBinaryFromReader);
      msg.setCreditor(value);
      break;
    case 6:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDebtor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.account_operation_endpoint.Party.serializeBinaryToWriter
    );
  }
  f = message.getCreditor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.account_operation_endpoint.Party.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string internalId = 1;
 * @return {string}
 */
proto.account_operation_endpoint.Transaction.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.account_operation_endpoint.Transaction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.Transaction.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Party debtor = 4;
 * @return {?proto.account_operation_endpoint.Party}
 */
proto.account_operation_endpoint.Transaction.prototype.getDebtor = function() {
  return /** @type{?proto.account_operation_endpoint.Party} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.Party, 4));
};


/**
 * @param {?proto.account_operation_endpoint.Party|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setDebtor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearDebtor = function() {
  return this.setDebtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasDebtor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Party creditor = 5;
 * @return {?proto.account_operation_endpoint.Party}
 */
proto.account_operation_endpoint.Transaction.prototype.getCreditor = function() {
  return /** @type{?proto.account_operation_endpoint.Party} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.Party, 5));
};


/**
 * @param {?proto.account_operation_endpoint.Party|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setCreditor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearCreditor = function() {
  return this.setCreditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasCreditor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.CurrencyResponse currency = 6;
 * @return {?proto.common.CurrencyResponse}
 */
proto.account_operation_endpoint.Transaction.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 6));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue amount = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.Transaction.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.Transaction.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_operation_endpoint.Transaction.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 10;
 * @return {?proto.common.TransactionResponse}
 */
proto.account_operation_endpoint.Transaction.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 10));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.account_operation_endpoint.Transaction} returns this
*/
proto.account_operation_endpoint.Transaction.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.Transaction} returns this
 */
proto.account_operation_endpoint.Transaction.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.Transaction.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.GetStatementReplay.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetStatementReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetStatementReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetStatementReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    feetransaction: (f = msg.getFeetransaction()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.account_operation_endpoint.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetStatementReplay}
 */
proto.account_operation_endpoint.GetStatementReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetStatementReplay;
  return proto.account_operation_endpoint.GetStatementReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetStatementReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetStatementReplay}
 */
proto.account_operation_endpoint.GetStatementReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFeetransaction(value);
      break;
    case 2:
      var value = new proto.account_operation_endpoint.Transaction;
      reader.readMessage(value,proto.account_operation_endpoint.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetStatementReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetStatementReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetStatementReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeetransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.account_operation_endpoint.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue feeTransaction = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.getFeetransaction = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementReplay} returns this
*/
proto.account_operation_endpoint.GetStatementReplay.prototype.setFeetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementReplay} returns this
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.clearFeetransaction = function() {
  return this.setFeetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.hasFeetransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Transaction transactions = 2;
 * @return {!Array<!proto.account_operation_endpoint.Transaction>}
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.account_operation_endpoint.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_operation_endpoint.Transaction, 2));
};


/**
 * @param {!Array<!proto.account_operation_endpoint.Transaction>} value
 * @return {!proto.account_operation_endpoint.GetStatementReplay} returns this
*/
proto.account_operation_endpoint.GetStatementReplay.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.account_operation_endpoint.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_operation_endpoint.Transaction}
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.account_operation_endpoint.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.GetStatementReplay} returns this
 */
proto.account_operation_endpoint.GetStatementReplay.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.GetTransactionsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.account_operation_endpoint.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionsReplay}
 */
proto.account_operation_endpoint.GetTransactionsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionsReplay;
  return proto.account_operation_endpoint.GetTransactionsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionsReplay}
 */
proto.account_operation_endpoint.GetTransactionsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.Transaction;
      reader.readMessage(value,proto.account_operation_endpoint.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account_operation_endpoint.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.account_operation_endpoint.Transaction>}
 */
proto.account_operation_endpoint.GetTransactionsReplay.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.account_operation_endpoint.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_operation_endpoint.Transaction, 1));
};


/**
 * @param {!Array<!proto.account_operation_endpoint.Transaction>} value
 * @return {!proto.account_operation_endpoint.GetTransactionsReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionsReplay.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account_operation_endpoint.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_operation_endpoint.Transaction}
 */
proto.account_operation_endpoint.GetTransactionsReplay.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account_operation_endpoint.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.GetTransactionsReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionsReplay.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_operation_endpoint.GetTransactionReplay.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    debtor: (f = msg.getDebtor()) && proto.account_operation_endpoint.TParty.toObject(includeInstance, f),
    creditor: (f = msg.getCreditor()) && proto.account_operation_endpoint.TParty.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay}
 */
proto.account_operation_endpoint.GetTransactionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionReplay;
  return proto.account_operation_endpoint.GetTransactionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay}
 */
proto.account_operation_endpoint.GetTransactionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new proto.account_operation_endpoint.TParty;
      reader.readMessage(value,proto.account_operation_endpoint.TParty.deserializeBinaryFromReader);
      msg.setDebtor(value);
      break;
    case 5:
      var value = new proto.account_operation_endpoint.TParty;
      reader.readMessage(value,proto.account_operation_endpoint.TParty.deserializeBinaryFromReader);
      msg.setCreditor(value);
      break;
    case 6:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 11:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDebtor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.account_operation_endpoint.TParty.serializeBinaryToWriter
    );
  }
  f = message.getCreditor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.account_operation_endpoint.TParty.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string internalId = 1;
 * @return {string}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TParty debtor = 4;
 * @return {?proto.account_operation_endpoint.TParty}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getDebtor = function() {
  return /** @type{?proto.account_operation_endpoint.TParty} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.TParty, 4));
};


/**
 * @param {?proto.account_operation_endpoint.TParty|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setDebtor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearDebtor = function() {
  return this.setDebtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasDebtor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TParty creditor = 5;
 * @return {?proto.account_operation_endpoint.TParty}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getCreditor = function() {
  return /** @type{?proto.account_operation_endpoint.TParty} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.TParty, 5));
};


/**
 * @param {?proto.account_operation_endpoint.TParty|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setCreditor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearCreditor = function() {
  return this.setCreditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasCreditor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.CurrencyResponse currency = 6;
 * @return {?proto.common.CurrencyResponse}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 6));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue amount = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 10;
 * @return {?proto.common.TransactionResponse}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 10));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated common.ExtraField extraFields = 11;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 11));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
*/
proto.account_operation_endpoint.GetTransactionReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_operation_endpoint.GetTransactionReplay} returns this
 */
proto.account_operation_endpoint.GetTransactionReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportStatementReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportStatementReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportStatementReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    statement: msg.getStatement_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportStatementReplay}
 */
proto.account_operation_endpoint.ExportStatementReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportStatementReplay;
  return proto.account_operation_endpoint.ExportStatementReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportStatementReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportStatementReplay}
 */
proto.account_operation_endpoint.ExportStatementReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStatement(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportStatementReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportStatementReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportStatementReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes statement = 1;
 * @return {!(string|Uint8Array)}
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.getStatement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes statement = 1;
 * This is a type-conversion wrapper around `getStatement()`
 * @return {string}
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.getStatement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStatement()));
};


/**
 * optional bytes statement = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStatement()`
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.getStatement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStatement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.account_operation_endpoint.ExportStatementReplay} returns this
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.setStatement = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementReplay} returns this
*/
proto.account_operation_endpoint.ExportStatementReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementReplay} returns this
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportTransactionsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportTransactionsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    statement: msg.getStatement_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportTransactionsReplay}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportTransactionsReplay;
  return proto.account_operation_endpoint.ExportTransactionsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportTransactionsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportTransactionsReplay}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStatement(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportTransactionsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportTransactionsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes statement = 1;
 * @return {!(string|Uint8Array)}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.getStatement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes statement = 1;
 * This is a type-conversion wrapper around `getStatement()`
 * @return {string}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.getStatement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStatement()));
};


/**
 * optional bytes statement = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStatement()`
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.getStatement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStatement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsReplay} returns this
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.setStatement = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsReplay} returns this
*/
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsReplay} returns this
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportTransactionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportTransactionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    statement: msg.getStatement_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportTransactionReplay}
 */
proto.account_operation_endpoint.ExportTransactionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportTransactionReplay;
  return proto.account_operation_endpoint.ExportTransactionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportTransactionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportTransactionReplay}
 */
proto.account_operation_endpoint.ExportTransactionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStatement(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportTransactionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportTransactionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes statement = 1;
 * @return {!(string|Uint8Array)}
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.getStatement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes statement = 1;
 * This is a type-conversion wrapper around `getStatement()`
 * @return {string}
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.getStatement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStatement()));
};


/**
 * optional bytes statement = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStatement()`
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.getStatement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStatement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.account_operation_endpoint.ExportTransactionReplay} returns this
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.setStatement = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionReplay} returns this
*/
proto.account_operation_endpoint.ExportTransactionReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionReplay} returns this
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.GetBalanceResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.GetBalanceResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.GetBalanceResponse.ResultCase}
 */
proto.account_operation_endpoint.GetBalanceResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.GetBalanceResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.GetBalanceResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.GetBalanceReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetBalanceResponse}
 */
proto.account_operation_endpoint.GetBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetBalanceResponse;
  return proto.account_operation_endpoint.GetBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetBalanceResponse}
 */
proto.account_operation_endpoint.GetBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.GetBalanceReplay;
      reader.readMessage(value,proto.account_operation_endpoint.GetBalanceReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.GetBalanceReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBalanceReplay success = 1;
 * @return {?proto.account_operation_endpoint.GetBalanceReplay}
 */
proto.account_operation_endpoint.GetBalanceResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.GetBalanceReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.GetBalanceReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.GetBalanceReplay|undefined} value
 * @return {!proto.account_operation_endpoint.GetBalanceResponse} returns this
*/
proto.account_operation_endpoint.GetBalanceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.GetBalanceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetBalanceResponse} returns this
 */
proto.account_operation_endpoint.GetBalanceResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetBalanceResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.DepositResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.DepositResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.DepositResponse.ResultCase}
 */
proto.account_operation_endpoint.DepositResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.DepositResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.DepositResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.DepositResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.DepositResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.DepositResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.DepositResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.DepositReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.DepositResponse}
 */
proto.account_operation_endpoint.DepositResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.DepositResponse;
  return proto.account_operation_endpoint.DepositResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.DepositResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.DepositResponse}
 */
proto.account_operation_endpoint.DepositResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.DepositReplay;
      reader.readMessage(value,proto.account_operation_endpoint.DepositReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.DepositResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.DepositResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.DepositResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.DepositResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.DepositReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DepositReplay success = 1;
 * @return {?proto.account_operation_endpoint.DepositReplay}
 */
proto.account_operation_endpoint.DepositResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.DepositReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.DepositReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.DepositReplay|undefined} value
 * @return {!proto.account_operation_endpoint.DepositResponse} returns this
*/
proto.account_operation_endpoint.DepositResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.DepositResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.DepositResponse} returns this
 */
proto.account_operation_endpoint.DepositResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.DepositResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.GetStatementResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.GetStatementResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.GetStatementResponse.ResultCase}
 */
proto.account_operation_endpoint.GetStatementResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.GetStatementResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.GetStatementResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetStatementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetStatementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetStatementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetStatementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.GetStatementReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetStatementResponse}
 */
proto.account_operation_endpoint.GetStatementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetStatementResponse;
  return proto.account_operation_endpoint.GetStatementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetStatementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetStatementResponse}
 */
proto.account_operation_endpoint.GetStatementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.GetStatementReplay;
      reader.readMessage(value,proto.account_operation_endpoint.GetStatementReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetStatementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetStatementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetStatementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetStatementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.GetStatementReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetStatementReplay success = 1;
 * @return {?proto.account_operation_endpoint.GetStatementReplay}
 */
proto.account_operation_endpoint.GetStatementResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.GetStatementReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.GetStatementReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.GetStatementReplay|undefined} value
 * @return {!proto.account_operation_endpoint.GetStatementResponse} returns this
*/
proto.account_operation_endpoint.GetStatementResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.GetStatementResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetStatementResponse} returns this
 */
proto.account_operation_endpoint.GetStatementResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetStatementResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.ExportStatementResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.ExportStatementResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.ExportStatementResponse.ResultCase}
 */
proto.account_operation_endpoint.ExportStatementResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.ExportStatementResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.ExportStatementResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportStatementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportStatementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportStatementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportStatementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.ExportStatementReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportStatementResponse}
 */
proto.account_operation_endpoint.ExportStatementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportStatementResponse;
  return proto.account_operation_endpoint.ExportStatementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportStatementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportStatementResponse}
 */
proto.account_operation_endpoint.ExportStatementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.ExportStatementReplay;
      reader.readMessage(value,proto.account_operation_endpoint.ExportStatementReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportStatementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportStatementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportStatementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportStatementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.ExportStatementReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportStatementReplay success = 1;
 * @return {?proto.account_operation_endpoint.ExportStatementReplay}
 */
proto.account_operation_endpoint.ExportStatementResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.ExportStatementReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.ExportStatementReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.ExportStatementReplay|undefined} value
 * @return {!proto.account_operation_endpoint.ExportStatementResponse} returns this
*/
proto.account_operation_endpoint.ExportStatementResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.ExportStatementResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportStatementResponse} returns this
 */
proto.account_operation_endpoint.ExportStatementResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportStatementResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.UpdateTransactionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.UpdateTransactionResponse.ResultCase}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.UpdateTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.UpdateTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.UpdateTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.UpdateTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.UpdateTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.UpdateTransactionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.UpdateTransactionResponse}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.UpdateTransactionResponse;
  return proto.account_operation_endpoint.UpdateTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.UpdateTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.UpdateTransactionResponse}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.UpdateTransactionReplay;
      reader.readMessage(value,proto.account_operation_endpoint.UpdateTransactionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.UpdateTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.UpdateTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.UpdateTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.UpdateTransactionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTransactionReplay success = 1;
 * @return {?proto.account_operation_endpoint.UpdateTransactionReplay}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.UpdateTransactionReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.UpdateTransactionReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.UpdateTransactionReplay|undefined} value
 * @return {!proto.account_operation_endpoint.UpdateTransactionResponse} returns this
*/
proto.account_operation_endpoint.UpdateTransactionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.UpdateTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.UpdateTransactionResponse} returns this
 */
proto.account_operation_endpoint.UpdateTransactionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.UpdateTransactionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.GetTransactionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.GetTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.GetTransactionResponse.ResultCase}
 */
proto.account_operation_endpoint.GetTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.GetTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.GetTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.GetTransactionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionResponse}
 */
proto.account_operation_endpoint.GetTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionResponse;
  return proto.account_operation_endpoint.GetTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionResponse}
 */
proto.account_operation_endpoint.GetTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.GetTransactionReplay;
      reader.readMessage(value,proto.account_operation_endpoint.GetTransactionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.GetTransactionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTransactionReplay success = 1;
 * @return {?proto.account_operation_endpoint.GetTransactionReplay}
 */
proto.account_operation_endpoint.GetTransactionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.GetTransactionReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.GetTransactionReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.GetTransactionReplay|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionResponse} returns this
*/
proto.account_operation_endpoint.GetTransactionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.GetTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionResponse} returns this
 */
proto.account_operation_endpoint.GetTransactionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.TransferResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.TransferResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.TransferResponse.ResultCase}
 */
proto.account_operation_endpoint.TransferResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.TransferResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.TransferResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.TransferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.TransferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.TransferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.TransferReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.TransferResponse}
 */
proto.account_operation_endpoint.TransferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.TransferResponse;
  return proto.account_operation_endpoint.TransferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.TransferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.TransferResponse}
 */
proto.account_operation_endpoint.TransferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.TransferReplay;
      reader.readMessage(value,proto.account_operation_endpoint.TransferReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.TransferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.TransferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.TransferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.TransferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.TransferReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional TransferReplay success = 1;
 * @return {?proto.account_operation_endpoint.TransferReplay}
 */
proto.account_operation_endpoint.TransferResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.TransferReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.TransferReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.TransferReplay|undefined} value
 * @return {!proto.account_operation_endpoint.TransferResponse} returns this
*/
proto.account_operation_endpoint.TransferResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.TransferResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.TransferResponse} returns this
 */
proto.account_operation_endpoint.TransferResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.TransferResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.WithdrawResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.WithdrawResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.WithdrawResponse.ResultCase}
 */
proto.account_operation_endpoint.WithdrawResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.WithdrawResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.WithdrawResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.WithdrawResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.WithdrawResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.WithdrawResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.WithdrawResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.WithdrawReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.WithdrawResponse}
 */
proto.account_operation_endpoint.WithdrawResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.WithdrawResponse;
  return proto.account_operation_endpoint.WithdrawResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.WithdrawResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.WithdrawResponse}
 */
proto.account_operation_endpoint.WithdrawResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.WithdrawReplay;
      reader.readMessage(value,proto.account_operation_endpoint.WithdrawReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.WithdrawResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.WithdrawResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.WithdrawResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.WithdrawResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.WithdrawReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional WithdrawReplay success = 1;
 * @return {?proto.account_operation_endpoint.WithdrawReplay}
 */
proto.account_operation_endpoint.WithdrawResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.WithdrawReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.WithdrawReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.WithdrawReplay|undefined} value
 * @return {!proto.account_operation_endpoint.WithdrawResponse} returns this
*/
proto.account_operation_endpoint.WithdrawResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.WithdrawResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.WithdrawResponse} returns this
 */
proto.account_operation_endpoint.WithdrawResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.WithdrawResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.ExportTransactionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.ExportTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.ExportTransactionResponse.ResultCase}
 */
proto.account_operation_endpoint.ExportTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.ExportTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.ExportTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.ExportTransactionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportTransactionResponse}
 */
proto.account_operation_endpoint.ExportTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportTransactionResponse;
  return proto.account_operation_endpoint.ExportTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportTransactionResponse}
 */
proto.account_operation_endpoint.ExportTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.ExportTransactionReplay;
      reader.readMessage(value,proto.account_operation_endpoint.ExportTransactionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.ExportTransactionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportTransactionReplay success = 1;
 * @return {?proto.account_operation_endpoint.ExportTransactionReplay}
 */
proto.account_operation_endpoint.ExportTransactionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.ExportTransactionReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.ExportTransactionReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.ExportTransactionReplay|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionResponse} returns this
*/
proto.account_operation_endpoint.ExportTransactionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.ExportTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionResponse} returns this
 */
proto.account_operation_endpoint.ExportTransactionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.GetTransactionSummaryResponse.ResultCase}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.GetTransactionSummaryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.GetTransactionSummaryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.GetTransactionSummaryReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryResponse}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionSummaryResponse;
  return proto.account_operation_endpoint.GetTransactionSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryResponse}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.GetTransactionSummaryReplay;
      reader.readMessage(value,proto.account_operation_endpoint.GetTransactionSummaryReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.GetTransactionSummaryReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTransactionSummaryReplay success = 1;
 * @return {?proto.account_operation_endpoint.GetTransactionSummaryReplay}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.GetTransactionSummaryReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.GetTransactionSummaryReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.GetTransactionSummaryReplay|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryResponse} returns this
*/
proto.account_operation_endpoint.GetTransactionSummaryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.GetTransactionSummaryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionSummaryResponse} returns this
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionSummaryResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.ExportTransactionsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.ExportTransactionsResponse.ResultCase}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.ExportTransactionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.ExportTransactionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExportTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExportTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.ExportTransactionsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExportTransactionsResponse}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExportTransactionsResponse;
  return proto.account_operation_endpoint.ExportTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExportTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExportTransactionsResponse}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.ExportTransactionsReplay;
      reader.readMessage(value,proto.account_operation_endpoint.ExportTransactionsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExportTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExportTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExportTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.ExportTransactionsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportTransactionsReplay success = 1;
 * @return {?proto.account_operation_endpoint.ExportTransactionsReplay}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.ExportTransactionsReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.ExportTransactionsReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.ExportTransactionsReplay|undefined} value
 * @return {!proto.account_operation_endpoint.ExportTransactionsResponse} returns this
*/
proto.account_operation_endpoint.ExportTransactionsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.ExportTransactionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExportTransactionsResponse} returns this
 */
proto.account_operation_endpoint.ExportTransactionsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExportTransactionsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.GetTransactionsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.GetTransactionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.GetTransactionsResponse.ResultCase}
 */
proto.account_operation_endpoint.GetTransactionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.GetTransactionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.GetTransactionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.GetTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.GetTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.GetTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.GetTransactionsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.GetTransactionsResponse}
 */
proto.account_operation_endpoint.GetTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.GetTransactionsResponse;
  return proto.account_operation_endpoint.GetTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.GetTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.GetTransactionsResponse}
 */
proto.account_operation_endpoint.GetTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.GetTransactionsReplay;
      reader.readMessage(value,proto.account_operation_endpoint.GetTransactionsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.GetTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.GetTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.GetTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.GetTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.GetTransactionsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTransactionsReplay success = 1;
 * @return {?proto.account_operation_endpoint.GetTransactionsReplay}
 */
proto.account_operation_endpoint.GetTransactionsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.GetTransactionsReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.GetTransactionsReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.GetTransactionsReplay|undefined} value
 * @return {!proto.account_operation_endpoint.GetTransactionsResponse} returns this
*/
proto.account_operation_endpoint.GetTransactionsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.GetTransactionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.GetTransactionsResponse} returns this
 */
proto.account_operation_endpoint.GetTransactionsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.GetTransactionsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_operation_endpoint.ExternalTransferResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_operation_endpoint.ExternalTransferResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_operation_endpoint.ExternalTransferResponse.ResultCase}
 */
proto.account_operation_endpoint.ExternalTransferResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_operation_endpoint.ExternalTransferResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_operation_endpoint.ExternalTransferResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_operation_endpoint.ExternalTransferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_operation_endpoint.ExternalTransferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_operation_endpoint.ExternalTransferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExternalTransferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_operation_endpoint.ExternalTransferReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_operation_endpoint.ExternalTransferResponse}
 */
proto.account_operation_endpoint.ExternalTransferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_operation_endpoint.ExternalTransferResponse;
  return proto.account_operation_endpoint.ExternalTransferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_operation_endpoint.ExternalTransferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_operation_endpoint.ExternalTransferResponse}
 */
proto.account_operation_endpoint.ExternalTransferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_operation_endpoint.ExternalTransferReplay;
      reader.readMessage(value,proto.account_operation_endpoint.ExternalTransferReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_operation_endpoint.ExternalTransferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_operation_endpoint.ExternalTransferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_operation_endpoint.ExternalTransferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_operation_endpoint.ExternalTransferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_operation_endpoint.ExternalTransferReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalTransferReplay success = 1;
 * @return {?proto.account_operation_endpoint.ExternalTransferReplay}
 */
proto.account_operation_endpoint.ExternalTransferResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_operation_endpoint.ExternalTransferReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_operation_endpoint.ExternalTransferReplay, 1));
};


/**
 * @param {?proto.account_operation_endpoint.ExternalTransferReplay|undefined} value
 * @return {!proto.account_operation_endpoint.ExternalTransferResponse} returns this
*/
proto.account_operation_endpoint.ExternalTransferResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_operation_endpoint.ExternalTransferResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_operation_endpoint.ExternalTransferResponse} returns this
 */
proto.account_operation_endpoint.ExternalTransferResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_operation_endpoint.ExternalTransferResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.account_operation_endpoint);
