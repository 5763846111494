import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL, APIRequest } from '../../../../app/Api';
import { base64ToArrayBuffer, downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../app/Helpers';
import { TableState } from '../../../common/Table/TableSate';
import { ReportEndpointClient } from '../../../../repository/Trading/ReportServiceClientPb';
import { CurrencyPairEndpointClient } from '../../../../repository/Trading/Currency_pairServiceClientPb';
import { GetTradingReportResponse, GetTradingReportRequest, ExportTradingReportResponse, ExportTradingReportRequest } from '../../../../repository/Trading/report_pb';
import { GetCurrencyPairsRequest, GetCurrencyPairsResponse } from '../../../../repository/Trading/currency_pair_pb';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../repository/Accountant/account_pb';


export interface TradingReportPageState {
    set: TableState
    message?: ApiMessage,
    currencyPairs: TableState
    accounts: TableState
    isChangeStateLoading: boolean
    totals: any



}

const initialState: TradingReportPageState = {

    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    message: undefined,
    totals: undefined,
    currencyPairs: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false


}
const client = new ReportEndpointClient(TRADING_API_URL, null, null);
const clientCurrencyPair = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);
const clientAccount = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<APIResponse<GetTradingReportResponse.AsObject>, APIRequest<GetTradingReportRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/tradingReport/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTradingReport(req.body, req.headers ?? {});
                return { metadata: { grouping: req.body.getGroupby() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCurrencyPairs = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/tradingReport/currencyPairs/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrencyPair.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/tradingReport/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientAccount.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)




export const exportReport = createAsyncThunk<APIResponse<ExportTradingReportResponse.AsObject>, APIRequest<ExportTradingReportRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/reports/tradingReport/export',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.exportTradingReport(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { exportType: req.body.getExporttype() } };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const tradingReportPageSlice = createSlice({
    name: 'pages/reports/tradingReport',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.currencyPairs = initialState.currencyPairs;
            state.accounts = initialState.accounts;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.totals = initialState.totals;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },
        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },
        resetCurrencyPairsSet: (state) => {
            state.currencyPairs.items = [];
            state.currencyPairs.hasMore = true;
        },

        setSelectedCurrencyPairsItems: (state, action: PayloadAction<any[]>) => {
            state.currencyPairs.selected = action.payload;
        },

        setIsFilteredCurrencyPairSet: (state, action: PayloadAction<boolean>) => {
            state.currencyPairs.isFilteredSet = action.payload;
        },
        setSelectedAccountItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
        },

        setIsFilteredAccountSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {


        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {
                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,

                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getCurrencyPairs.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value ,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,
                        isActive: val.isactive?.value,
                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencyPairs.numberOfResults) {
                    state.currencyPairs.hasMore = false;
                }
                state.currencyPairs.items = state.currencyPairs.items.concat(r);
            } else {
                state.currencyPairs.hasMore = false;

            }
            state.currencyPairs.isFetching = false

        })
        builder.addCase(getCurrencyPairs.rejected, (state, action) => {
            state.currencyPairs.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencyPairs.pending, (state, action) => {
            state.currencyPairs.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.response?.success?.reportList.map(val => {
                    let r = {
                        date: formatDate(timestampToDate(val.date?.seconds, val.date?.nanos, payload?.metadata?.grouping == 3), payload?.metadata?.grouping == 0 ? 'YYYY-MM-DD' : payload?.metadata?.grouping == 1 ? 'YYYY-MM' : payload?.metadata?.grouping == 2 ? 'YYYY' : payload?.metadata?.grouping == 3 ? "YYYY-MM-DD HH:00:00" : 'YYYY-MM-DD'),
                        avgBuyPrice: val.avgbuyprice?.value,
                        avgSellPrice: val.avgsellprice?.value,
                        numberOfBuyOperations: val.numberofbuyoperations?.value,
                        numberOfSellOperations: val.numberofselloperations?.value,
                        totalBought: val.totalbought?.value,
                        totalSold: val.totalsold?.value,
                        totalQuantitySold: val.totalquantitysold?.value,
                        totalQuantityBought: val.totalquantitybought?.value,
                        totalAvailable: val.totalavailable?.value,
                        totalQuantityAvailable: val.totalquantityavailable?.value,
                        currencyPairId: val.pair?.id?.value,
                        currencyPairName: val.pair?.name?.value,
                        currencyPairBaseCurrencyId: val.pair?.base?.id?.value ?? "",
                        currencyPairBaseCurrencyName: val.pair?.base?.name?.value ?? "",
                        currencyPairBaseCurrencyCode: val.pair?.base?.code?.value ?? "",
                        currencyPairBaseCurrencyDecimalPlaces: val.pair?.base?.decimalplaces?.value ?? undefined,
                        currencyPairBaseCurrencyForm: val.pair?.base?.form?.value ?? undefined,
                        currencyPairBaseCurrencySymbol: val.pair?.base?.symbol?.value ?? "",
                        currencyPairQuoteCurrencyId: val.pair?.quote?.id?.value ?? "",
                        currencyPairQuoteCurrencyName: val.pair?.quote?.name?.value ?? "",
                        currencyPairQuoteCurrencyCode: val.pair?.quote?.code?.value ?? "",
                        currencyPairQuoteCurrencyDecimalPlaces: val.pair?.quote?.decimalplaces?.value ?? undefined,
                        currencyPairQuoteCurrencyForm: val.pair?.quote?.form?.value ?? undefined,
                        currencyPairQuoteCurrencySymbol: val.pair?.quote?.symbol?.value ?? "",



                    }
                    return r;
                }
                ) as any[])

                if (r.length > 0 && state.currencyPairs.selected.length > 0) {
                    state.totals = {
                        avgBuyPrice: payload?.response?.success?.avgbuyprice?.value,
                        avgSellPrice: payload?.response?.success?.avgsellprice?.value,
                        numberOfBuyOperations: payload?.response?.success?.numberofbuyoperations?.value,
                        numberOfSellOperations: payload?.response?.success?.numberofselloperations?.value,
                        totalBought: payload?.response?.success?.totalbought?.value,
                        totalSold: payload?.response?.success?.totalsold?.value,
                        totalAvailable: payload?.response?.success?.totalavailable?.value,
                        totalQuantityAvailable: payload?.response?.success?.totalquantityavailable?.value,
                        totalQuantitySold: payload?.response?.success?.totalquantitysold?.value,
                        totalQuantityBought: payload?.response?.success?.totalquantitybought?.value,
                        quoteCurrency: { decimalPlaces: state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces, symbol: state.currencyPairs.selected.at(0)?.quoteCurrencySymbol },
                        baseCurrency: { decimalPlaces: state.currencyPairs.selected.at(0)?.baseCurrencyDecimalPlaces, symbol: state.currencyPairs.selected.at(0)?.baseCurrencySymbol }
                    }
                } else {
                    state.totals = undefined;
                }

                state.set.items = r

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })
        builder.addCase(exportReport.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload.response.success?.report) {

                downloadFile(payload.response.success?.report + "", payload.response.success.name?.value ?? "report" + getFileExtension(payload.metadata?.exportType))

            }

        })
        builder.addCase(exportReport.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(exportReport.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setMessage, resetSet, resetCurrencyPairsSet, setIsFilteredCurrencyPairSet, setSelectedCurrencyPairsItems, resetAccountsSet, setSelectedAccountItems, setIsFilteredAccountSet } = tradingReportPageSlice.actions

export default tradingReportPageSlice.reducer


