// source: treasury_operation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportStatementReplay', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportStatementRequest', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportStatementResponse', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportStatementResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportTransactionReplay', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportTransactionRequest', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportTransactionResponse', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.ExportTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetBalanceReplay', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetBalanceRequest', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetBalanceResponse', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetBalanceResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetStatementReplay', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetStatementRequest', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetStatementResponse', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetStatementResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetTransactionReplay', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetTransactionRequest', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetTransactionResponse', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.GetTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.Label', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.Party', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.TParty', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.Transaction', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.UpdateTransactionReplay', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.UpdateTransactionRequest', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.UpdateTransactionResponse', null, global);
goog.exportSymbol('proto.treasury_operation_endpoint.UpdateTransactionResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.GetBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetBalanceRequest.displayName = 'proto.treasury_operation_endpoint.GetBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.GetTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetTransactionRequest.displayName = 'proto.treasury_operation_endpoint.GetTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.GetStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetStatementRequest.displayName = 'proto.treasury_operation_endpoint.GetStatementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_operation_endpoint.UpdateTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_operation_endpoint.UpdateTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.UpdateTransactionRequest.displayName = 'proto.treasury_operation_endpoint.UpdateTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.Label.displayName = 'proto.treasury_operation_endpoint.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.ExportStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_operation_endpoint.ExportStatementRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_operation_endpoint.ExportStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.ExportStatementRequest.displayName = 'proto.treasury_operation_endpoint.ExportStatementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.ExportTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_operation_endpoint.ExportTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_operation_endpoint.ExportTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.ExportTransactionRequest.displayName = 'proto.treasury_operation_endpoint.ExportTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetBalanceReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.GetBalanceReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetBalanceReplay.displayName = 'proto.treasury_operation_endpoint.GetBalanceReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.UpdateTransactionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.UpdateTransactionReplay.displayName = 'proto.treasury_operation_endpoint.UpdateTransactionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.Party = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.Party, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.Party.displayName = 'proto.treasury_operation_endpoint.Party';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.TParty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.TParty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.TParty.displayName = 'proto.treasury_operation_endpoint.TParty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.Transaction.displayName = 'proto.treasury_operation_endpoint.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetTransactionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_operation_endpoint.GetTransactionReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_operation_endpoint.GetTransactionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetTransactionReplay.displayName = 'proto.treasury_operation_endpoint.GetTransactionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetStatementReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_operation_endpoint.GetStatementReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_operation_endpoint.GetStatementReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetStatementReplay.displayName = 'proto.treasury_operation_endpoint.GetStatementReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.ExportStatementReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.ExportStatementReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.ExportStatementReplay.displayName = 'proto.treasury_operation_endpoint.ExportStatementReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.ExportTransactionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_operation_endpoint.ExportTransactionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.ExportTransactionReplay.displayName = 'proto.treasury_operation_endpoint.ExportTransactionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_operation_endpoint.GetBalanceResponse.oneofGroups_);
};
goog.inherits(proto.treasury_operation_endpoint.GetBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetBalanceResponse.displayName = 'proto.treasury_operation_endpoint.GetBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetStatementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_operation_endpoint.GetStatementResponse.oneofGroups_);
};
goog.inherits(proto.treasury_operation_endpoint.GetStatementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetStatementResponse.displayName = 'proto.treasury_operation_endpoint.GetStatementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.GetTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_operation_endpoint.GetTransactionResponse.oneofGroups_);
};
goog.inherits(proto.treasury_operation_endpoint.GetTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.GetTransactionResponse.displayName = 'proto.treasury_operation_endpoint.GetTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_operation_endpoint.UpdateTransactionResponse.oneofGroups_);
};
goog.inherits(proto.treasury_operation_endpoint.UpdateTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.UpdateTransactionResponse.displayName = 'proto.treasury_operation_endpoint.UpdateTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.ExportStatementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_operation_endpoint.ExportStatementResponse.oneofGroups_);
};
goog.inherits(proto.treasury_operation_endpoint.ExportStatementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.ExportStatementResponse.displayName = 'proto.treasury_operation_endpoint.ExportStatementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_operation_endpoint.ExportTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_operation_endpoint.ExportTransactionResponse.oneofGroups_);
};
goog.inherits(proto.treasury_operation_endpoint.ExportTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_operation_endpoint.ExportTransactionResponse.displayName = 'proto.treasury_operation_endpoint.ExportTransactionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    treasuryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currencyid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetBalanceRequest}
 */
proto.treasury_operation_endpoint.GetBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetBalanceRequest;
  return proto.treasury_operation_endpoint.GetBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetBalanceRequest}
 */
proto.treasury_operation_endpoint.GetBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasuryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreasuryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrencyid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string treasuryId = 1;
 * @return {string}
 */
proto.treasury_operation_endpoint.GetBalanceRequest.prototype.getTreasuryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.GetBalanceRequest} returns this
 */
proto.treasury_operation_endpoint.GetBalanceRequest.prototype.setTreasuryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currencyId = 2;
 * @return {string}
 */
proto.treasury_operation_endpoint.GetBalanceRequest.prototype.getCurrencyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.GetBalanceRequest} returns this
 */
proto.treasury_operation_endpoint.GetBalanceRequest.prototype.setCurrencyid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetTransactionRequest}
 */
proto.treasury_operation_endpoint.GetTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetTransactionRequest;
  return proto.treasury_operation_endpoint.GetTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetTransactionRequest}
 */
proto.treasury_operation_endpoint.GetTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_operation_endpoint.GetTransactionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionRequest} returns this
 */
proto.treasury_operation_endpoint.GetTransactionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    treasury: (f = msg.getTreasury()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    numofresults: jspb.Message.getFieldWithDefault(msg, 9, 0),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest}
 */
proto.treasury_operation_endpoint.GetStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetStatementRequest;
  return proto.treasury_operation_endpoint.GetStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest}
 */
proto.treasury_operation_endpoint.GetStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasury(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTreasury();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue treasury = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getTreasury = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearTreasury = function() {
  return this.setTreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasTreasury = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue account = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue nextTo = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue search = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool order = 8;
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 numOfResults = 9;
 * @return {number}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.StringValue currency = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
*/
proto.treasury_operation_endpoint.GetStatementRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementRequest} returns this
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.UpdateTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionRequest}
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.UpdateTransactionRequest;
  return proto.treasury_operation_endpoint.UpdateTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionRequest}
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.UpdateTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionRequest} returns this
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated common.ExtraField extraFields = 2;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 2));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionRequest} returns this
*/
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionRequest} returns this
 */
proto.treasury_operation_endpoint.UpdateTransactionRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    propartyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelvalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.Label}
 */
proto.treasury_operation_endpoint.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.Label;
  return proto.treasury_operation_endpoint.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.Label}
 */
proto.treasury_operation_endpoint.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropartyname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelvalue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropartyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelvalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string propartyName = 1;
 * @return {string}
 */
proto.treasury_operation_endpoint.Label.prototype.getPropartyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.Label} returns this
 */
proto.treasury_operation_endpoint.Label.prototype.setPropartyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string labelValue = 2;
 * @return {string}
 */
proto.treasury_operation_endpoint.Label.prototype.getLabelvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.Label} returns this
 */
proto.treasury_operation_endpoint.Label.prototype.setLabelvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue value = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.Label.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Label} returns this
*/
proto.treasury_operation_endpoint.Label.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Label} returns this
 */
proto.treasury_operation_endpoint.Label.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Label.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_operation_endpoint.ExportStatementRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.ExportStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.ExportStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    treasury: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.treasury_operation_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    numofresults: jspb.Message.getFieldWithDefault(msg, 7, 0),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.ExportStatementRequest;
  return proto.treasury_operation_endpoint.ExportStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.ExportStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasury(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = new proto.treasury_operation_endpoint.Label;
      reader.readMessage(value,proto.treasury_operation_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.ExportStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.ExportStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTreasury();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.treasury_operation_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
*/
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp to = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
*/
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string treasury = 3;
 * @return {string}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getTreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setTreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Label labels = 5;
 * @return {!Array<!proto.treasury_operation_endpoint.Label>}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.treasury_operation_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_operation_endpoint.Label, 5));
};


/**
 * @param {!Array<!proto.treasury_operation_endpoint.Label>} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
*/
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.treasury_operation_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_operation_endpoint.Label}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.treasury_operation_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 6;
 * @return {number}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 numOfResults = 7;
 * @return {number}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Int32Value operation = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
*/
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue account = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
*/
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportStatementRequest} returns this
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportStatementRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.ExportTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.ExportTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iscredit: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.treasury_operation_endpoint.Label.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionRequest}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.ExportTransactionRequest;
  return proto.treasury_operation_endpoint.ExportTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.ExportTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionRequest}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscredit(value);
      break;
    case 3:
      var value = new proto.treasury_operation_endpoint.Label;
      reader.readMessage(value,proto.treasury_operation_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.ExportTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.ExportTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIscredit();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.treasury_operation_endpoint.Label.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.ExportTransactionRequest} returns this
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isCredit = 2;
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.getIscredit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_operation_endpoint.ExportTransactionRequest} returns this
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.setIscredit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Label labels = 3;
 * @return {!Array<!proto.treasury_operation_endpoint.Label>}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.treasury_operation_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_operation_endpoint.Label, 3));
};


/**
 * @param {!Array<!proto.treasury_operation_endpoint.Label>} value
 * @return {!proto.treasury_operation_endpoint.ExportTransactionRequest} returns this
*/
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.treasury_operation_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_operation_endpoint.Label}
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.treasury_operation_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionRequest} returns this
 */
proto.treasury_operation_endpoint.ExportTransactionRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetBalanceReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetBalanceReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetBalanceReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    balance: (f = msg.getBalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetBalanceReplay}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetBalanceReplay;
  return proto.treasury_operation_endpoint.GetBalanceReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetBalanceReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetBalanceReplay}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetBalanceReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetBalanceReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetBalanceReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue balance = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.getBalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetBalanceReplay} returns this
*/
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetBalanceReplay} returns this
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.CurrencyResponse currency = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetBalanceReplay} returns this
*/
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetBalanceReplay} returns this
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetBalanceReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.UpdateTransactionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionReplay}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.UpdateTransactionReplay;
  return proto.treasury_operation_endpoint.UpdateTransactionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionReplay}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.UpdateTransactionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.UpdateTransactionReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.Party.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.Party.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.Party} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.Party.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    internalid: (f = msg.getInternalid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balancebefore: (f = msg.getBalancebefore()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balanceafter: (f = msg.getBalanceafter()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    istreasury: (f = msg.getIstreasury()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.Party}
 */
proto.treasury_operation_endpoint.Party.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.Party;
  return proto.treasury_operation_endpoint.Party.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.Party} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.Party}
 */
proto.treasury_operation_endpoint.Party.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalancebefore(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalanceafter(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIstreasury(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.Party.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.Party.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.Party} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.Party.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalancebefore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalanceafter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIstreasury();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int64Value id = 1;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.treasury_operation_endpoint.Party.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.treasury_operation_endpoint.Party} returns this
*/
proto.treasury_operation_endpoint.Party.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Party} returns this
 */
proto.treasury_operation_endpoint.Party.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Party.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue internalId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.Party.prototype.getInternalid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Party} returns this
*/
proto.treasury_operation_endpoint.Party.prototype.setInternalid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Party} returns this
 */
proto.treasury_operation_endpoint.Party.prototype.clearInternalid = function() {
  return this.setInternalid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Party.prototype.hasInternalid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.Party.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Party} returns this
*/
proto.treasury_operation_endpoint.Party.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Party} returns this
 */
proto.treasury_operation_endpoint.Party.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Party.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue description = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.Party.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Party} returns this
*/
proto.treasury_operation_endpoint.Party.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Party} returns this
 */
proto.treasury_operation_endpoint.Party.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Party.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue balanceBefore = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.Party.prototype.getBalancebefore = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Party} returns this
*/
proto.treasury_operation_endpoint.Party.prototype.setBalancebefore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Party} returns this
 */
proto.treasury_operation_endpoint.Party.prototype.clearBalancebefore = function() {
  return this.setBalancebefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Party.prototype.hasBalancebefore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue balanceAfter = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.Party.prototype.getBalanceafter = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Party} returns this
*/
proto.treasury_operation_endpoint.Party.prototype.setBalanceafter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Party} returns this
 */
proto.treasury_operation_endpoint.Party.prototype.clearBalanceafter = function() {
  return this.setBalanceafter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Party.prototype.hasBalanceafter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isTreasury = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_operation_endpoint.Party.prototype.getIstreasury = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Party} returns this
*/
proto.treasury_operation_endpoint.Party.prototype.setIstreasury = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Party} returns this
 */
proto.treasury_operation_endpoint.Party.prototype.clearIstreasury = function() {
  return this.setIstreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Party.prototype.hasIstreasury = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.TParty.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.TParty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.TParty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.TParty.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    internalid: (f = msg.getInternalid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balancebefore: (f = msg.getBalancebefore()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balanceafter: (f = msg.getBalanceafter()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    note: (f = msg.getNote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    istreasury: (f = msg.getIstreasury()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    feetransaction: (f = msg.getFeetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    receivedmessage: (f = msg.getReceivedmessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.TParty}
 */
proto.treasury_operation_endpoint.TParty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.TParty;
  return proto.treasury_operation_endpoint.TParty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.TParty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.TParty}
 */
proto.treasury_operation_endpoint.TParty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalancebefore(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalanceafter(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIstreasury(value);
      break;
    case 9:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setFeetransaction(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReceivedmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.TParty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.TParty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.TParty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.TParty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalancebefore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalanceafter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIstreasury();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getFeetransaction();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getReceivedmessage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int64Value id = 1;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.treasury_operation_endpoint.TParty.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue internalId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getInternalid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setInternalid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearInternalid = function() {
  return this.setInternalid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasInternalid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue description = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue balanceBefore = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getBalancebefore = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setBalancebefore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearBalancebefore = function() {
  return this.setBalancebefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasBalancebefore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue balanceAfter = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getBalanceafter = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setBalanceafter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearBalanceafter = function() {
  return this.setBalanceafter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasBalanceafter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue note = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getNote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasNote = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue isTreasury = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getIstreasury = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setIstreasury = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearIstreasury = function() {
  return this.setIstreasury(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasIstreasury = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.TransactionResponse feeTransaction = 9;
 * @return {?proto.common.TransactionResponse}
 */
proto.treasury_operation_endpoint.TParty.prototype.getFeetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 9));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setFeetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearFeetransaction = function() {
  return this.setFeetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasFeetransaction = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue receivedMessage = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.TParty.prototype.getReceivedmessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
*/
proto.treasury_operation_endpoint.TParty.prototype.setReceivedmessage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.TParty} returns this
 */
proto.treasury_operation_endpoint.TParty.prototype.clearReceivedmessage = function() {
  return this.setReceivedmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.TParty.prototype.hasReceivedmessage = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    debtor: (f = msg.getDebtor()) && proto.treasury_operation_endpoint.Party.toObject(includeInstance, f),
    creditor: (f = msg.getCreditor()) && proto.treasury_operation_endpoint.Party.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.Transaction}
 */
proto.treasury_operation_endpoint.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.Transaction;
  return proto.treasury_operation_endpoint.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.Transaction}
 */
proto.treasury_operation_endpoint.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new proto.treasury_operation_endpoint.Party;
      reader.readMessage(value,proto.treasury_operation_endpoint.Party.deserializeBinaryFromReader);
      msg.setDebtor(value);
      break;
    case 5:
      var value = new proto.treasury_operation_endpoint.Party;
      reader.readMessage(value,proto.treasury_operation_endpoint.Party.deserializeBinaryFromReader);
      msg.setCreditor(value);
      break;
    case 6:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDebtor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.treasury_operation_endpoint.Party.serializeBinaryToWriter
    );
  }
  f = message.getCreditor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.treasury_operation_endpoint.Party.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string internalId = 1;
 * @return {string}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Party debtor = 4;
 * @return {?proto.treasury_operation_endpoint.Party}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getDebtor = function() {
  return /** @type{?proto.treasury_operation_endpoint.Party} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.Party, 4));
};


/**
 * @param {?proto.treasury_operation_endpoint.Party|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setDebtor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearDebtor = function() {
  return this.setDebtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasDebtor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Party creditor = 5;
 * @return {?proto.treasury_operation_endpoint.Party}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getCreditor = function() {
  return /** @type{?proto.treasury_operation_endpoint.Party} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.Party, 5));
};


/**
 * @param {?proto.treasury_operation_endpoint.Party|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setCreditor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearCreditor = function() {
  return this.setCreditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasCreditor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.CurrencyResponse currency = 6;
 * @return {?proto.common.CurrencyResponse}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 6));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue amount = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 10;
 * @return {?proto.common.TransactionResponse}
 */
proto.treasury_operation_endpoint.Transaction.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 10));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
*/
proto.treasury_operation_endpoint.Transaction.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.Transaction} returns this
 */
proto.treasury_operation_endpoint.Transaction.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.Transaction.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_operation_endpoint.GetTransactionReplay.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetTransactionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetTransactionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetTransactionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    debtor: (f = msg.getDebtor()) && proto.treasury_operation_endpoint.TParty.toObject(includeInstance, f),
    creditor: (f = msg.getCreditor()) && proto.treasury_operation_endpoint.TParty.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetTransactionReplay;
  return proto.treasury_operation_endpoint.GetTransactionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetTransactionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new proto.treasury_operation_endpoint.TParty;
      reader.readMessage(value,proto.treasury_operation_endpoint.TParty.deserializeBinaryFromReader);
      msg.setDebtor(value);
      break;
    case 5:
      var value = new proto.treasury_operation_endpoint.TParty;
      reader.readMessage(value,proto.treasury_operation_endpoint.TParty.deserializeBinaryFromReader);
      msg.setCreditor(value);
      break;
    case 6:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 11:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetTransactionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetTransactionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetTransactionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDebtor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.treasury_operation_endpoint.TParty.serializeBinaryToWriter
    );
  }
  f = message.getCreditor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.treasury_operation_endpoint.TParty.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string internalId = 1;
 * @return {string}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TParty debtor = 4;
 * @return {?proto.treasury_operation_endpoint.TParty}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getDebtor = function() {
  return /** @type{?proto.treasury_operation_endpoint.TParty} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.TParty, 4));
};


/**
 * @param {?proto.treasury_operation_endpoint.TParty|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setDebtor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearDebtor = function() {
  return this.setDebtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasDebtor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TParty creditor = 5;
 * @return {?proto.treasury_operation_endpoint.TParty}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getCreditor = function() {
  return /** @type{?proto.treasury_operation_endpoint.TParty} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.TParty, 5));
};


/**
 * @param {?proto.treasury_operation_endpoint.TParty|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setCreditor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearCreditor = function() {
  return this.setCreditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasCreditor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.CurrencyResponse currency = 6;
 * @return {?proto.common.CurrencyResponse}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 6));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue amount = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 10;
 * @return {?proto.common.TransactionResponse}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 10));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated common.ExtraField extraFields = 11;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 11));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_operation_endpoint.GetTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.GetTransactionReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_operation_endpoint.GetStatementReplay.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetStatementReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetStatementReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetStatementReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    feetransaction: (f = msg.getFeetransaction()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.treasury_operation_endpoint.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetStatementReplay}
 */
proto.treasury_operation_endpoint.GetStatementReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetStatementReplay;
  return proto.treasury_operation_endpoint.GetStatementReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetStatementReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetStatementReplay}
 */
proto.treasury_operation_endpoint.GetStatementReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFeetransaction(value);
      break;
    case 2:
      var value = new proto.treasury_operation_endpoint.Transaction;
      reader.readMessage(value,proto.treasury_operation_endpoint.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetStatementReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetStatementReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetStatementReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeetransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.treasury_operation_endpoint.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue feeTransaction = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.getFeetransaction = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementReplay} returns this
*/
proto.treasury_operation_endpoint.GetStatementReplay.prototype.setFeetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementReplay} returns this
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.clearFeetransaction = function() {
  return this.setFeetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.hasFeetransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Transaction transactions = 2;
 * @return {!Array<!proto.treasury_operation_endpoint.Transaction>}
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.treasury_operation_endpoint.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_operation_endpoint.Transaction, 2));
};


/**
 * @param {!Array<!proto.treasury_operation_endpoint.Transaction>} value
 * @return {!proto.treasury_operation_endpoint.GetStatementReplay} returns this
*/
proto.treasury_operation_endpoint.GetStatementReplay.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.treasury_operation_endpoint.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_operation_endpoint.Transaction}
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.treasury_operation_endpoint.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_operation_endpoint.GetStatementReplay} returns this
 */
proto.treasury_operation_endpoint.GetStatementReplay.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.ExportStatementReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.ExportStatementReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportStatementReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    statement: msg.getStatement_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.ExportStatementReplay}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.ExportStatementReplay;
  return proto.treasury_operation_endpoint.ExportStatementReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.ExportStatementReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.ExportStatementReplay}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStatement(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.ExportStatementReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.ExportStatementReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportStatementReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes statement = 1;
 * @return {!(string|Uint8Array)}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.getStatement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes statement = 1;
 * This is a type-conversion wrapper around `getStatement()`
 * @return {string}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.getStatement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStatement()));
};


/**
 * optional bytes statement = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStatement()`
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.getStatement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStatement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementReplay} returns this
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.setStatement = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementReplay} returns this
*/
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportStatementReplay} returns this
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportStatementReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.ExportTransactionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.ExportTransactionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    statement: msg.getStatement_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionReplay}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.ExportTransactionReplay;
  return proto.treasury_operation_endpoint.ExportTransactionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.ExportTransactionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionReplay}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStatement(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.ExportTransactionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.ExportTransactionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatement_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes statement = 1;
 * @return {!(string|Uint8Array)}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.getStatement = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes statement = 1;
 * This is a type-conversion wrapper around `getStatement()`
 * @return {string}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.getStatement_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStatement()));
};


/**
 * optional bytes statement = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStatement()`
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.getStatement_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStatement()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.treasury_operation_endpoint.ExportTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.setStatement = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportTransactionReplay} returns this
*/
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionReplay} returns this
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportTransactionReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_operation_endpoint.GetBalanceResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_operation_endpoint.GetBalanceResponse.ResultCase}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_operation_endpoint.GetBalanceResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_operation_endpoint.GetBalanceResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_operation_endpoint.GetBalanceReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetBalanceResponse}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetBalanceResponse;
  return proto.treasury_operation_endpoint.GetBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetBalanceResponse}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_operation_endpoint.GetBalanceReplay;
      reader.readMessage(value,proto.treasury_operation_endpoint.GetBalanceReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_operation_endpoint.GetBalanceReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetBalanceReplay success = 1;
 * @return {?proto.treasury_operation_endpoint.GetBalanceReplay}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_operation_endpoint.GetBalanceReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.GetBalanceReplay, 1));
};


/**
 * @param {?proto.treasury_operation_endpoint.GetBalanceReplay|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetBalanceResponse} returns this
*/
proto.treasury_operation_endpoint.GetBalanceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_operation_endpoint.GetBalanceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetBalanceResponse} returns this
 */
proto.treasury_operation_endpoint.GetBalanceResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetBalanceResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_operation_endpoint.GetStatementResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_operation_endpoint.GetStatementResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_operation_endpoint.GetStatementResponse.ResultCase}
 */
proto.treasury_operation_endpoint.GetStatementResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_operation_endpoint.GetStatementResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_operation_endpoint.GetStatementResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetStatementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetStatementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetStatementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetStatementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_operation_endpoint.GetStatementReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetStatementResponse}
 */
proto.treasury_operation_endpoint.GetStatementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetStatementResponse;
  return proto.treasury_operation_endpoint.GetStatementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetStatementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetStatementResponse}
 */
proto.treasury_operation_endpoint.GetStatementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_operation_endpoint.GetStatementReplay;
      reader.readMessage(value,proto.treasury_operation_endpoint.GetStatementReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetStatementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetStatementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetStatementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetStatementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_operation_endpoint.GetStatementReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetStatementReplay success = 1;
 * @return {?proto.treasury_operation_endpoint.GetStatementReplay}
 */
proto.treasury_operation_endpoint.GetStatementResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_operation_endpoint.GetStatementReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.GetStatementReplay, 1));
};


/**
 * @param {?proto.treasury_operation_endpoint.GetStatementReplay|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetStatementResponse} returns this
*/
proto.treasury_operation_endpoint.GetStatementResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_operation_endpoint.GetStatementResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetStatementResponse} returns this
 */
proto.treasury_operation_endpoint.GetStatementResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetStatementResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_operation_endpoint.GetTransactionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_operation_endpoint.GetTransactionResponse.ResultCase}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_operation_endpoint.GetTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_operation_endpoint.GetTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.GetTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.GetTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_operation_endpoint.GetTransactionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.GetTransactionResponse}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.GetTransactionResponse;
  return proto.treasury_operation_endpoint.GetTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.GetTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.GetTransactionResponse}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_operation_endpoint.GetTransactionReplay;
      reader.readMessage(value,proto.treasury_operation_endpoint.GetTransactionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.GetTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.GetTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.GetTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_operation_endpoint.GetTransactionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTransactionReplay success = 1;
 * @return {?proto.treasury_operation_endpoint.GetTransactionReplay}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_operation_endpoint.GetTransactionReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.GetTransactionReplay, 1));
};


/**
 * @param {?proto.treasury_operation_endpoint.GetTransactionReplay|undefined} value
 * @return {!proto.treasury_operation_endpoint.GetTransactionResponse} returns this
*/
proto.treasury_operation_endpoint.GetTransactionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_operation_endpoint.GetTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.GetTransactionResponse} returns this
 */
proto.treasury_operation_endpoint.GetTransactionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.GetTransactionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_operation_endpoint.UpdateTransactionResponse.ResultCase}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_operation_endpoint.UpdateTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_operation_endpoint.UpdateTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.UpdateTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_operation_endpoint.UpdateTransactionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionResponse}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.UpdateTransactionResponse;
  return proto.treasury_operation_endpoint.UpdateTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionResponse}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_operation_endpoint.UpdateTransactionReplay;
      reader.readMessage(value,proto.treasury_operation_endpoint.UpdateTransactionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.UpdateTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.UpdateTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_operation_endpoint.UpdateTransactionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTransactionReplay success = 1;
 * @return {?proto.treasury_operation_endpoint.UpdateTransactionReplay}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_operation_endpoint.UpdateTransactionReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.UpdateTransactionReplay, 1));
};


/**
 * @param {?proto.treasury_operation_endpoint.UpdateTransactionReplay|undefined} value
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionResponse} returns this
*/
proto.treasury_operation_endpoint.UpdateTransactionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_operation_endpoint.UpdateTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.UpdateTransactionResponse} returns this
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.UpdateTransactionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_operation_endpoint.ExportStatementResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_operation_endpoint.ExportStatementResponse.ResultCase}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_operation_endpoint.ExportStatementResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_operation_endpoint.ExportStatementResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.ExportStatementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.ExportStatementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportStatementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_operation_endpoint.ExportStatementReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.ExportStatementResponse}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.ExportStatementResponse;
  return proto.treasury_operation_endpoint.ExportStatementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.ExportStatementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.ExportStatementResponse}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_operation_endpoint.ExportStatementReplay;
      reader.readMessage(value,proto.treasury_operation_endpoint.ExportStatementReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.ExportStatementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.ExportStatementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportStatementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_operation_endpoint.ExportStatementReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportStatementReplay success = 1;
 * @return {?proto.treasury_operation_endpoint.ExportStatementReplay}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_operation_endpoint.ExportStatementReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.ExportStatementReplay, 1));
};


/**
 * @param {?proto.treasury_operation_endpoint.ExportStatementReplay|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportStatementResponse} returns this
*/
proto.treasury_operation_endpoint.ExportStatementResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_operation_endpoint.ExportStatementResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportStatementResponse} returns this
 */
proto.treasury_operation_endpoint.ExportStatementResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportStatementResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_operation_endpoint.ExportTransactionResponse.ResultCase}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_operation_endpoint.ExportTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_operation_endpoint.ExportTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_operation_endpoint.ExportTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_operation_endpoint.ExportTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_operation_endpoint.ExportTransactionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionResponse}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_operation_endpoint.ExportTransactionResponse;
  return proto.treasury_operation_endpoint.ExportTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_operation_endpoint.ExportTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionResponse}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_operation_endpoint.ExportTransactionReplay;
      reader.readMessage(value,proto.treasury_operation_endpoint.ExportTransactionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_operation_endpoint.ExportTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_operation_endpoint.ExportTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_operation_endpoint.ExportTransactionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportTransactionReplay success = 1;
 * @return {?proto.treasury_operation_endpoint.ExportTransactionReplay}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_operation_endpoint.ExportTransactionReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_operation_endpoint.ExportTransactionReplay, 1));
};


/**
 * @param {?proto.treasury_operation_endpoint.ExportTransactionReplay|undefined} value
 * @return {!proto.treasury_operation_endpoint.ExportTransactionResponse} returns this
*/
proto.treasury_operation_endpoint.ExportTransactionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_operation_endpoint.ExportTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_operation_endpoint.ExportTransactionResponse} returns this
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_operation_endpoint.ExportTransactionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.treasury_operation_endpoint);
