import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL, APIResponse } from '../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { BalanceReservationEndpointClient } from '../../../../repository/Accountant/Balance_reservationServiceClientPb';
import { ReserveAmountRequest, ReserveAmountResponse, FreeAmountResponse, FreeAmountRequest, GetBalanceReservationResponse, GetBalanceReservationRequest, UpdateBalanceReservationRequest, UpdateBalanceReservationResponse } from '../../../../repository/Accountant/balance_reservation_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../repository/Accountant/account_pb';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { TableState } from '../../../common/Table/TableSate';

export interface ReservedBalancesFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    accounts: TableState,
    requireAuth: boolean

}

const initialState: ReservedBalancesFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    requireAuth: false

}

const client = new BalanceReservationEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);

export const add = createAsyncThunk<ReserveAmountResponse.AsObject, APIRequest<ReserveAmountRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.reserveAmount(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)






export const get = createAsyncThunk<GetBalanceReservationResponse.AsObject, APIRequest<GetBalanceReservationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getBalanceReservation(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const update = createAsyncThunk<UpdateBalanceReservationResponse.AsObject, APIRequest<UpdateBalanceReservationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const reservedBalancesFormSlice = createSlice({
    name: 'forms/accountant/reservedBalances',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.accounts = initialState.accounts;
            state.requireAuth = initialState.requireAuth;

        },
        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },
        setIsAuthorizationRequired: (state, action: PayloadAction<boolean>) => {
            state.requireAuth = action.payload;
        },
        setSelectedAccountItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
        },

        setIsFilteredAccountSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload?.id,
                accountId: action.payload?.accountId,
                accountName: action.payload?.accountName,
                currencyId: action.payload?.currencyId,
                currencyName: action.payload?.currencyName,
                currencyCode: action.payload?.currencyCode,
                currencyDecimalPlaces: action.payload?.currencyDecimalPlaces,
                currencyForm: action.payload?.currencyForm,
                currencySymbol: action.payload?.currencySymbol,
                amount: action.payload?.amount,
                reason: action.payload?.reason,
                statusCreatedBy: action.payload?.statusCreatedBy,
                statusCreatedDate: action.payload?.statusCreatedDate,
                status: action.payload?.status,
                statusTransactionId: action.payload?.statusTransactionId,
                statusTransactionInternalId: action.payload?.statusTransactionInternalId,
                referenceTransactionId: action.payload?.referenceTransactionId,
                referenceTransactionInternalId: action.payload?.referenceTransactionInternalId,
                createdDate: action.payload?.createdDate,
                addedBy: action.payload?.addedBy,
                addedById: action.payload?.addedById,
                extraFields:action.payload?.extraFields,
                note: action.payload?.note,


            }
            state.stage1 = stage1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    accountId: payload.success?.account?.id?.value ?? "",
                    accountName: payload.success?.account?.name?.value ?? "",
                    currencyId: payload.success?.account?.currency?.id?.value ?? "",
                    currencyName: payload.success?.account?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.account?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.account?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.account?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.account?.currency?.symbol?.value ?? "",
                    amount: payload.success?.amount?.value ?? undefined,
                    reason: payload.success?.reason?.value ?? undefined,
                    note: payload.success?.note?.value ?? undefined,
                    statusCreatedBy: (payload.success?.status?.createdby?.firstname?.value ?? "") + " " + (payload.success?.status?.createdby?.lastname?.value ?? ""),
                    statusCreatedDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    status: payload.success?.status?.status?.value ?? undefined,
                    statusTransactionId: payload.success?.status?.createdtransaction?.id?.value ?? undefined,
                    statusTransactionInternalId: payload.success?.status?.createdtransaction?.internalid?.value ?? "",
                    referenceTransactionId: payload.success?.referencetransaction?.id?.value ?? undefined,
                    referenceTransactionInternalId: payload.success?.referencetransaction?.internalid?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[]

                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                                                isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
           // state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1 , setIsAuthorizationRequired, resetAccountsSet, setIsFilteredAccountSet, setSelectedAccountItems} = reservedBalancesFormSlice.actions

export default reservedBalancesFormSlice.reducer


