import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL, APIRequest } from '../../../../app/Api';
import { base64ToArrayBuffer, downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../app/Helpers';

import { TableState } from '../../../common/Table/TableSate';
import { ReportEndpointClient } from '../../../../repository/Trading/ReportServiceClientPb';
import { CurrencyPairEndpointClient } from '../../../../repository/Trading/Currency_pairServiceClientPb';
import { ExportCurrencyExchangeRatesReportRequest, ExportCurrencyExchangeRatesReportResponse, GetCurrencyExchangeRatesReportRequest, GetCurrencyExchangeRatesReportResponse } from '../../../../repository/Trading/report_pb';
import { GetCurrencyPairsRequest, GetCurrencyPairsResponse } from '../../../../repository/Trading/currency_pair_pb';


export interface CurrencyExchangeRatesReportPageState {
    set: TableState
    message?: ApiMessage,
    currencyPairs: TableState
    isChangeStateLoading: boolean,

}

const initialState: CurrencyExchangeRatesReportPageState = {

    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    message: undefined,
    currencyPairs: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false


}
const client = new ReportEndpointClient(TRADING_API_URL, null, null);
const clientCurrencyPair = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);

export const getItems = createAsyncThunk<APIResponse<GetCurrencyExchangeRatesReportResponse.AsObject>, APIRequest<GetCurrencyExchangeRatesReportRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/currencyExchangeRatesReport/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getCurrencyExchangeRatesReport(req.body, req.headers ?? {});
                return { metadata: { grouping: req.body.getGroupby() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCurrencyPairs = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/currencyExchangeRatesReport/currencyPairs/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrencyPair.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const exportReport = createAsyncThunk<APIResponse<ExportCurrencyExchangeRatesReportResponse.AsObject>, APIRequest<ExportCurrencyExchangeRatesReportRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/reports/currencyExchangeRatesReport/export',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.exportCurrencyExchangeRatesReport(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { exportType: req.body.getExporttype() } };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const currencyExchangeRatesReportPageSlice = createSlice({
    name: 'pages/reports/currencyExchangeRatesReport',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.currencyPairs = initialState.currencyPairs;
            state.isChangeStateLoading = initialState.isChangeStateLoading;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

        resetCurrencyPairsSet: (state) => {
            state.currencyPairs.items = [];
            state.currencyPairs.hasMore = true;
        },

        setSelectedCurrencyPairsItems: (state, action: PayloadAction<any[]>) => {
            state.currencyPairs.selected = action.payload;
        },

        setIsFilteredCurrencyPairSet: (state, action: PayloadAction<boolean>) => {
            state.currencyPairs.isFilteredSet = action.payload;
        },

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {


        builder.addCase(getCurrencyPairs.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value ,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,
                        isActive: val.isactive?.value,
                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }

                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencyPairs.numberOfResults) {
                    state.currencyPairs.hasMore = false;
                }
                state.currencyPairs.items = state.currencyPairs.items.concat(r);
            } else {
                state.currencyPairs.hasMore = false;

            }
            state.currencyPairs.isFetching = false

        })
        builder.addCase(getCurrencyPairs.rejected, (state, action) => {
            state.currencyPairs.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencyPairs.pending, (state, action) => {
            state.currencyPairs.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.response?.success?.reportList.map(val => {
                    let r = {
                        date: formatDate(timestampToDate(val.date?.seconds, val.date?.nanos, payload?.metadata?.grouping >= 3), payload?.metadata?.grouping == 0 ? 'YYYY-MM-DD' : payload?.metadata?.grouping == 1 ? 'YYYY-MM' : payload?.metadata?.grouping == 2 ? 'YYYY' : payload?.metadata?.grouping == 3 ? "YYYY-MM-DD HH:00:00" : 'YYYY-MM-DD HH:mm:ss'),



                        openBuyPrice: val.openbuyprice?.value,
                        openSellprice: val.opensellprice?.value,
                        openAvgPrice: val.openavgprice?.value,
                        openTradingPrice: val.opentradingprice?.value,

                        highestBuyPrice: val.highestbuyprice?.value,
                        lowsetBuyPrice: val.lowsetbuyprice?.value,
                        highestSellPrice: val.highestsellprice?.value,
                        lowsetSellPrice: val.lowsetsellprice?.value,
                        highestTradingPrice: val.highesttradingprice?.value,
                        lowsetTradingPrice: val.lowsettradingprice?.value,
                        highestAVGPrice: val.highestavgprice?.value,
                        lowsetAVGPrice: val.lowsetavgprice?.value,

                        closeSellPrice: val.closesellprice?.value,
                        closeBuyPrice: val.closebuyprice?.value,
                        closeAvgPrice: val.closeavgprice?.value,
                        closeTradingPrice: val.closetradingprice?.value,

                        volumeQuote: val.volumequote?.value,
                        volumeBase: val.volumebase?.value,
                        quoteCurrency: { decimalPlaces: state.currencyPairs.selected.at(0).quoteCurrencyDecimalPlaces, symbol: state.currencyPairs.selected.at(0).quoteCurrencySymbol },
                        baseCurrency: { decimalPlaces: state.currencyPairs.selected.at(0).baseCurrencyDecimalPlaces, symbol: state.currencyPairs.selected.at(0).baseCurrencySymbol }

                    }
                    //console.log(r)
                    return r;
                }
                ) as any[])
                state.set.items = r

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(exportReport.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload.response.success?.report) {
                downloadFile(payload.response.success?.report + "", payload.response.success.name?.value ?? "report" + getFileExtension(payload.metadata?.exportType))

            }

        })
        builder.addCase(exportReport.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(exportReport.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setMessage, resetSet, resetCurrencyPairsSet, setIsFilteredCurrencyPairSet, setSelectedCurrencyPairsItems } = currencyExchangeRatesReportPageSlice.actions

export default currencyExchangeRatesReportPageSlice.reducer


