import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, CheckboxVisibility, TooltipHost, Icon, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, getItems, getAutorizedClientUsers, setIsFilteredAutorizedClientSet, resetAutorizedClients, changeOwnership, getAccountTypes, getClinetUsers, getCurrencies, resetAccountTypes, resetCurrencies, resetUsers, setCodeFilterOption, setIsDebtorFilterOption, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet, setIsFilteredOwnersSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setSelectedLimitationItems, setSelectedUserItems, setStatusFilterOption, updateStatus, closeAccount, resetLimitations, getLimitations, setAuthorizationFilterOption, addAmount, withdrawAmount, setAccountLinkingFilterOption, removeLink, setSelectedAutorizedClientItems } from "./AccountsPageSlice"
import { ApiMessage, CLIENT_PROFILE_LOGO_URL, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, normalizeKey, printComponent } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums"
import { CloseAccountRequest, GetAccountsRequest, RevokeLinkRequest, UpdateAccountStatusRequest } from "../../../../repository/Accountant/account_pb"
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { AccountsForm } from "../../../forms/Accountant/Accounts/AccountsForm"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { UsersPage } from "../../Customers/Users/UsersPage"
import { AccountTypesPage } from "../AccountTypes/AccountTypesPage"
import { CurrenciesPage } from "../Currencies/CurrenciesPage"
import { TransferForm } from "../../../forms/Accountant/Operations/Transfer/TransferForm"
import { DepositForm } from "../../../forms/Accountant/Operations/Deposit/DepositForm"
import { WithdrawForm } from "../../../forms/Accountant/Operations/Withdraw/WithdrawForm"
import { ExternalTransferForm } from "../../../forms/Accountant/Operations/ExternalTransfare/ExternalTransfareForm"
import { LinkAccountForm } from "../../../forms/Accountant/Accounts/LinkAccountForm"


const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetAccountsRequest;
let accountTypesReq: GetAccountTypesRequest;
let currenciesReq: GetCurrenciesRequest;
let limitationReq: GetLimitationsRequest;
let clintsReq: GetUsersRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "accountsManagement/accounts"
export const AccountsPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        isDebtorFilterOption: { key: number, text: string },
        filters: any[],
        codeFilterOption: { value: string, text: string }
        statusFilterOption: { key: number, text: string }
        accountTypes: TableState
        currencies: TableState
        owners: TableState,
        autorizedClient: TableState,
        limitations: TableState
        authorizationFilterOption: { key: number, text: string },
        accountLinkingFilterOption: { key: number, text: string },


    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.accountsPage.isChangeStateLoading,
            set: state.accountsPage.set,
            message: state.accountsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.accountsPage.searchText,
            accountTypes: state.accountsPage.accountTypes,
            currencies: state.accountsPage.currencies,
            owners: state.accountsPage.owners,
            autorizedClient: state.accountsPage.autorizedClient,
            codeFilterOption: state.accountsPage.codeFilterOption,
            filters: state.accountsPage.filters,
            isDebtorFilterOption: state.accountsPage.isDebtorFilterOption,
            statusFilterOption: state.accountsPage.statusFilterOption,
            limitations: state.accountsPage.limitations,
            authorizationFilterOption: state.accountsPage.authorizationFilterOption,
            accountLinkingFilterOption: state.accountsPage.accountLinkingFilterOption

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeAccountActionConfirmationcalloutTargetId = useId('close-account-action-callout-target');
    const removeLinkActionConfirmationcalloutTargetId = useId('remove-link-action-callout-target');
    const executeTransactionActionConfirmationcalloutTargetId = useId('close-transaction-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)

    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeAccountActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else if (action == 3) {
            return executeTransactionActionConfirmationcalloutTargetId;
        } else if (action == 4) {
            return removeLinkActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedAccountTypeItems([]))
        req.setAccounttype(undefined)
        dispatch(setSelectedCurrencyItems([]))
        req.setCurrency(undefined)
        dispatch(setSelectedUserItems([]))
        req.setOwner(undefined)
        dispatch(setStatusFilterOption({ key: 0, text: "" }))
        req.setStatus(undefined)
        dispatch(setCodeFilterOption({ text: "", value: "" }))
        req.setCode(undefined)
        dispatch(setIsDebtorFilterOption({ key: 0, text: "" }))
        req.setIsdebtor(undefined)
        dispatch(setAuthorizationFilterOption({ key: 0, text: "" }))
        req.setIsauthorizationrequired(undefined)
        dispatch(setAccountLinkingFilterOption({ key: 0, text: "" }))
        req.setLinkingstate(undefined)
        dispatch(setSelectedAutorizedClientItems([]))
        req.setAuthorizedclient(undefined)



    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }


        if (state.accountTypes.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.accountTypes.selected.at(0)?.id));
            req.setAccounttype(wrapper)
            isFiltered = true;
        } else {
            req.setAccounttype(undefined)
        }

        if (state.currencies.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.currencies.selected.at(0)?.id));
            req.setCurrency(wrapper)
            isFiltered = true;
        } else {
            req.setCurrency(undefined)
        }

        if (state.owners.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.owners.selected.at(0)?.internalId));
            req.setOwner(wrapper)
            isFiltered = true;
        } else {
            req.setOwner(undefined)
        }
        if (state.autorizedClient.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.autorizedClient.selected.at(0)?.internalId));
            req.setAuthorizedclient(wrapper)
            isFiltered = true;
        } else {
            req.setAuthorizedclient(undefined)
        }
        if (state.statusFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.statusFilterOption.key);
            req.setStatus(wrapper)
            isFiltered = true;

        } else {
            req.setStatus(undefined)
        }

        if (state.codeFilterOption && state.codeFilterOption.value.trim().length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue(state.codeFilterOption.value.trim());
            req.setCode(wrapper)
            isFiltered = true;

        } else {
            req.setCode(undefined)
        }

        if (state.isDebtorFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.isDebtorFilterOption.key == 1);
            req.setIsdebtor(wrapper)
            isFiltered = true;

        } else {
            req.setIsdebtor(undefined)
        }

        if (state.authorizationFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.authorizationFilterOption.key == 1);
            req.setIsauthorizationrequired(wrapper)
            isFiltered = true;

        } else {
            req.setIsauthorizationrequired(undefined)
        }
        if (state.accountLinkingFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.accountLinkingFilterOption.key);
            req.setLinkingstate(wrapper)
            isFiltered = true;

        } else {
            req.setLinkingstate(undefined)
        }
        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetAccountsRequest();
        accountTypesReq = new GetAccountTypesRequest();
        currenciesReq = new GetCurrenciesRequest();
        clintsReq = new GetUsersRequest();
        limitationReq = new GetLimitationsRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                accountTypesReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)
                limitationReq.setOrganization(wrapper)

            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)


        const boolv = new BoolValue();
        boolv.setValue(true);

        accountTypesReq.setNumofresults(state.accountTypes.numberOfResults)
        accountTypesReq.setOrder(state.accountTypes.isDescending)
        //accountTypesReq.setIsavailable(boolv)
        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
        // currenciesReq.setIsavailable(boolv)
        clintsReq.setNumofresults(state.owners.numberOfResults)
        clintsReq.setOrder(state.owners.isDescending)
        limitationReq.setNumofresults(state.limitations.numberOfResults)
        limitationReq.setOrder(state.limitations.isDescending)
        const type = new Int32Value();
        type.setValue(LimitationType.ACCOUNT_LIMITATION);
        limitationReq.setType(type)
        //  limitationReq.setIsavailable(boolv)
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])
    /*
    
          public long Id { get; set; }
                public string InternalId { get; set; }
                public string Name { get; set; }
                public string Code { get; set; }
                public decimal MirrorBalance { get; set; }
                public CurrencyResponse Currency { get; set; }
                public int Status { get; set; }
                public AccountTypeResponse AccountType { get; set; }
                public OwnerResponse Owner { get; set; }
                public ProfileResponse CreatedBy { get; set; }
                public DateTime CreatedDate { get; set; }
    */

    const c: IColumn[] = [
        {
            key: 'id',
            name: t("accountNumber"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    if (item?.isAuthorizationRequired) {
                        if (item?.isLinkingMetadataSet) {
                            return <Stack horizontal>
                                {item?.id + ' '}
                                <TooltipHost content={t("authorizationDesc")}>
                                    <Icon iconName="LockSolid" aria-label={t("authorizationDesc")} />
                                </TooltipHost>
                                <TooltipHost content={t("linkedAccountDesc")}>
                                    <Icon iconName={item?.isLinkingEnable ? "Link" : "RemoveLink"} aria-label={t("linkedAccountDesc")} />
                                </TooltipHost>
                            </Stack>;
                        }
                        return <div>
                            {item?.id + ' '}
                            <TooltipHost content={t("authorizationDesc")}>
                                <Icon iconName="LockSolid" aria-label={t("authorizationDesc")} />
                            </TooltipHost>
                        </div>;
                    } else {
                        if (item?.isLinkingMetadataSet) {
                            return <div>
                                {item?.id + ' '}
                                <TooltipHost content={t("linkedAccountDesc")}>
                                    <Icon iconName={item?.isLinkingEnable ? "Link" : "RemoveLink"} aria-label={t("linkedAccountDesc")} />
                                </TooltipHost>
                            </div>;
                        }
                        return <Text>{item?.id}</Text>;
                    }

            },
        },
        {
            key: 'name',
            name: t("accountName"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/accounts/" + item?.internalId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/accounts/" + item?.internalId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.name == "" ? item?.internalId : item?.name}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;


            },
        },
        {
            key: 'code',
            name: t("accountCode"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.code != undefined)
                    return <Text>{item?.code}</Text>;
            },
        },
        {
            key: 'mirrorBalance',
            name: t("totalBalance"),
            minWidth: 20,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.mirrorBalance != undefined)
                    return <Text>{currencyFormatter(item?.mirrorBalance, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'currencyName',
            name: t("currency"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/currencies/" + item?.currencyId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/currencies/" + item?.currencyId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.currencyName == "" ? item?.currencyId : item?.currencyName + " (" + item?.currencyCode + ")"}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },



        {
            key: 'status',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.status != undefined) {
                    if (item?.status == AccountStatus.ACTIVE)
                        return <Text>{t("active")}</Text>;
                    else if (item?.status == AccountStatus.INACTIVE)
                        return <Text>{t("inactive")}</Text>;
                    else if (item?.status == AccountStatus.CLOSED)
                        return <Text>{t("closed")}</Text>;
                }

            },
        },
        {
            key: 'accountTypeName',
            name: t("accountType"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/account-types/" + item?.accountTypeId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/account-types/" + item?.accountTypeId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined} > {item?.accountTypeName == "" ? item?.accountTypeId : item?.accountTypeName}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'ownerName',
            name: t("owner"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/customers/users/" + item?.ownerId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/users/" + item?.ownerId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}><Persona
                        text={item?.ownerName == "" ? item?.ownerId : item?.ownerName}
                        size={PersonaSize.size40}
                        imageUrl={CLIENT_PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + item?.ownerId + "/profile.jpg"}

                    /></Link>

            },
        },


        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 160,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'transaction',
            text: t("executeTransaction"),
            iconProps: { id: executeTransactionActionConfirmationcalloutTargetId, iconName: 'SetAction' },
            disabled: state.set.selected.at(0)?.status == AccountStatus.CLOSED,
            title: t("executeTransaction"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 3 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 3 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 3 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            subMenuProps: {
                items: [
                    {
                        key: 'transferMoney',
                        text: t("transferMoney"),
                        iconProps: { iconName: 'Switch' },
                        disabled: state.set.selected.at(0)?.status != AccountStatus.ACTIVE,
                        title: t("transferMoney"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(5)
                            }
                        }
                    },
                    {
                        key: 'externalTransferMoney',
                        text: t("externalTransferMoney"),
                        iconProps: { iconName: 'SwitcherStartEnd' },
                        disabled: state.set.selected.at(0)?.status != AccountStatus.ACTIVE,
                        title: t("externalTransferMoney"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(8)
                            }
                        }
                    },
                    {
                        key: 'deposit',
                        text: t("deposit"),
                        iconProps: { iconName: 'Installation' },
                        disabled: state.set.selected.at(0)?.status != AccountStatus.ACTIVE,
                        title: t("deposit"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(6)
                            }
                        }
                    },

                    {
                        key: 'withdraw',
                        text: t("withdraw"),
                        iconProps: { iconName: 'PublishContent' },
                        disabled: state.set.selected.at(0)?.status == AccountStatus.CLOSED,
                        title: t("withdraw"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(7)
                            }
                        }
                    },

                ]
            },
        },
        {
            key: 'edit',
            text: t("edit"),

            iconProps: { iconName: 'EditSolid12' },
            disabled: state.set.selected.at(0)?.status == AccountStatus.CLOSED,
            title: t("edit"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },
        {
            key: 'status',
            text: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == AccountStatus.ACTIVE) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == AccountStatus.ACTIVE) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == AccountStatus.ACTIVE) ? undefined : "bold" } } },
            disabled: state.set.selected.at(0)?.status == AccountStatus.CLOSED,
            title: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == AccountStatus.ACTIVE) ? t("deactivate") : t("activate"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'close',
            text: t("closeAccount"),
            iconProps: { id: closeAccountActionConfirmationcalloutTargetId, iconName: "Blocked", styles: { root: { fontWeight: "bold" } } },
            disabled: state.set.selected.at(0)?.status == AccountStatus.CLOSED,
            title: t("closeAccount"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },


    ];

    const _farselectionItemsEdit: ICommandBarItemProps[] = [
        {
            key: 'chart',
            text: t("chart"),
            iconProps: { iconName: "chart" },
            title: t("chart"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/accounts/chart?symbol=" + state.set.selected.at(0)?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'link',
            text: t("accountLinking"),
            iconProps: { id: removeLinkActionConfirmationcalloutTargetId, iconName: 'Link' },
            disabled: state.set.selected.at(0)?.status == AccountStatus.CLOSED,
            title: t("accountLinking"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 4 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 4 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 4 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (state.set.selected.at(0)?.isLinkingMetadataSet == false) {
                    if (!state.isChangeStateLoading) {
                        setCurrenctAction(state.set.selected.at(0)?.isLinkingMetadataSet ? 104 : 103)
                    }
                }
            },
            subMenuProps: {
                items: [
                    {
                        key: 'updateLink',
                        text: t("updateLinkStatus"),
                        iconProps: { iconName: 'EditSolid12' },
                        disabled: state.set.selected.at(0)?.status != AccountStatus.ACTIVE,
                        title: t("updateLinkStatus"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(state.set.selected.at(0)?.isLinkingMetadataSet ? 104 : 103)
                            }
                        }
                    },
                    {
                        key: 'removeLink',
                        text: t("removeLink"),
                        iconProps: { iconName: 'remove' },
                        disabled: state.set.selected.at(0)?.status != AccountStatus.ACTIVE,
                        title: t("removeLink"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(4)
                                toggleisActionConfirmationCalloutVisible()

                            }
                        }
                    },

                ]
            },


        },



    ];


    const _farselectionItemsNew: ICommandBarItemProps[] = [
        {
            key: 'chart',
            text: t("chart"),
            iconProps: { iconName: "chart" },
            title: t("chart"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/accounts/chart?symbol=" + state.set.selected.at(0)?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'link',
            text: t("accountLinking"),
            iconProps: { id: closeAccountActionConfirmationcalloutTargetId, iconName: 'Link' },
            disabled: state.set.selected.at(0)?.status == AccountStatus.CLOSED,
            title: t("accountLinking"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 4 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 4 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 4 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (state.set.selected.at(0)?.isLinkingMetadataSet == false) {
                    if (!state.isChangeStateLoading) {
                        setCurrenctAction(state.set.selected.at(0)?.isLinkingMetadataSet ? 104 : 103)
                    }
                }
            },



        },



    ];
    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('openAccount'),
            title: t('openAccount'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];



    const accountTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('accountTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accountTypes.hasMore && !state.accountTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accountTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                accountTypesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false,
        isSearching: state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false,


    };
    const onAccountTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetAccountTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        accountTypesReq.setSearch(wrapper)
        accountTypesReq.setNextto(undefined)
        dispatch(setIsFilteredAccountTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
        return [];

    }


    const ownersSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('clients'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.owners.hasMore && !state.owners.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.owners.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserItems([state.owners.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.owners.items.length > 0 && !state.owners.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.owners.items.at(state.owners.items.length - 1).internalId);
                clintsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.owners.items.length > 0 ? state.owners.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.owners.items.length == 0 ? state.owners.isFetching : false,
        isSearching: state.owners.items.length > 0 ? state.owners.isFetching : false,


    };


    const onOwnersFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetUsers())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredOwnersSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }


    const onAuthorizedClientFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAutorizedClients())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredAutorizedClientSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAutorizedClientUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }


    const currenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.currencies.hasMore && !state.currencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.currencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                currenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.currencies.items.length == 0 ? state.currencies.isFetching : false,
        isSearching: state.currencies.items.length > 0 ? state.currencies.isFetching : false,


    };
    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }

    const limitationsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('limitations'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.limitations.hasMore && !state.limitations.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.limitations.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                limitationReq.setNextto(wrapper)
                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
            }
        },
        suggestions: state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.limitations.items.length == 0 ? state.limitations.isFetching : false,
        isSearching: state.limitations.items.length > 0 ? state.limitations.isFetching : false,


    };
    const onLimitationsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetLimitations())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        limitationReq.setSearch(wrapper)
        limitationReq.setNextto(undefined)
        dispatch(setIsFilteredLimitationsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >
            <Popup isOpen={(currentAction == 100)} title={t("openAccount")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <AccountsForm childrenGap={10} maxWidth={320} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("editAccount")} onDismiss={() => { setCurrenctAction(0) }} >
                <AccountsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} fetchData={true} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 103 || currentAction == 104)} title={t("accountLinking")} onDismiss={() => { setCurrenctAction(0) }} >
                <LinkAccountForm childrenGap={10} maxWidth={280} type={currentAction == 104 ? FormType.EDIT : FormType.ADD} fetchData={false} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>


            <Popup isOpen={(currentAction == 1000)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1002)} title={t("accountTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountTypeItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1003)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 5)} title={t("transfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <TransferForm childrenGap={10} maxWidth={500} type={FormType.ADD} renderObject={{ from: state.set.selected.at(0) }} onSuccess={(e) => {
                    dispatch(withdrawAmount({ id: e.from, amount: Number(e.amount) }))
                    dispatch(addAmount({ id: e.to, amount: Number(e.amount) }))
                    //setCurrenctAction(0)
                    // getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 8)} title={t("externalTransfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <ExternalTransferForm childrenGap={10} maxWidth={500} type={FormType.ADD} renderObject={{ from: state.set.selected.at(0) }} onSuccess={(e) => {
                    dispatch(withdrawAmount({ id: e.from, amount: Number(e.amount) }))
                    dispatch(addAmount({ id: e.to, amount: Number(e.amount) }))
                    //setCurrenctAction(0)
                    // getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 6)} title={t("deposit")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <DepositForm childrenGap={10} maxWidth={500} type={FormType.ADD} renderObject={{ account: state.set.selected.at(0) }} onSuccess={(e) => {
                    dispatch(addAmount({ id: e.account, amount: Number(e.amount) }))

                    //  setCurrenctAction(0)
                    //  getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 7)} title={t("withdraw")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <WithdrawForm childrenGap={10} maxWidth={500} type={FormType.ADD} renderObject={{ account: state.set.selected.at(0) }} onSuccess={(e) => {
                    dispatch(withdrawAmount({ id: e.account, amount: Number(e.amount) }))
                    // setCurrenctAction(0)
                    // getBalancePromise = dispatch(getBalance({ body: balanceReq, headers: getHeaders() }))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 1005)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAutorizedClientItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={state.set.selected.length == 0 ? _farOptions : state.set.selected.at(0)?.isLinkingMetadataSet == false ? _farselectionItemsNew : _farselectionItemsEdit}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareAccount")} value={`https://${window.location.host}/accounts/${state.set.selected.at(0).internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("closeAccount") : currentAction == 2 ? (state.set.selected.at(0)?.status == 1) ? t("deactivate") : t("activate") : currentAction == 4 ? t("removeLink") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("closeAccountDesc") : currentAction == 2 ? (state.set.selected.at(0)?.status == 1) ? t("deactivateFinancialAccountDesc") : t("activateFinancialAccountDesc") : currentAction == 4 ? t("removeLinkDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new CloseAccountRequest();
                                            req.setId(state.set.selected.at(0)?.internalId)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(closeAccount({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        } else if (currentAction == 2) {
                                            let req = new UpdateAccountStatusRequest();
                                            req.setId(state.set.selected.at(0)?.internalId)
                                            req.setStatus(state.set.selected.at(0)?.status == 1 ? 2 : 1)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateStatus({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })

                                        } else if (currentAction == 4) {
                                            let req = new RevokeLinkRequest();
                                            req.setId(state.set.selected.at(0)?.internalId)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(removeLink({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("close") : currentAction == 2 ? (state.set.selected.at(0)?.status == 1) ? t("deactivate") : t("activate") : currentAction == 4 ? t("removeLink") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label  >{t("accountType")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("accountType") }}
                                suggestionsHeaderText={t('accountTypes')}
                                isLoading={state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false}
                                isSearching={state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false}
                                moreSuggestionsAvailable={state.accountTypes.hasMore && !state.accountTypes.isFetching}
                                suggestions={state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {
                                    if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                                        accountTypesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.accountTypes.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={false}
                                selectedItems={state.accountTypes.selected.length > 0 ? state.accountTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedAccountTypeItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetAccountTypes())
                                    accountTypesReq.setSearch(undefined)
                                    accountTypesReq.setNextto(undefined)
                                    dispatch(setIsFilteredAccountTypesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onAccountTypesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1002)

                                        }
                                    }]}
                            />


                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label >{t("currency")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("currency") }}
                                suggestionsHeaderText={t('currencies')}
                                isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}                                onGetMoreResults={() => {
                                    if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                        currenciesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.currencies.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={true}
                                selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedCurrencyItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetCurrencies())
                                    currenciesReq.setSearch(undefined)
                                    currenciesReq.setNextto(undefined)
                                    dispatch(setIsFilteredCurrenciesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onCurrenciesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1003)

                                        }
                                    }]}
                            />

                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label >{t("owner")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('clients')}
                                isLoading={state.owners.items.length == 0 ? state.owners.isFetching : false}
                                isSearching={state.owners.items.length > 0 ? state.owners.isFetching : false}
                                moreSuggestionsAvailable={state.owners.hasMore && !state.owners.isFetching}
                                suggestions={state.owners.items.length > 0 ? state.owners.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.owners.items.length > 0 && !state.owners.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.owners.items.at(state.owners.items.length - 1).internalId);
                                        clintsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.owners.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedUserItems([state.owners.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("owner") }}

                                selectedItems={state.owners.selected.length > 0 ? state.owners.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username,   imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedUserItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetUsers())
                                    clintsReq.setSearch(undefined)
                                    clintsReq.setNextto(undefined)
                                    dispatch(setIsFilteredOwnersSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    return []

                                }}
                                onFilterChanged={onOwnersFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)

                                        }
                                    }]}
                            />
                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label >{t("authorizedClient")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('clients')}
                                isLoading={state.autorizedClient.items.length == 0 ? state.autorizedClient.isFetching : false}
                                isSearching={state.autorizedClient.items.length > 0 ? state.autorizedClient.isFetching : false}
                                moreSuggestionsAvailable={state.autorizedClient.hasMore && !state.autorizedClient.isFetching}
                                suggestions={state.autorizedClient.items.length > 0 ? state.autorizedClient.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.autorizedClient.items.length > 0 && !state.autorizedClient.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.autorizedClient.items.at(state.autorizedClient.items.length - 1).internalId);
                                        clintsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getAutorizedClientUsers({ body: clintsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.autorizedClient.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedAutorizedClientItems([state.autorizedClient.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("authorizedClient") }}

                                selectedItems={state.autorizedClient.selected.length > 0 ? state.autorizedClient.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username , imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedAutorizedClientItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetAutorizedClients())
                                    clintsReq.setSearch(undefined)
                                    clintsReq.setNextto(undefined)
                                    dispatch(setIsFilteredAutorizedClientSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getAutorizedClientUsers({ body: clintsReq, headers: getHeaders() }))
                                    return []

                                }}
                                onFilterChanged={onAuthorizedClientFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1005)

                                        }
                                    }]}
                            />
                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("party")}</Label>
                            <Dropdown
                                selectedKey={state.isDebtorFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("debit") }, { key: 2, text: t("credit") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {

                                    dispatch(setIsDebtorFilterOption({ key: Number(item?.key), text: t("party") + ":" + item?.text }))

                                })}

                            />
                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("status")}</Label>
                            <Dropdown
                                selectedKey={state.statusFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }, { key: 3, text: t("closed") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setStatusFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text}))

                                })}

                            />
                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("authorization")}</Label>
                            <Dropdown
                                selectedKey={state.authorizationFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setAuthorizationFilterOption({ key: Number(item?.key), text: t("authorization") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("accountLinking")}</Label>
                            <Dropdown
                                selectedKey={state.accountLinkingFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("enabled") }, { key: 2, text: t("disabled") }, { key: 3, text: t("unspecified") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setAccountLinkingFilterOption({ key: Number(item?.key), text: t("accountLinking") + ":" + item?.text }))

                                })}

                            />
                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("accountCode")}</Label>
                            <TextField onChange={(e: any) => {
                                if (e)
                                    dispatch(setCodeFilterOption({ value: e?.target?.value, text: t("accountCode") + ":" + e?.target?.value }))

                            }} placeholder="ACC-01" maxLength={20} value={state.codeFilterOption.value} styles={{ root: { width: 300 } }} />

                        </Stack>

                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null
            }

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {




                                if (e.key == 1) {
                                    dispatch(setSelectedAccountTypeItems([]))
                                    req.setAccounttype(undefined)
                                }
                                if (e.key == 2) {
                                    dispatch(setSelectedCurrencyItems([]))
                                    req.setCurrency(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setSelectedUserItems([]))
                                    req.setOwner(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setSelectedAutorizedClientItems([]))
                                    req.setAuthorizedclient(undefined)
                                }
                                if (e.key == 5) {
                                    dispatch(setCodeFilterOption({ value: "", text: "" }))
                                    req.setCode(undefined)
                                }
                                if (e.key == 6) {
                                    dispatch(setStatusFilterOption({ key: 0, text: "" }))
                                    req.setStatus(undefined)
                                }
                                if (e.key == 7) {
                                    dispatch(setIsDebtorFilterOption({ key: 0, text: "" }))
                                    req.setIsdebtor(undefined)
                                }
                                if (e.key == 8) {
                                    dispatch(setAuthorizationFilterOption({ key: 0, text: "" }))
                                    req.setIsauthorizationrequired(undefined)
                                }
                                if (e.key == 9) {
                                    dispatch(setAccountLinkingFilterOption({ key: 0, text: "" }))
                                    req.setLinkingstate(undefined)
                                }
                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="accounts"
                items={state.set.items}
                columns={c}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"} selection={_selection}
                selectionMode={SelectionMode.single}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}

                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.internalId);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}

                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.internalId);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {
                props.isSelection ? <DialogFooter>
                    <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                        if (props.onItemSelected && state.set.selected.length > 0) {
                            props.onItemSelected(state.set.selected.at(0))
                        }
                    }} >

                        <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                    </PrimaryButton>
                    <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                        if (props.onCancel) {
                            props.onCancel()
                        }
                    }} />
                </DialogFooter> : undefined
            }
        </Stack >



    );
}




