import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL, APIResponse } from '../../../../../../app/Api';
import { base64ToArrayBuffer, downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../../../app/Helpers';
import { DynamicField } from '../../../../../common/DynamicFields/DynamicFields';
import { FeeEndpointClient } from '../../../../../../repository/Accountant/FeeServiceClientPb';
import { AddFeeCollectionAccountRequest, AddFeeCollectionAccountResponse, AddFeeRequest, AddFeeResponse, GetFeeRequest, GetFeeResponse, UpdateFeeRequest, UpdateFeeResponse } from '../../../../../../repository/Accountant/fee_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../../../repository/Accountant/account_pb';
import { AccountEndpointClient } from '../../../../../../repository/Accountant/AccountServiceClientPb';
import { TableState } from '../../../../../common/Table/TableSate';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../../../repository/Accountant/CurrencyServiceClientPb';
import {  ExportStatementRequest, ExportStatementResponse } from '../../../../../../repository/Accountant/treasury_operation_pb';
import { AccountOperationEndpointClient } from '../../../../../../repository/Accountant/Account_operationServiceClientPb';
import { TreasuryEndpointClient } from '../../../../../../repository/Accountant/TreasuryServiceClientPb';
import { GetTreasuriesRequest, GetTreasuriesResponse } from '../../../../../../repository/Accountant/treasury_pb';
import { TreasuryOperationEndpointClient } from '../../../../../../repository/Accountant/Treasury_operationServiceClientPb';


export interface TreasuryStatementFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    treasuries: TableState,
    currencies: TableState,
    commonWithAccount: TableState,

}

const initialState: TreasuryStatementFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    treasuries: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    commonWithAccount: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new TreasuryOperationEndpointClient(ACCOUNTANT_API_URL, null, null);
const treasuryClient = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const currencyClient = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);


export const getTreasuries = createAsyncThunk<GetTreasuriesResponse.AsObject, APIRequest<GetTreasuriesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/trasuryStatement/treasuries/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await treasuryClient.getTreasuries(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)
 
export const getCommonWithAccount = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/trasuryStatement/treasuries/fetchCommonWithAccount',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)





export const add = createAsyncThunk<APIResponse<ExportStatementResponse.AsObject>, APIRequest<ExportStatementRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/treasuryStatement/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.exportStatement(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { exportType: req.body.getExporttype() } };

            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/trasuryStatement/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await currencyClient.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const treasuryStatementFormSlice = createSlice({
    name: 'forms/accountant/operations/treasuryStatement',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.treasuries = initialState.treasuries;
            state.currencies = initialState.currencies;
            state.commonWithAccount = initialState.commonWithAccount;

        },
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },


        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;

        },

        resetCommonWithAccountsSet: (state) => {
            state.commonWithAccount.items = [];
            state.commonWithAccount.hasMore = true;
        },

        setSelectedCommonWithAccountAccountItems: (state, action: PayloadAction<any[]>) => {
            state.commonWithAccount.selected = action.payload;
        },
        setIsFilteredCommonWithAccountAccountSet: (state, action: PayloadAction<boolean>) => {
            state.commonWithAccount.isFilteredSet = action.payload;
        },


  setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },


        resetTreasuriesSet: (state) => {
            state.treasuries.items = [];
            state.treasuries.hasMore = true;
        },

   

        setIsFilteredTreasurySet: (state, action: PayloadAction<boolean>) => {
            state.treasuries.isFilteredSet = action.payload;
        },

        setSelectedTreasuryItems: (state, action: PayloadAction<any[]>) => {
            state.treasuries.selected = action.payload;
        },


        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
           
            }
            state.stage1 = stage1;
        },

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
        resetMessage: (state) => {
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload.response.success?.statement) {
                downloadFile(payload.response.success?.statement+ "", payload.response.success.name?.value ?? "AcountStatement" + getFileExtension(payload.metadata?.exportType))
            }

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getCommonWithAccount.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.commonWithAccount.numberOfResults) {
                    state.commonWithAccount.hasMore = false;
                }
                state.commonWithAccount.items = state.commonWithAccount.items.concat(r);
            } else {
                state.commonWithAccount.hasMore = false;

            }
            state.commonWithAccount.isFetching = false

        })
        builder.addCase(getCommonWithAccount.rejected, (state, action) => {
            state.commonWithAccount.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCommonWithAccount.pending, (state, action) => {
            state.commonWithAccount.isFetching = true;
            // state.message = undefined;
        })
        builder.addCase(getTreasuries.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasuriesList.map(val => {
                  
                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        nature: val.nature?.id?.value ?? undefined,
                        treasuryTypeId: val.treasurytype?.id?.value ?? "",
                        treasuryTypeName: val.treasurytype?.name?.value ?? "",
                        status: val.status?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.treasuries.numberOfResults) {
                    state.treasuries.hasMore = false;
                }
                state.treasuries.items = state.treasuries.items.concat(r);
            } else {
                state.treasuries.hasMore = false;

            }
            state.treasuries.isFetching = false

        })
        builder.addCase(getTreasuries.rejected, (state, action) => {
            state.treasuries.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuries.pending, (state, action) => {
            state.treasuries.isFetching = true;
          //  state.message = undefined;
        })

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })


    }
})

export const { reset, dismissMessage, resetMessage, setStage1, setIsFilteredCommonWithAccountAccountSet, resetCommonWithAccountsSet, setSelectedCommonWithAccountAccountItems, setMessage, resetCurrencies, resetTreasuriesSet, setIsFilteredCurrenciesSet, setIsFilteredTreasurySet, setSelectedCurrencyItems, setSelectedTreasuryItems } = treasuryStatementFormSlice.actions

export default treasuryStatementFormSlice.reducer


