import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { add, dismissMessage, getFromAccounts, setIsAuthorizationRequired, getToAccounts, reset, resetFromAccountsSet, resetToAccountsSet, setIsFilteredToAccountSet, setMessage, setIsFilteredFromAccountSet, setSelectedFromAccountItems, setSelectedToAccountItems, setStage1, resetMessage, getTransactionSummary, } from "./ExternalTransfareFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatDate, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../repository/Accountant/common_pb";
import { TableState } from "../../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../../repository/Accountant/account_pb";
import { AccountStatus, AuthorizedOperation, Operations } from "../../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../../repository/Accountant/currency_pb";
import { ExternalTransferRequest, GetTransactionSummary, GetTransactionSummaryRequest, TransferRequest, ExternalTransferResponse } from "../../../../../repository/Accountant/account_operation_pb";
import { List } from "../../../../common/List/List";
import { Popup } from "../../../../common/Popup/Popup";
import { AccountsPage } from "../../../../pages/Accountant/Accounts/AccountsPage";
import { AuthorizationForm } from "../../Accounts/AuthorizationForm";


let req: ExternalTransferRequest;
let getFromAccountsReq: GetAccountsRequest;
let getToAccountsReq: GetAccountsRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;
let currenciesReq: GetCurrenciesRequest;

export const ExternalTransferForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState({
        type: DialogType.normal,
        title: '',
        subText: '',
    });
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const modalPropsStyles = { main: { maxWidth: 450 } };

    const [currentAction, setCurrenctAction] = useState(0)

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [isDraggable],
    );

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, from: TableState, to: TableState, confirmation: any[] , requireAuth: boolean} = useAppSelector((state) => {

        return {
            isLoading: state.externalTransferForm.isLoading, message: state.externalTransferForm.message,
            stage1: state.externalTransferForm.stage1,
            from: state.externalTransferForm.from,
            to: state.externalTransferForm.to,
            confirmation: state.externalTransferForm.confirmation,
            requireAuth: state.externalTransferForm.requireAuth


        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new ExternalTransferRequest();
        getFromAccountsReq = new GetAccountsRequest();
        getToAccountsReq = new GetAccountsRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                getFromAccountsReq.setOrganization(wrapper)
                getToAccountsReq.setOrganization(wrapper)
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);
        const stat2 = new Int32Value();
        stat2.setValue(1);

        getFromAccountsReq.setNumofresults(state.from.numberOfResults)
        getFromAccountsReq.setOrder(state.from.isDescending)
        getFromAccountsReq.setStatus(stat)

        getToAccountsReq.setNumofresults(state.to.numberOfResults)
        getToAccountsReq.setOrder(state.to.isDescending)
        getToAccountsReq.setStatus(stat)
        getToAccountsReq.setLinkingstate(stat2)
        if (props.renderObject && props.renderObject?.from) {

            dispatch(setSelectedFromAccountItems(([props.renderObject?.from])))

            const c = new StringValue();
            c.setValue(props.renderObject?.from?.currencyId)
            getToAccountsReq.setCurrency(c)
        }

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])

    useEffect(() => {
        if (state.confirmation.length > 0) {

            if (state.confirmation.at(0).debtorfeeValue != undefined && state.confirmation.at(0).debtorfeeValue != 0 && state.confirmation.at(0).creditorfeeValue != undefined && state.confirmation.at(0).creditorfeeValue != 0) {
                const formatteddebtFeeCurrency = currencyFormatter(Number(state.confirmation.at(0).debtorfeeValue), { significantDigits: state.confirmation.at(0).currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                const formattedcreditFeeCurrency = currencyFormatter(Number(state.confirmation.at(0).creditorfeeValue), { significantDigits: state.confirmation.at(0).currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                const formattedCurrency = currencyFormatter(Number(state.confirmation.at(0).amount), { significantDigits: state.confirmation.at(0)?.currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })

                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    subText: t("externalTransferConfirmWithFee", {
                        org: state.confirmation.at(0).org, creditorFeeAmount: formattedcreditFeeCurrency, debtorFeeAmount: formatteddebtFeeCurrency, amount: formattedCurrency, from: state.from.selected.at(0).name ?? "",
                        to: state.confirmation.at(0).to
                    }),
                })

            } else if (state.confirmation.at(0).debtorfeeValue != undefined && state.confirmation.at(0).debtorfeeValue != 0) {
                const formatteddebtFeeCurrency = currencyFormatter(Number(state.confirmation.at(0).debtorfeeValue), { significantDigits: state.confirmation.at(0).currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                const formattedCurrency = currencyFormatter(Number(state.confirmation.at(0).amount), { significantDigits: state.confirmation.at(0)?.currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    subText: t("externalTransferConfirmWithDebitFee", { org: state.confirmation.at(0).org, debtorFeeAmount: formatteddebtFeeCurrency, amount: formattedCurrency, from: state.from.selected.at(0).name ?? "", to: state.confirmation.at(0).to }),
                })
            } else if (state.confirmation.at(0).creditorfeeValue != undefined && state.confirmation.at(0).creditorfeeValue != 0) {
                const formattedcreditFeeCurrency = currencyFormatter(Number(state.confirmation.at(0).creditorfeeValue), { significantDigits: state.confirmation.at(0).currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                const formattedCurrency = currencyFormatter(Number(state.confirmation.at(0).amount), { significantDigits: state.confirmation.at(0)?.currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    subText: t("externalTransferConfirmWithCreditFee", { org: state.confirmation.at(0).org, creditorFeeAmount: formattedcreditFeeCurrency, amount: formattedCurrency, from: state.from.selected.at(0).name ?? "", to: state.confirmation.at(0).to }),
                })
            } else {
                const formattedCurrency = currencyFormatter(Number(state.confirmation.at(0).amount), { significantDigits: state.confirmation.at(0)?.currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    subText: t("externalTransferConfirm", { org: state.confirmation.at(0).org, amount: formattedCurrency, from: state.from.selected.at(0).name ?? "", to: state.confirmation.at(0).to }),
                })
            }
            toggleHideDialog()
        }
    }, [state.confirmation])

    const toSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.to.hasMore && !state.to.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.to.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedToAccountItems([state.to.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.to.items.length > 0 && !state.to.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.to.items.at(state.to.items.length - 1).internalId);
                getToAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.to.items.length > 0 ? state.to.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.to.items.length == 0 ? state.to.isFetching : false,
        isSearching: state.to.items.length > 0 ? state.to.isFetching : false,


    };


    const onToAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetToAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getToAccountsReq.setSearch(wrapper)
        getToAccountsReq.setNextto(undefined)
        if (state.from.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.from.selected.at(0).currencyId);
            getToAccountsReq.setCurrency(r);
        }
        dispatch(setIsFilteredToAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
        return [];


    }




    const fromSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.from.hasMore && !state.from.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.from.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedFromAccountItems([state.from.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.from.items.length > 0 && !state.from.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.from.items.at(state.from.items.length - 1).internalId);
                getFromAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.from.items.length > 0 ? state.from.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.from.items.length == 0 ? state.from.isFetching : false,
        isSearching: state.from.items.length > 0 ? state.from.isFetching : false,


    };


    const onFromAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetFromAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getFromAccountsReq.setSearch(wrapper)
        getFromAccountsReq.setNextto(undefined)
        if (state.to.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.to.selected.at(0).currencyId);
            getFromAccountsReq.setCurrency(r);
        }
        dispatch(setIsFilteredFromAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
        return [];


    }





    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    if (state.to.selected.length > 0) {
                        if (state.to.selected.at(0).currencyId != e.currencyId) {
                            setCurrenctAction(0)
                            return;
                        }
                    }
                    dispatch(setSelectedFromAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    if (state.from.selected.length > 0) {
                        if (state.from.selected.at(0).currencyId != e.currencyId) {
                            setCurrenctAction(0)
                            return;
                        }
                    }
                    dispatch(setSelectedToAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Formik
                enableReinitialize={true}
                initialValues={{

                    from: undefined,
                    to: undefined,
                    endAccount: undefined,
                    amount: "",
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],
                    message: "",
                    advancedOptions: false,
                    description: ""


                }}

                validationSchema={Yup.object({
                    amount: inputs.amount,
                    extraFields: inputs.extraFields,
                    message: inputs.message,
                    endAccount: inputs.accountNumber,
                    description: inputs.message,
                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let from = state.from.selected.at(0);
                        let to = state.to.selected.at(0);
                        if (from.internalId == to.internalId) {
                            dispatch(setSelectedToAccountItems([]))
                            return;
                        }
                        let amount = "";
                        let formattedCurrency = "";
                        if (values.amount) {
                            let r = Number(Number(values.amount?.replace(/,/g, '')).toFixed(to?.currencyDecimalPlaces ?? 9))
                            if (r <= 0) {
                                actions.setFieldValue("amount", "")
                                return;
                            }
                            formattedCurrency = currencyFormatter(r, { significantDigits: to?.currencyDecimalPlaces ?? 9, symbol: to?.currencySymbol })
                            amount = r + ""
                        }
                        req.setCreditor(to.internalId);
                        req.setDebtor(from.internalId)
                        req.setAmount(amount)
                        req.setEndcreditor((values.endAccount) + "")

                        const wrapperdesc = new StringValue();
                        wrapperdesc.setValue(values?.description);
                        req.setDescription(wrapperdesc)

                        const wrapper = new StringValue();
                        wrapper.setValue(values.advancedOptions ? values.message : "");
                        req.setMessage(wrapper)
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: ExternalTransferResponse.AsObject) => {
                                dispatch(setMessage({ body: t("transferSuccessDesc", { amount: formattedCurrency, account: to?.name ?? "", accountId: to?.internalId ?? "" }), type: 1, title: t("transferSuccessTitle", { transaction: res?.success?.transactionid ?? "", transactionId: res?.success?.transactioninternalid ?? "" }), data: undefined }))
                                dispatch(setSelectedToAccountItems([]))
                                actions.resetForm();
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res?.success?.transactionid,
                                            internalId: res?.success?.transactioninternalid,
                                            amount: amount,
                                            from: from.internalId,
                                            to: to.internalId,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            extraFields: values.extraFields

                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {

                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}

            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()

                        let a = Number(formkikProps.values.amount?.replace(/,/g, ''))
                        if (isNaN(a) || !formkikProps.isValid || a == 0) {
                            return;
                        }
                        dispatch(resetMessage())
                        if (state.from.selected.at(0).internalId == state.to.selected.at(0).internalId) {
                            dispatch(setSelectedToAccountItems([]))
                            return;
                        }

                        let from = state.from.selected.at(0);
                        let to = state.to.selected.at(0);

                        req.setAuthcode(undefined);
                        if (from.isAuthorizationRequired || state.requireAuth) {
                            setCurrenctAction(13);
                            return;
                        }



                        var sumary = new GetTransactionSummaryRequest();
                        const set: GetTransactionSummary[] = [];
                        const trans = new GetTransactionSummary();
                        trans.setAmount(formkikProps.values.amount?.replace(/,/g, ''))
                        trans.setCreditor(to.internalId)
                        trans.setDebtor(from.internalId)
                        trans.setOperation(Operations.EXTERNAL_TRANSFER)
                        set.push(trans)
                        sumary.setTransactionsList(set)
                        promise?.abort();
                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders(), metadata: { to: formkikProps.values.endAccount + "", org: state.to.selected.at(0)?.name + " (#" + state.to.selected.at(0)?.id + ")" } }))

                    }}>
                        <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                            <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                                account: state.from.selected.at(0),
                                operation: AuthorizedOperation.EXTERNAL_TRANSFER,
                                parameters: [{ key: "Amount", value: formkikProps.values.amount?.replace(/,/g, ''), viewValue: state.from.selected.at(0)?.currencySymbol }
                                    , { key: "To", value: state.to.selected.at(0)?.id + "", viewValue: state.to.selected.at(0)?.name + " (#" + state.to.selected.at(0)?.id + ")" },
                                { key: "ExternalTo", value: formkikProps.values.endAccount + "", viewValue: formkikProps.values.endAccount + "" },
                                ],
                            }} onSuccess={(e) => {
                                if (req && e.authCode) {
                                    if (currentAction == 13) {
                                        var r = new StringValue();
                                        r.setValue(e.authCode)
                                        req.setAuthcode(r)
                                        setCurrenctAction(0)

                                        let from = state.from.selected.at(0);
                                        let to = state.to.selected.at(0);

                                        var sumary = new GetTransactionSummaryRequest();
                                        const set: GetTransactionSummary[] = [];
                                        const trans = new GetTransactionSummary();
                                        trans.setAmount(formkikProps.values.amount?.replace(/,/g, ''))
                                        trans.setCreditor(to.internalId)
                                        trans.setDebtor(from.internalId)
                                        trans.setOperation(Operations.EXTERNAL_TRANSFER)
                                        set.push(trans)
                                        sumary.setTransactionsList(set)
                                        promise?.abort();
                                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders(), metadata: { to: formkikProps.values.endAccount + "", org: state.to.selected.at(0)?.name + " (#" + state.to.selected.at(0)?.id + ")" } }))

                                    }
                                } else {
                                    setCurrenctAction(0)
                                }
                            }} onCancel={() => { setCurrenctAction(0) }} />
                        </Popup>

                        <Dialog
                            hidden={hideDialog}
                            onDismiss={toggleHideDialog}
                            dialogContentProps={showConfirmation}
                            modalProps={modalProps}
                        >
                            <DialogFooter>
                                <PrimaryButton autoFocus onClick={(e: any) => { formkikProps.handleSubmit(e); toggleHideDialog() }} text={t("transfer")} type="submit" />
                                <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
                            </DialogFooter>
                        </Dialog>
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("from")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.from.items.length == 0 ? state.from.isFetching : false}
                                    isSearching={state.from.items.length > 0 ? state.from.isFetching : false}
                                    moreSuggestionsAvailable={state.from.hasMore && !state.from.isFetching}
                                    suggestions={state.from.items.length > 0 ? state.from.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.from.items.length > 0 && !state.from.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.from.items.at(state.from.items.length - 1).internalId);
                                            getFromAccountsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.from.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedFromAccountItems([state.from.items.at(u)]))
                                        }
                                    }}
                                    inputProps={{ placeholder: t("from"), required: true }}

                                    disabled={state.isLoading || props.renderObject?.from ? true : false}
                                    selectedItems={state.from.selected.length > 0 ? state.from.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedFromAccountItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetFromAccountsSet())
                                        getFromAccountsReq.setSearch(undefined)
                                        getFromAccountsReq.setNextto(undefined)
                                        if (state.to.selected.length > 0) {
                                            let r = new StringValue();
                                            r.setValue(state.to.selected.at(0).currencyId);
                                            getFromAccountsReq.setCurrency(r);
                                        }
                                        dispatch(setIsFilteredFromAccountSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onFromAccountsFilterChanged}
                                    endButtons={state.from.selected?.at(0)?.isAuthorizationRequired ? [
                                        {
                                            title: t("more"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100);
                                            }
                                        }
                                    ] : [
                                        {
                                            title: t("more"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100);
                                            }
                                        },
                                        {
                                            title: state.requireAuth ? t("operationAuthorizationEnabled") : t("operationAuthorizationDisabled"), checked: state.requireAuth, disabled: false, iconProps: { iconName: !state.requireAuth ? "Unlock" : "Lock" },
                                            onClick: () => {
                                                dispatch(setIsAuthorizationRequired(!state.requireAuth))
                                            }
                                        }
                                    ]}
                                />

                            </Stack> : undefined}

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("to")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.to.items.length == 0 ? state.to.isFetching : false}
                                    isSearching={state.to.items.length > 0 ? state.to.isFetching : false}
                                    moreSuggestionsAvailable={state.to.hasMore && !state.to.isFetching}
                                    suggestions={state.to.items.length > 0 ? state.to.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.to.items.length > 0 && !state.to.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.to.items.at(state.to.items.length - 1).internalId);
                                            getToAccountsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.to.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedToAccountItems([state.to.items.at(u)]))
                                        }
                                    }}
                                    inputProps={{ placeholder: t("to"), required: true }}

                                    disabled={state.isLoading}
                                    selectedItems={state.to.selected.length > 0 ? state.to.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedToAccountItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetToAccountsSet())
                                        getToAccountsReq.setSearch(undefined)
                                        getToAccountsReq.setNextto(undefined)
                                        if (state.from.selected.length > 0) {
                                            let r = new StringValue();
                                            r.setValue(state.from.selected.at(0).currencyId);
                                            getToAccountsReq.setCurrency(r);
                                        }
                                        dispatch(setIsFilteredToAccountSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onToAccountsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}

                            {props.type == FormType.ADD ?
                                <Stack>
                                    <Label required disabled={state.isLoading}>{t("endAccount")} </Label>
                                    <Field name="endAccount" placeholder={t("endAccount")}
                                        disabled={state.isLoading} maxLength={19}
                                        component={InputField}
                                    />
                                </Stack> : undefined}
                            {props.type == FormType.ADD ?

                                <Field name="amount" label={t("amount")} placeholder={t("amount")}
                                    component={InputField} disabled={state.isLoading} maxLength={15}
                                    onBlur={(event: any) => {
                                        if (isNaN(Number(formkikProps.values.amount?.replace(/,/g, '')))) {
                                            formkikProps.handleBlur(event);
                                            return;
                                        }
                                        let from = state.from.selected.at(0);
                                        let to = state.to.selected.at(0);
                                        if (from)
                                            formkikProps.setFieldValue("amount", Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(from?.currencyDecimalPlaces ?? 9))
                                        else if (to)
                                            formkikProps.setFieldValue("amount", Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(to?.currencyDecimalPlaces ?? 9))
                                        else
                                            formkikProps.setFieldValue("amount", Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(9))
                                        formkikProps.handleBlur(event);
                                    }}
                                    required suffix={state.from?.selected?.at(0)?.currencyCode ?? state.to?.selected?.at(0)?.currencyCode} />


                                : undefined}




                            <Field name="description" label={
                                <div>
                                    {t("description") + ' '}
                                    <TooltipHost content={t("descriptionDesc")}>
                                        <Icon iconName="Info" aria-label={t("description")} />
                                    </TooltipHost>
                                </div>
                            } placeholder={t("description")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={140} />


                            <Toggle

                                label={t("advancedOptions")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.advancedOptions}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('advancedOptions', checked);
                                }
                                }
                            />

                            {formkikProps.values.advancedOptions ?
                                <Field name="message" label={
                                    <div>
                                        {t("message") + ' '}
                                        <TooltipHost content={t("messageDesc")}>
                                            <Icon iconName="Info" aria-label={t("message")} />
                                        </TooltipHost>
                                    </div>
                                } placeholder={t("message")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={140} />
                                : undefined}

                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton type="submit" disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("transfer") : t("edit")}
                            >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

