import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../app/Api';
import { base64ToArrayBuffer, downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../app/Helpers';

import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { TreasuryEndpointClient } from '../../../../repository/Accountant/TreasuryServiceClientPb';
import { ReportEndpointClient } from '../../../../repository/Accountant/ReportServiceClientPb';
import { GetAccountBalanceReportRequest, GetAccountBalanceReportResponse, ExportAccountBalanceReportRequest, ExportAccountBalanceReportResponse, GetTransactionTotalsReportResponse, GetTransactionTotalsReportRequest, ExportTransactionTotalsReportResponse, ExportTransactionTotalsReportRequest } from '../../../../repository/Accountant/report_pb';
import { GetTreasuriesRequest, GetTreasuriesResponse } from '../../../../repository/Accountant/treasury_pb';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../repository/Accountant/account_pb';


export interface TransactionTotalsReportPageState {
    set: TableState
    totals: any
    message?: ApiMessage,
    accounts: TableState
    currencies: TableState
    isChangeStateLoading: boolean,
    operations: { key: number, text: string },

}

const initialState: TransactionTotalsReportPageState = {

    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    totals: undefined,
    message: undefined,
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    operations: { key: 0, text: "" },

}
const client = new ReportEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccount = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<APIResponse<GetTransactionTotalsReportResponse.AsObject>, APIRequest<GetTransactionTotalsReportRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/transactionTotalsReport/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTransactionTotalsReport(req.body, req.headers ?? {});
                return { metadata: { grouping: req.body.getGroupby() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/transactionTotalsReport/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientAccount.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/reports/transactionTotalsReport/currency/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const exportReport = createAsyncThunk<APIResponse<ExportTransactionTotalsReportResponse.AsObject>, APIRequest<ExportTransactionTotalsReportRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/reports/transactionTotalsReport/export',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.exportTransactionTotalsReport(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { exportType: req.body.getExporttype() } };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const transactionTotalsReportPageSlice = createSlice({
    name: 'pages/reports/transactionTotalsReport',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.accounts = initialState.accounts;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.totals = initialState.totals;
            state.currencies = initialState.currencies;
            state.operations = initialState.operations;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },

        setSelectedAccountItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
        },

        setIsFilteredAccountSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },

        resetCurrenciesSet: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },

        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;
        },

        setIsFilteredCurrencySet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },
        setOperation: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.operations = action.payload;

        },
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })
        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {
                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value,

                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.response?.success?.reportList.map(val => {
                    let r = {
                        date: formatDate(timestampToDate(val.date?.seconds, val.date?.nanos, payload?.metadata?.grouping == 3), payload?.metadata?.grouping == 0 ? 'YYYY-MM-DD' : payload?.metadata?.grouping == 1 ? 'YYYY-MM' : payload?.metadata?.grouping == 2 ? 'YYYY' : payload?.metadata?.grouping == 3 ? "YYYY-MM-DD HH:00:00" : 'YYYY-MM-DD'),
                        currency: val.currency?.value,
                        currencyId: val.currencymetadata?.id?.value ?? "",
                        currencyName: val.currencymetadata?.name?.value ?? "",
                        currencyCode: val.currencymetadata?.code?.value ?? "",
                        currencyDecimalPlaces: val.currencymetadata?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currencymetadata?.form?.value ?? undefined,
                        currencySymbol: val.currencymetadata?.symbol?.value ?? "",
                        operation: val.operation?.value,
                        totalAmount: val.totalamount?.value,
                        totalNumberOfOperations: val.totalnumberofoperations?.value,

                    }
                    //console.log(r)
                    return r;
                }
                ) as any[])
                if (r.length > 0) {
                    state.totals = {
                        totalAmount: payload?.response?.success?.totalamount?.value,
                        totalNumberOfOperations: payload?.response?.success?.totalnumberofoperations?.value,
                        currencyDecimalPlaces: r[0]?.currencyDecimalPlaces,
                        currencySymbol: r[0]?.currencySymbol,

                    }
                } else {
                    state.totals = undefined;
                }
                state.set.items = r

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(exportReport.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload.response.success?.report) {
                downloadFile(payload.response.success?.report + "", payload.response.success.name?.value ?? "report" + getFileExtension(payload.metadata?.exportType))
            }

        })
        builder.addCase(exportReport.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(exportReport.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setMessage, resetSet, resetAccountsSet, setIsFilteredAccountSet, setSelectedAccountItems, resetCurrenciesSet, setIsFilteredCurrencySet, setOperation, setSelectedCurrencyItems } = transactionTotalsReportPageSlice.actions

export default transactionTotalsReportPageSlice.reducer


