export interface BaseFormProps {
    onSuccess?: (res: any) => any,
    onCancel: (state?: any) => any,
}

export enum FormType {
    ADD = 1,
    EDIT = 2,
}

export interface InputFormProps {
    type: FormType,
    renderObject?: any,
    fetchData?: boolean,

}