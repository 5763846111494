import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DatePicker, DayOfWeek, defaultDatePickerStrings, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks"
import { getPage } from "../../../../../../app/Pages"
import { setPage } from "../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, getItems, getCurrencies, resetCurrencies, setFromFilterOption, setOperationFilterOption, setSelectedCurrencyItems, setIsFilteredCurrenciesSet, setToFilterOption, setMessage, setSelectedAccountItems, getAccounts, resetAccountsSet, setIsFilteredAccountSet } from "./TreasuryStatementPageSlice"
import { ApiMessage, getHeaders } from "../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../../app/Themes"
import { SearchField } from "../../../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, getOperations, printComponent, normalizeKey, printStatement } from "../../../../../../app/Helpers"
import { TableState } from "../../../../../common/Table/TableSate"
import { Message } from "../../../../../common/Message/Message"
import { ShareBox } from "../../../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../../common/Popup/Popup"
import { FormType } from "../../../../../forms/FormProps"
import { TreasuryStatus, LimitationType, Operations, Natures } from "../../../../../../app/Enums"
import { GetCurrenciesRequest } from "../../../../../../repository/Accountant/currency_pb"
import { GetStatementRequest } from "../../../../../../repository/Accountant/treasury_operation_pb"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import moment from "moment"
import { GetAccountsRequest } from "../../../../../../repository/Accountant/account_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../../../PageProps"
import { List } from "../../../../../common/List/List"
import { CurrenciesPage } from "../../../Currencies/CurrenciesPage"
import { AccountsPage } from "../../../Accounts/AccountsPage"
import { TreasuryStatementForm } from "../../../../../forms/Accountant/Operations/Statement/TreasuryStatement/TreasuryStatementForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetStatementRequest;
let currenciesReq: GetCurrenciesRequest;
let accountsReq: GetAccountsRequest;
let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

interface TreasuryStatementPageProps {
    treasury: any
}
export const TreasuryStatementPage: React.FunctionComponent<TreasuryStatementPageProps & PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        fromFilterOption: { value: string, text: string }
        filters: any[],
        toFilterOption: { value: string, text: string }
        operationFilterOption: { key: number, text: string }
        currencies: TableState,
        accounts: TableState


    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.treasuryStatementPage.isChangeStateLoading,
            set: state.treasuryStatementPage.set,
            message: state.treasuryStatementPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.treasuryStatementPage.searchText,
            currencies: state.treasuryStatementPage.currencies,
            fromFilterOption: state.treasuryStatementPage.fromFilterOption,
            filters: state.treasuryStatementPage.filters,
            toFilterOption: state.treasuryStatementPage.toFilterOption,
            operationFilterOption: state.treasuryStatementPage.operationFilterOption,
            accounts: state.treasuryStatementPage.accounts

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeTreasuryActionConfirmationcalloutTargetId = useId('close-treasury-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);

    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});


    const printCreditContent = () => {
        return printStatement(state.set.selected.at(0), true, t)
    };

    const printDebitContent = () => {
        return printStatement(state.set.selected.at(0), false, t)
    };
    const handlePrintCredit = useReactToPrint({ ignoreGlobalStyles: true });
    const handlePrintDebit = useReactToPrint({ ignoreGlobalStyles: true });


    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeTreasuryActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedCurrencyItems([]))
        req.setCurrency(undefined)
        dispatch(setSelectedAccountItems([]))
        req.setAccount(undefined)
        dispatch(setOperationFilterOption({ key: 0, text: "" }))
        req.setOperation(undefined)
        dispatch(setFromFilterOption({ value: "", text: "" }))
        req.setFrom(undefined)
        dispatch(setToFilterOption({ value: "", text: "" }))
        req.setTo(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }


        if (state.currencies.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.currencies.selected.at(0)?.id));
            req.setCurrency(wrapper)
            isFiltered = true;
        } else {
            req.setCurrency(undefined)
        }

        if (state.accounts.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.accounts.selected.at(0)?.internalId));
            req.setAccount(wrapper)
            isFiltered = true;
        } else {
            req.setAccount(undefined)
        }

        if (state.operationFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.operationFilterOption.key);
            //req.setOperation(state.operationFilterOption.key)
            req.setOperation(wrapper)
            isFiltered = true;

        } else {
            req.setOperation(undefined)
        }

        if (state.fromFilterOption && state.fromFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();
            wrapper.fromDate(moment(state.fromFilterOption.value, 'YYYY-MM-DD').startOf('day').toDate())
            req.setFrom(wrapper)
            isFiltered = true;

        } else {
            req.setFrom(undefined)
        }

        if (state.toFilterOption && state.toFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();
            wrapper.fromDate(moment(state.toFilterOption.value, 'YYYY-MM-DD').endOf('day').toDate())
            req.setTo(wrapper)
            isFiltered = true;

        } else {
            req.setTo(undefined)
        }

        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetStatementRequest();
        currenciesReq = new GetCurrenciesRequest();
        accountsReq = new GetAccountsRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)
                accountsReq.setOrganization(wrapper)

            }
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)
        var r = new StringValue();
        r.setValue(props.treasury?.internalId);
        req.setTreasury(r)

        const boolv = new BoolValue();
        boolv.setValue(true);

        accountsReq.setNumofresults(state.accounts.numberOfResults)
        accountsReq.setOrder(state.accounts.isDescending)

        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
        //currenciesReq.setIsavailable(boolv)
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

        dispatch(setMessage({ body: t("statementTreasuryDesc", { treasury: props.treasury?.name }), type: 4, title: "", data: undefined }))

        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])
    /*
    
          public long Id { get; set; }
                public string InternalId { get; set; }
                public string Name { get; set; }
                public string Code { get; set; }
                public decimal MirrorBalance { get; set; }
                public CurrencyResponse Currency { get; set; }
                public int Status { get; set; }
                public AccountTypeResponse AccountType { get; set; }
                public OwnerResponse Owner { get; set; }
                public ProfileResponse CreatedBy { get; set; }
                public DateTime CreatedDate { get; set; }
    */

    const c: IColumn[] = [
        {
            key: 'id',
            name: t("transactionNumber"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,

            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/treasury-transactions/" + item?.internalId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/treasury-transactions/" + item?.internalId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.id}</Link >;
            },
        },
        {
            key: 'operation',
            name: t("operation"),
            minWidth: 20,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.operation) {
                    return <Text>{t(getOperations().find(x => x.key == item?.operation)?.text ?? item?.operation)}</Text>;;

                }
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'debtorDescription',
            name: t("description"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.creditorInternalId == props.treasury?.internalId)
                    return <Text>{item?.creditorDescription}</Text>;
                if (item?.debtorInternalId == props.treasury?.internalId)
                    return <Text>{item?.debtorDescription}</Text>;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'creditAmount',
            name: t("credit"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.creditorInternalId == props.treasury?.internalId && item?.creditAmount != undefined)
                    return <Text>{currencyFormatter(item?.creditAmount, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;


            },
        },
        {
            key: 'debitAmount',
            name: t("debit"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.debtorInternalId == props.treasury?.internalId && item?.debitAmount != undefined)
                    return <Text>{currencyFormatter(item?.debitAmount, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;
            },
        },



        {
            key: 'debtorBalanceBefore',
            name: t("balanceBefore"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.creditorInternalId == props.treasury?.internalId)
                    return <Text>{currencyFormatter(item?.creditorBalanceBefore, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;
                if (item?.debtorInternalId == props.treasury?.internalId)
                    return <Text>{currencyFormatter(item?.debtorBalanceBefore, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'debtorBalanceAfter',
            name: t("balanceAfter"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.creditorInternalId == props.treasury?.internalId)
                    return <Text>{currencyFormatter(item?.creditorBalanceAfter, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;
                if (item?.debtorInternalId == props.treasury?.internalId)
                    return <Text>{currencyFormatter(item?.debtorBalanceAfter, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'createdDate',
            name: t("date"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'chart',
            text: t("chart"),
            iconProps: { iconName: "chart" },
            title: t("chart"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/treasuries/chart?symbol=" + props.treasury?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            subMenuProps: {
                items: [{
                    key: 'printReceiptCredit',
                    text: t('printReceiptCredit'),
                    title: t("printReceiptCredit"),
                    iconProps: { iconName: 'M365InvoicingLogo' },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrintCredit(printCreditContent)

                        }

                    }
                },
                {
                    key: 'printReceiptDebit',
                    text: t('printReceiptDebit'),
                    title: t("printReceiptDebit"),
                    iconProps: { iconName: 'M365InvoicingLogo' },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrintDebit(printDebitContent)
                        }

                    }
                },


                ]
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'exportTreasuryStatement',
            text: t('exportTreasuryStatement'),
            title: t('exportTreasuryStatement'),
            iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];




    const accountsSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accounts.hasMore && !state.accounts.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accounts.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                accountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.accounts.items.length == 0 ? state.accounts.isFetching : false,
        isSearching: state.accounts.items.length > 0 ? state.accounts.isFetching : false,


    };


    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {


        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        accountsReq.setSearch(wrapper)
        accountsReq.setNextto(undefined)
        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
        return [];
    }





    const currenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.currencies.hasMore && !state.currencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.currencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                currenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.currencies.items.length == 0 ? state.currencies.isFetching : false,
        isSearching: state.currencies.items.length > 0 ? state.currencies.isFetching : false,


    };
    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }



    return (
        <Stack>

            <Popup isOpen={(currentAction == 1003)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >
                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>


            <Popup isOpen={(currentAction == 1000)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >
                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 100)} title={t("exportTreasuryStatement")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <TreasuryStatementForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{ treasury: props.treasury }} onSuccess={(e) => {
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>


            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareTransaction")} value={`https://${window.location.host}/treasury-transactions/${state.set.selected.at(0).internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}



            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label >{t("currency")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("currency"), }}
                                suggestionsHeaderText={t('currencies')}
                                isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []} onGetMoreResults={() => {
                                    if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                        currenciesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.currencies.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={true}
                                selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedCurrencyItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetCurrencies())
                                    currenciesReq.setSearch(undefined)
                                    currenciesReq.setNextto(undefined)
                                    dispatch(setIsFilteredCurrenciesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onCurrenciesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1003)

                                        }
                                    }]}
                            />

                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label >{t("account")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("account") }}
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('accounts')}
                                isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                        accountsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                    }

                                }}

                                selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedAccountItems([]))
                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetAccountsSet())
                                    accountsReq.setSearch(undefined)
                                    accountsReq.setNextto(undefined)
                                    dispatch(setIsFilteredAccountSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onAccountsFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000);

                                        }
                                    }]}
                            />

                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("trxType")}</Label>
                            <Dropdown
                                selectedKey={state.operationFilterOption.key}
                                options={[{ key: 0, text: t("all") }].concat(getOperations(true, 3).map(o => {
                                    return { key: o.key, text: t(normalizeKey(o.text)) }
                                }))}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {

                                    dispatch(setOperationFilterOption({ key: Number(item?.key), text: t("trxType") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("from")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("from")}
                                value={state.fromFilterOption.value != "" ? moment(state.fromFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("from")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setFromFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("from") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("to")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("to")}
                                value={state.toFilterOption.value != "" ? moment(state.toFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("to")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setToFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("to") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {


                                if (e.key == 1) {
                                    dispatch(setSelectedCurrencyItems([]))
                                    req.setCurrency(undefined)
                                }

                                if (e.key == 2) {
                                    dispatch(setFromFilterOption({ value: "", text: "" }))
                                    req.setFrom(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setToFilterOption({ value: "", text: "" }))
                                    req.setTo(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setOperationFilterOption({ key: 0, text: "" }))
                                    req.setOperation(undefined)
                                }

                                if (e.key == 5) {
                                    dispatch(setSelectedAccountItems([]))
                                    req.setAccount(undefined)
                                }
                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="statement"
                items={state.set.items}
                columns={c}
                selection={_selection}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 225px)" : "calc(100vh - 273px)"}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.internalId);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}

                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.internalId);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.set.selected.length > 0) {
                        props.onItemSelected(state.set.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




