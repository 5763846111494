import { ChoiceGroup, ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, Icon, IconButton, IDialogContentProps, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { add, dismissMessage, getCurrencyPairs, reset, resetCurrencyPairs, setIsFilteredCurrencyPairsSet, getFromAccounts, getToAccounts, resetFromAccountSet, resetToAccountSet, setIsFilteredFromAccountSet, setIsFilteredToAccountSet, setMessage, setSelectedCurrencyPairItems, setSelectedFromAccountItems, setSelectedToAccountItems, setStage1, resetMessage, getTransactionSummary, setIsAuthorizationRequired } from "./PlaceOrderFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatDate, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../repository/Accountant/common_pb";
import { TableState } from "../../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../../repository/Accountant/account_pb";
import { AccountStatus, AuthorizedOperation, Operations, OrderAction, OrderStatus, OrderType } from "../../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../../repository/Accountant/currency_pb";
import { GetCurrencyPairsRequest } from "../../../../../repository/Trading/currency_pair_pb";
import { DirectExchangeRequest, DirectExchangeResponse, ExternalExchangeRequest, ExternalExchangeResponse, PlaceOrderRequest, PlaceOrderResponse } from "../../../../../repository/Trading/order_pb";
import { GetTreasuriesRequest } from "../../../../../repository/Accountant/treasury_pb";
import { List } from "../../../../common/List/List";
import { Popup } from "../../../../common/Popup/Popup";
import { CurrencyPairsPage } from "../../../../pages/Trading/CurrencyPairs/CurrencyPairsPage";
import { AccountsPage } from "../../../../pages/Accountant/Accounts/AccountsPage";
import { TreasuriesPage } from "../../../../pages/Accountant/Treasuries/TreasuriesPage";
import { AuthorizationForm } from "../../../Accountant/Accounts/AuthorizationForm";
import { GetTransactionSummary, GetTransactionSummaryRequest } from "../../../../../repository/Accountant/account_operation_pb";


let req: PlaceOrderRequest;
let getFromAccountsReq: GetAccountsRequest;
let getToAccountsReq: GetAccountsRequest;
let currecyPairsReq: GetCurrencyPairsRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;
let currenciesReq: GetCurrenciesRequest;

export const PlaceOrderForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState({
        type: DialogType.normal,
        title: '',
        subText: '',
    } as IDialogContentProps);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const modalPropsStyles = { main: { maxWidth: 450 } };

    const [currentAction, setCurrenctAction] = useState(0)


    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [isDraggable],
    );

    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any, currencyPairs: TableState, fromAccount: TableState, toAccount: TableState,
        confirmation: any, requireAuth: boolean
    } = useAppSelector((state) => {

        return {
            isLoading: state.placeOrderForm.isLoading, message: state.placeOrderForm.message,
            stage1: state.placeOrderForm.stage1,
            fromAccount: state.placeOrderForm.fromAccountSet,
            toAccount: state.placeOrderForm.toAccountSet,
            currencyPairs: state.placeOrderForm.currencyPairs,
            confirmation: state.placeOrderForm.confirmation,
            requireAuth: state.placeOrderForm.requireAuth


        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new PlaceOrderRequest();
        currecyPairsReq = new GetCurrencyPairsRequest();
        getFromAccountsReq = new GetAccountsRequest();
        getToAccountsReq = new GetAccountsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                currecyPairsReq.setOrganization(wrapper)
                getFromAccountsReq.setOrganization(wrapper)
                getToAccountsReq.setOrganization(wrapper)

            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);

        currecyPairsReq.setNumofresults(state.currencyPairs.numberOfResults)
        currecyPairsReq.setOrder(state.currencyPairs.isDescending)
        currecyPairsReq.setIsactive(boolv)

        getFromAccountsReq.setNumofresults(state.fromAccount.numberOfResults)
        getFromAccountsReq.setOrder(state.fromAccount.isDescending)
        getFromAccountsReq.setStatus(stat)

        getToAccountsReq.setNumofresults(state.toAccount.numberOfResults)
        getToAccountsReq.setOrder(state.toAccount.isDescending)
        getToAccountsReq.setStatus(stat)

        if (props.renderObject?.matchOrder) {
            dispatch(setSelectedCurrencyPairItems([{
                id: props.renderObject?.matchOrder?.currencyPairId,
                name: props.renderObject?.matchOrder?.currencyPairName,
                baseCurrencyId: props.renderObject?.matchOrder?.currencyPairBaseCurrencyId,
                baseCurrencyName: props.renderObject?.matchOrder?.currencyPairBaseCurrencyName,
                baseCurrencyCode: props.renderObject?.matchOrder?.currencyPairBaseCurrencyCode,
                baseCurrencyDecimalPlaces: props.renderObject?.matchOrder?.currencyPairBaseCurrencyDecimalPlaces,
                baseCurrencyForm: props.renderObject?.matchOrder?.currencyPairBaseCurrencyForm,
                baseCurrencySymbol: props.renderObject?.matchOrder?.currencyPairBaseCurrencySymbol,
                quoteCurrencyId: props.renderObject?.matchOrder?.currencyPairQuoteCurrencyId,
                quoteCurrencyName: props.renderObject?.matchOrder?.currencyPairQuoteCurrencyName,
                quoteCurrencyCode: props.renderObject?.matchOrder?.currencyPairQuoteCurrencyCode,
                quoteCurrencyDecimalPlaces: props.renderObject?.matchOrder?.currencyPairQuoteCurrencyDecimalPlaces,
                quoteCurrencyForm: props.renderObject?.matchOrder?.currencyPairQuoteCurrencyForm,
                quoteCurrencySymbol: props.renderObject?.matchOrder?.currencyPairQuoteCurrencySymbol,
            }]))
        }

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])

    useEffect(() => {
        if (state.confirmation.length > 0) {
            var fees = "";
            if (state.confirmation.at(0)?.debtorfeeValue != undefined && state.confirmation.at(0)?.debtorfeeValue != 0) {
                const fromAccountFeeAmount = currencyFormatter(Number(state.confirmation.at(0).debtorfeeValue ?? 0), { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
                fees = t("fromAccount", {
                    fromAccount: (state.fromAccount?.selected?.at(0)?.name ?? "") + " (#" + state.fromAccount?.selected?.at(0)?.id + ")",
                    fromAccountFeeAmount: fromAccountFeeAmount,
                })
            }
            if (state.confirmation.at(1)?.creditorfeeValue != undefined && state.confirmation.at(1)?.creditorfeeValue != 0) {
                const toAccountFeeAmount = currencyFormatter(Number(state.confirmation.at(1).creditorfeeValue ?? 0), { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
                fees = fees + t("toAccount", {
                    toAccount: (state.toAccount?.selected?.at(0)?.name ?? "") + " (#" + state.toAccount?.selected?.at(0)?.id + ")",
                    toAccountFeeAmount: toAccountFeeAmount,
                })
            }


            const formattedAmount = currencyFormatter(state.currencyPairs?.selected.at(0)?.baseCurrencyId == state.confirmation.at(0).currencyId ? state.confirmation.at(0).amount : state.confirmation.at(1).amount, { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
            const formattedPrice = currencyFormatter(state.confirmation.at(0).price, { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })
            const formattedTotal = currencyFormatter(state.currencyPairs?.selected.at(0)?.baseCurrencyId == state.confirmation.at(0).currencyId ? state.confirmation.at(1).amount : state.confirmation.at(0).amount, { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })

            if (fees != "") {
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    styles: { subText: { whiteSpace: 'pre-line' } },
                    subText: t("executeExternalOrderConfirmWithFees", {
                        amount: formattedAmount, price: formattedPrice, total: formattedTotal,
                    }) + fees,
                })
            } else {
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    styles: { subText: { whiteSpace: 'pre-line' } },
                    subText: t("executeExternalOrderConfirm", {
                        amount: formattedAmount, price: formattedPrice, total: formattedTotal,
                    }),
                })
            }


            toggleHideDialog()
        }
    }, [state.confirmation])

    const toSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.toAccount.hasMore && !state.toAccount.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.toAccount.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedToAccountItems([state.toAccount.items.at(u)]))
            }


        },

        onGetMoreResults: () => {
            if (state.toAccount.items.length > 0 && !state.toAccount.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.toAccount.items.at(state.toAccount.items.length - 1).internalId);
                getToAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.toAccount.items.length > 0 ? state.toAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.toAccount.items.length == 0 ? state.toAccount.isFetching : false,
        isSearching: state.toAccount.items.length > 0 ? state.toAccount.isFetching : false,


    };


    const onToAccountsFilterChanged = (filterText: string, side: number, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetToAccountSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getToAccountsReq.setSearch(wrapper)
        getToAccountsReq.setNextto(undefined)
        if (state.currencyPairs.selected.length > 0) {
            let r = new StringValue();
            r.setValue(side == 1 ? state.currencyPairs.selected.at(0).quoteCurrencyId : state.currencyPairs.selected.at(0).baseCurrencyId);
            getToAccountsReq.setCurrency(r);
        } else {
            getToAccountsReq.setCurrency(undefined);

        }

        if (state.fromAccount.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.fromAccount.selected.at(0).ownerId);
            getToAccountsReq.setOwner(r);
        } else {
            getToAccountsReq.setOwner(undefined);
        }

        dispatch(setIsFilteredToAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
        return [];

    }


    const fromSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.fromAccount.hasMore && !state.fromAccount.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.fromAccount.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedFromAccountItems([state.fromAccount.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.fromAccount.items.length > 0 && !state.fromAccount.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.fromAccount.items.at(state.fromAccount.items.length - 1).internalId);
                getFromAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.fromAccount.items.length > 0 ? state.fromAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.fromAccount.items.length == 0 ? state.fromAccount.isFetching : false,
        isSearching: state.fromAccount.items.length > 0 ? state.fromAccount.isFetching : false,


    };


    const onFromAccountsFilterChanged = (filterText: string, side: number, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetFromAccountSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getFromAccountsReq.setSearch(wrapper)
        getFromAccountsReq.setNextto(undefined)
        if (state.currencyPairs.selected.length > 0) {
            let r = new StringValue();
            r.setValue(side == 1 ? state.currencyPairs.selected.at(0).baseCurrencyId : state.currencyPairs.selected.at(0).quoteCurrencyId);
            getFromAccountsReq.setCurrency(r);
        } else {
            getFromAccountsReq.setCurrency(undefined);

        }

        if (state.toAccount.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.toAccount.selected.at(0).ownerId);
            getFromAccountsReq.setOwner(r);
        } else {
            getFromAccountsReq.setOwner(undefined);

        }
        dispatch(setIsFilteredFromAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
        return [];


    }





    const pairsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('pair'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.currencyPairs.hasMore && !state.currencyPairs.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.currencyPairs.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedCurrencyPairItems([state.currencyPairs.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.currencyPairs.items.length > 0 && !state.currencyPairs.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.currencyPairs.items.at(state.currencyPairs.items.length - 1).id);
                currecyPairsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.currencyPairs.items.length > 0 ? state.currencyPairs.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.currencyPairs.items.length == 0 ? state.currencyPairs.isFetching : false,
        isSearching: state.currencyPairs.items.length > 0 ? state.currencyPairs.isFetching : false,


    };
    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencyPairs())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currecyPairsReq.setSearch(wrapper)
        currecyPairsReq.setNextto(undefined)
        dispatch(setIsFilteredCurrencyPairsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
        return [];

    }


    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("pair")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyPairItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedFromAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 102)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedToAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Formik
                enableReinitialize={true}
                initialValues={{

                    fromAccount: undefined,
                    toAccount: undefined,
                    currencyPairs: undefined,
                    priceOption: props.renderObject?.matchOrder ? { key: "3", text: "" } : { key: "2", text: t("sell") },
                    price: props.renderObject?.matchOrder?.itemPrice ? Number(props.renderObject?.matchOrder?.itemPrice).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9) : Number(state.currencyPairs?.selected?.at(0)?.sellPrice).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9) ?? "",
                    amount: props.renderObject?.matchOrder?.remainingQuantityFilled ? Number(props.renderObject?.matchOrder?.remainingQuantityFilled).toFixed(state.currencyPairs.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9) : "",
                    total: props.renderObject?.matchOrder?.remainingFilled ? Number(props.renderObject?.matchOrder?.remainingFilled).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9) : "",
                    side: props.renderObject?.matchOrder?.tradeAction == OrderAction.SELL ? { key: 2, text: t("buy") } : { key: 1, text: t("sell") },
                    type: props.renderObject?.matchOrder?.orderType == OrderType.ALL_OR_NONE_ORDER ? { key: OrderType.ALL_OR_NONE_ORDER, text: t("AON") } : { key: OrderType.LIMIT_ORDER, text: t("limitOrder") },

                }}

                validationSchema={Yup.object({
                    price: inputs.price,
                    amount: inputs.amount

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let fromAccount = state.fromAccount.selected.at(0);
                        let toAccount = state.toAccount.selected.at(0);
                        let currencyPairs = state.currencyPairs.selected.at(0);
                        if (fromAccount == undefined || toAccount == undefined || currencyPairs == undefined) {
                            return;
                        }
                        if (fromAccount.internalId == toAccount.internalId) {
                            dispatch(setSelectedFromAccountItems([]))
                            dispatch(setSelectedToAccountItems([]))
                            return;
                        }


                        if (props.renderObject?.matchOrder) {
                            req.setMatchorder(props.renderObject?.matchOrder?.id)
                        }


                        req.setFromaccount(fromAccount.internalId);
                        req.setToaccount(toAccount.internalId)
                        req.setCurrencypair(currencyPairs.id);
                        req.setPrice(values.price?.replace(/,/g, '') + "")
                        req.setQuantity(values.amount?.replace(/,/g, '') + "")
                        req.setSide(values.side.key == 1)
                        req.setType(Number(values.type.key))

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: PlaceOrderResponse.AsObject) => {

                                if (res) {
                                    if (props?.onSuccess) {

                                        let r = undefined;
                                        if (props.renderObject?.matchOrder) {
                                            r = {
                                                ...props.renderObject?.matchOrder,
                                                quantityFilled: res.success?.matchorderquantityfilled,
                                                remainingFilled: res.success?.matchorderremainingfilled,
                                                remainingQuantityFilled: res.success?.matchorderremainingquantityfilled,
                                                filled: res.success?.matchorderfilled,
                                                status: (Number(res.success?.matchorderremainingquantityfilled) == 0) ? OrderStatus.FILLD : OrderStatus.OPEN,
                                            }
                                        }

                                        props?.onSuccess({
                                            order: {
                                                id: res?.success?.order,
                                                orderType: Number(values.type.key),
                                                currencyPairId: currencyPairs.id,
                                                currencyPairName: currencyPairs.name,
                                                currencyPairBaseCurrencyId: currencyPairs.baseCurrencyId,
                                                currencyPairBaseCurrencyName: currencyPairs.baseCurrencyName,
                                                currencyPairBaseCurrencyCode: currencyPairs.baseCurrencyCode,
                                                currencyPairBaseCurrencyDecimalPlaces: currencyPairs.baseCurrencyDecimalPlaces,
                                                currencyPairBaseCurrencyForm: currencyPairs.baseCurrencyForm,
                                                currencyPairBaseCurrencySymbol: currencyPairs.baseCurrencySymbol,
                                                currencyPairQuoteCurrencyId: currencyPairs.quoteCurrencyId,
                                                currencyPairQuoteCurrencyName: currencyPairs.quoteCurrencyName,
                                                currencyPairQuoteCurrencyCode: currencyPairs.quoteCurrencyCode,
                                                currencyPairQuoteCurrencyDecimalPlaces: currencyPairs.quoteCurrencyDecimalPlaces,
                                                currencyPairQuoteCurrencyForm: currencyPairs.quoteCurrencyForm,
                                                currencyPairQuoteCurrencySymbol: currencyPairs.quoteCurrencySymbol,
                                                currencyId: currencyPairs.quoteCurrencyId,
                                                currencyName: currencyPairs.quoteCurrencyName,
                                                currencyCode: currencyPairs.quoteCurrencyCode,
                                                currencyDecimalPlaces: currencyPairs.quoteCurrencyDecimalPlaces,
                                                currencyForm: currencyPairs.quoteCurrencyForm,
                                                currencySymbol: currencyPairs.quoteCurrencySymbol,
                                                itemPrice: values.price?.replace(/,/g, ''),
                                                itemQuantity: values.amount?.replace(/,/g, ''),
                                                clientId: fromAccount.ownerId,
                                                clientName: toAccount.ownerName,
                                                status: (Number(res.success?.remainingquantityfilled) == 0) ? OrderStatus.FILLD : OrderStatus.OPEN,
                                                total: values.total,
                                                tradeAction: values.side.key == 1 ? OrderAction.SELL : OrderAction.BUY,
                                                createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                                addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                                addedById: current.profile?.id?.value,
                                                quantityFilled: res.success?.quantityfilled,
                                                remainingFilled: res.success?.remainingfilled,
                                                remainingQuantityFilled: res.success?.remainingquantityfilled,
                                                filled: res.success?.filled
                                            },
                                            matchOrder: r

                                        });


                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}
            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()
                        formkikProps.validateForm();

                        let a = Number(formkikProps.values.amount?.replace(/,/g, ''))
                        let p = Number(formkikProps.values.price?.replace(/,/g, ''))
                        let total = (a * p).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9);

                        if (isNaN(a) || !formkikProps.isValid || a == 0 || isNaN(p) || p == 0) {
                            return;
                        }
                        formkikProps.setFieldValue("total", total)
                        dispatch(resetMessage())
                        let fromAccount = state.fromAccount.selected.at(0);
                        let toAccount = state.toAccount.selected.at(0);
                        let currencyPairs = state.currencyPairs.selected.at(0);
                        if (fromAccount == undefined || toAccount == undefined) {
                            return;
                        }
                        if (fromAccount.internalId == toAccount.internalId) {
                            dispatch(setSelectedFromAccountItems([]))
                            dispatch(setSelectedToAccountItems([]))
                            return;
                        }



                        req.setAuthcode(undefined)
                        if (fromAccount.isAuthorizationRequired || state.requireAuth) {
                            setCurrenctAction(13);
                            return;
                        }

                        const formattedAmount = currencyFormatter(a, { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
                        const formattedPrice = currencyFormatter(p, { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })
                        const formattedTotal = currencyFormatter(Number(total), { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })

                        setShowConfirmation({
                            type: DialogType.normal,
                            title: t("confirm"),
                            styles: { subText: { whiteSpace: 'pre-line' } },
                            subText: t("executeOrderConfirm", {
                                amount: formattedAmount, price: formattedPrice, total: formattedTotal,
                            }),
                        })

                        toggleHideDialog()
                    }}  >
                        <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                            <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                                account: state.fromAccount.selected.at(0),
                                operation: formkikProps.values.side.key == 1 ? AuthorizedOperation.TRADING_ORDER_SELL : AuthorizedOperation.TRADING_ORDER_BUY,
                                parameters: [
                                    { key: "CurrencyPair", value: state.currencyPairs.selected.at(0)?.id + "", viewValue: state.currencyPairs.selected.at(0)?.name },
                                    { key: "Quantity", value: formkikProps.values.amount?.replace(/,/g, ''), viewValue: state.currencyPairs.selected.at(0)?.baseCurrencySymbol },
                                    { key: "Price", value: formkikProps.values.price?.replace(/,/g, '') + "", viewValue: state.currencyPairs.selected.at(0)?.quoteCurrencySymbol },
                                    { key: "Total", value: formkikProps.values.total?.replace(/,/g, '') + "", viewValue: state.currencyPairs.selected.at(0)?.quoteCurrencySymbol },
                                    { key: "ToAccount", value: state.toAccount.selected.at(0)?.internalId + "", viewValue: state.toAccount.selected.at(0)?.id + "" },
                                ],

                            }} onSuccess={(e) => {
                                if (req && e.authCode) {
                                    if (currentAction == 13) {
                                        var r = new StringValue();
                                        r.setValue(e.authCode)
                                        req.setAuthcode(r)
                                        setCurrenctAction(0)

                                        let a = Number(formkikProps.values.amount?.replace(/,/g, ''))
                                        let p = Number(formkikProps.values.price?.replace(/,/g, ''))
                                        let total = (a * p)


                                        if (isNaN(a) || !formkikProps.isValid || a == 0 || isNaN(p) || p == 0) {
                                            return;
                                        }


                                        const formattedAmount = currencyFormatter(a, { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
                                        const formattedPrice = currencyFormatter(p, { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })
                                        const formattedTotal = currencyFormatter(total, { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })


                                        setShowConfirmation({
                                            type: DialogType.normal,
                                            title: t("confirm"),
                                            styles: { subText: { whiteSpace: 'pre-line' } },
                                            subText: t("executeOrderConfirm", {
                                                amount: formattedAmount, price: formattedPrice, total: formattedTotal,
                                            }),
                                        })

                                        toggleHideDialog()

                                    }
                                } else {
                                    setCurrenctAction(0)
                                }
                            }} onCancel={() => { setCurrenctAction(0) }} />
                        </Popup>
                        <Dialog
                            hidden={hideDialog}
                            onDismiss={toggleHideDialog}
                            dialogContentProps={showConfirmation}
                            modalProps={modalProps}

                        >
                            <DialogFooter>
                                <PrimaryButton autoFocus onClick={(e: any) => { formkikProps.handleSubmit(e); toggleHideDialog() }} text={t("execute")} type="submit" />
                                <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
                            </DialogFooter>
                        </Dialog>
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }} >
                                {props.type == FormType.ADD ? <Stack grow>
                                    <Label required disabled={state.isLoading}>{t("pair")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("pair"), required: true }}
                                        disabled={state.isLoading || (props.renderObject?.matchOrder != undefined)}
                                        suggestionsHeaderText={t('pair')}
                                        isLoading={state.currencyPairs.items.length == 0 ? state.currencyPairs.isFetching : false}
                                        isSearching={state.currencyPairs.items.length > 0 ? state.currencyPairs.isFetching : false}
                                        moreSuggestionsAvailable={state.currencyPairs.hasMore && !state.currencyPairs.isFetching}
                                        suggestions={state.currencyPairs.items.length > 0 ? state.currencyPairs.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.currencyPairs.items.length > 0 && !state.currencyPairs.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.currencyPairs.items.at(state.currencyPairs.items.length - 1).id);
                                                currecyPairsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.currencyPairs.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedCurrencyPairItems([state.currencyPairs.items.at(u)]))
                                            }


                                        }}
                                        isPeoplePicker={true}
                                        selectedItems={state.currencyPairs.selected.length > 0 ? state.currencyPairs.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedCurrencyPairItems([]))
                                            dispatch(setSelectedFromAccountItems([]))
                                            dispatch(setSelectedToAccountItems([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetCurrencyPairs())
                                            currecyPairsReq.setSearch(undefined)
                                            currecyPairsReq.setNextto(undefined)

                                            dispatch(setIsFilteredCurrencyPairsSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onPairsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(100)

                                                }
                                            }]}
                                    />
                                </Stack> : undefined}

                            </Stack>

                            {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ? <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }} >



                                <Stack.Item grow >
                                    <ComboBox
                                        required
                                        label={t("side")}
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={state.isLoading || (props.renderObject?.matchOrder != undefined)}
                                        options={[{ key: 1, text: t("sell") }, { key: 2, text: t("buy") },
                                        ]}
                                        selectedKey={formkikProps.values?.side?.key}

                                        onChange={(e, o) => {
                                            dispatch(setSelectedFromAccountItems([]))
                                            dispatch(setSelectedToAccountItems([]))

                                            formkikProps.setFieldValue(`side`, { key: o?.key, text: o?.text })
                                            formkikProps.setFieldValue("priceOption", { key: o?.key == 1 ? "2" : "1", text: o?.text })
                                            let price;
                                            if (state.currencyPairs.selected.at(0)) {

                                                if (o?.key == 2) {
                                                    price = state.currencyPairs.selected.at(0).buyPrice;
                                                    formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                                }

                                                if (o?.key == 1) {
                                                    price = state.currencyPairs.selected.at(0).sellPrice;
                                                    formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))

                                                }

                                                if (formkikProps.values.amount) {
                                                    formkikProps.setFieldValue("total", (Number(price?.replace(/,/g, '')) * Number(formkikProps.values.amount?.replace(/,/g, ''))).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                                }
                                            }

                                        }}
                                    // Force re-creating the component when the toggles change (for demo purposes)
                                    />
                                </Stack.Item>



                                <Stack.Item grow >
                                    <ComboBox
                                        required
                                        label={t("type")}
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={state.isLoading || (props.renderObject?.matchOrder != undefined)}
                                        options={[{ key: OrderType.LIMIT_ORDER, text: t("limitOrder") }, { key: OrderType.ALL_OR_NONE_ORDER, text: t("AON") },]}

                                        selectedKey={formkikProps?.values?.type?.key}
                                        onChange={(e, o) => {
                                            formkikProps.setFieldValue("type", { key: o?.key, text: o?.text })
                                        }}
                                    // Force re-creating the component when the toggles change (for demo purposes)
                                    />

                                </Stack.Item>
                            </Stack> : undefined}
                            <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }} >
                                {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ? <Stack grow>
                                    <Label required disabled={state.isLoading}>{formkikProps.values?.side?.key == 1 ? t("sellerFrom", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode }) : t("buyerFrom", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode })}</Label>

                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.fromAccount.items.length == 0 ? state.fromAccount.isFetching : false}
                                        isSearching={state.fromAccount.items.length > 0 ? state.fromAccount.isFetching : false}
                                        moreSuggestionsAvailable={state.fromAccount.hasMore && !state.fromAccount.isFetching}
                                        suggestions={state.fromAccount.items.length > 0 ? state.fromAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.fromAccount.items.length > 0 && !state.fromAccount.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.fromAccount.items.at(state.fromAccount.items.length - 1).internalId);
                                                getFromAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {

                                            var u = state.fromAccount.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedFromAccountItems([state.fromAccount.items.at(u)]))
                                            }
                                        }}
                                        inputProps={{ placeholder: formkikProps.values?.side?.key == 1 ? t("sellerFrom", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode }) : t("buyerFrom", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode }), required: true }}

                                        disabled={state.isLoading}
                                        selectedItems={state.fromAccount.selected.length > 0 ? state.fromAccount.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedFromAccountItems([]))
                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetFromAccountSet())
                                            getFromAccountsReq.setSearch(undefined)
                                            getFromAccountsReq.setNextto(undefined)
                                            if (state.currencyPairs.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(formkikProps.values?.side?.key == 1 ? state.currencyPairs.selected.at(0).baseCurrencyId : state.currencyPairs.selected.at(0).quoteCurrencyId);
                                                getFromAccountsReq.setCurrency(r);
                                            } else {
                                                getFromAccountsReq.setCurrency(undefined);

                                            }
                                            if (state.toAccount.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.toAccount.selected.at(0).ownerId);
                                                getFromAccountsReq.setOwner(r);
                                            } else {
                                                getFromAccountsReq.setOwner(undefined);
                                            }
                                            dispatch(setIsFilteredFromAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getFromAccounts({ body: getFromAccountsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={(filterText: string, selectedItems?: any[] | undefined) => {
                                            return onFromAccountsFilterChanged(filterText, formkikProps.values?.side?.key, selectedItems)
                                        }}
                                        endButtons={state.fromAccount.selected?.at(0)?.isAuthorizationRequired ? [
                                            {
                                                title: t("more"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(101);
                                                }
                                            }
                                        ] : [
                                            {
                                                title: t("more"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(101);
                                                }
                                            },
                                            {
                                                title: state.requireAuth ? t("operationAuthorizationEnabled") : t("operationAuthorizationDisabled"), checked: state.requireAuth, disabled: false, iconProps: { iconName: !state.requireAuth ? "Unlock" : "Lock" },
                                                onClick: () => {
                                                    dispatch(setIsAuthorizationRequired(!state.requireAuth))
                                                }
                                            }
                                        ]}
                                    />

                                </Stack> : undefined}

                                {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ? <Stack grow>
                                    <Label required disabled={state.isLoading}>{formkikProps.values?.side?.key == 1 ? t("sellerTo", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode }) : t("buyerTo", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode })}</Label>

                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.toAccount.items.length == 0 ? state.toAccount.isFetching : false}
                                        isSearching={state.toAccount.items.length > 0 ? state.toAccount.isFetching : false}
                                        moreSuggestionsAvailable={state.toAccount.hasMore && !state.toAccount.isFetching}
                                        suggestions={state.toAccount.items.length > 0 ? state.toAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.toAccount.items.length > 0 && !state.toAccount.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.toAccount.items.at(state.toAccount.items.length - 1).internalId);
                                                getToAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.toAccount.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedToAccountItems([state.toAccount.items.at(u)]))
                                            }
                                        }}
                                        inputProps={{ placeholder: formkikProps.values?.side?.key == 1 ? t("sellerTo", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode }) : t("buyerTo", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode }), required: true }}

                                        disabled={state.isLoading}
                                        selectedItems={state.toAccount.selected.length > 0 ? state.toAccount.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedToAccountItems([]))
                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetToAccountSet())
                                            getToAccountsReq.setSearch(undefined)
                                            getToAccountsReq.setNextto(undefined)
                                            if (state.currencyPairs.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(formkikProps.values?.side?.key == 1 ? state.currencyPairs.selected.at(0).quoteCurrencyId : state.currencyPairs.selected.at(0).baseCurrencyId);
                                                getToAccountsReq.setCurrency(r);
                                            } else {
                                                getToAccountsReq.setCurrency(undefined);

                                            }
                                            if (state.fromAccount.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.fromAccount.selected.at(0).ownerId);
                                                getToAccountsReq.setOwner(r);
                                            } else {
                                                getToAccountsReq.setOwner(undefined);

                                            }
                                            dispatch(setIsFilteredToAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getToAccounts({ body: getToAccountsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={(filterText: string, selectedItems?: any[] | undefined) => {
                                            return onToAccountsFilterChanged(filterText, formkikProps.values?.side?.key, selectedItems)
                                        }}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(102)

                                                }
                                            }]}
                                    />

                                </Stack> : undefined}
                            </Stack>



                            {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ?
                                <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }}>

                                    {props.renderObject?.matchOrder == undefined ? <ChoiceGroup styles={{ flexContainer: { display: "flex" } }} label={t("priceOption")}

                                        disabled={state.isLoading}
                                        name="priceOption"
                                        //selectedKey={formkikProps.values.gender.key}
                                        selectedKey={formkikProps.values.priceOption.key}
                                        options={formkikProps.values.side.key == 1 ? [{ key: "2", text: t("sell") + "\u00A0\u00A0" }, { key: "3", text: t("custom") }]
                                            : [{ key: "1", text: t("buy") + "\u00A0\u00A0" }, { key: "3", text: t("custom") }]}
                                        onChange={(e, o) => {
                                            let price;
                                            if (o?.key == "1") {
                                                price = state.currencyPairs.selected.at(0).buyPrice;
                                                formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }

                                            if (o?.key == "2") {
                                                price = state.currencyPairs.selected.at(0).sellPrice;
                                                formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }

                                            if (o?.key == "3") {
                                                price = state.currencyPairs.selected.at(0).tradingPrice;
                                                formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }
                                            formkikProps.setFieldValue("priceOption", { key: o?.key, text: o?.text })
                                            if (formkikProps.values.amount) {
                                                formkikProps.setFieldValue("total", (Number(price?.replace(/,/g, '')) * Number(formkikProps.values.amount?.replace(/,/g, ''))).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }
                                        }} required={true} /> : undefined}


                                    <Stack.Item grow >
                                        <Field name="price" label={t("price")} placeholder={t("price")}
                                            component={InputField} disabled={state.isLoading} maxLength={15} readOnly={formkikProps.values.priceOption.key != "3" || props.renderObject?.matchOrder?.itemPrice != undefined}
                                            required={formkikProps.values.priceOption.key == "3"} value={formkikProps.values.priceOption.key != "3" ? formkikProps.values.side.key == 1 ? Number(state.currencyPairs?.selected?.at(0)?.sellPrice).toFixed(state.currencyPairs.selected.at(0).quoteCurrencyDecimalPlaces ?? 9) : Number(state.currencyPairs?.selected?.at(0)?.buyPrice).toFixed(state.currencyPairs.selected.at(0).quoteCurrencyDecimalPlaces ?? 9) : formkikProps.values.price} suffix={state.currencyPairs.selected.at(0).quoteCurrencyCode}
                                            onBlur={(event: any) => {
                                                if (isNaN(Number(formkikProps.values.price?.replace(/,/g, '')))) {
                                                    formkikProps.handleBlur(event);
                                                    return;
                                                }
                                                let account = state.currencyPairs?.selected?.at(0);
                                                let price;
                                                if (account)
                                                    price = Number(formkikProps.values.price?.replace(/,/g, '')).toFixed(account?.quoteCurrencyDecimalPlaces ?? 9);
                                                else
                                                    price = Number(formkikProps.values.price?.replace(/,/g, '')).toFixed(9)

                                                formkikProps.setFieldValue("price", price)
                                                if (formkikProps.values.amount) {
                                                    formkikProps.setFieldValue("total", (Number(price) * Number(formkikProps.values.amount?.replace(/,/g, ''))).toFixed(account?.quoteCurrencyDecimalPlaces ?? 9))
                                                }

                                                formkikProps.handleBlur(event);
                                            }} />
                                    </Stack.Item>
                                </Stack> : undefined}


                            {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ?
                                <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }} >
                                    <Stack.Item grow >
                                        <Field name="amount" label={t("amount")} placeholder={t("amount")}
                                            component={InputField} disabled={state.isLoading} maxLength={15} readOnly={props.renderObject?.matchOrder?.orderType == OrderType.ALL_OR_NONE_ORDER}
                                            required suffix={state.currencyPairs.selected.at(0).baseCurrencyCode} onBlur={(event: any) => {
                                                if (isNaN(Number(formkikProps.values.amount?.replace(/,/g, '')))) {
                                                    formkikProps.handleBlur(event);
                                                    return;
                                                }
                                                let account = state.currencyPairs?.selected?.at(0);
                                                let amount;
                                                if (account)
                                                    amount = Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(account?.baseCurrencyDecimalPlaces ?? 9);
                                                else
                                                    amount = Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(9)

                                                formkikProps.setFieldValue("amount", amount)
                                                if (formkikProps.values.price) {
                                                    formkikProps.setFieldValue("total", (Number(amount?.replace(/,/g, '')) * Number(formkikProps.values.price?.replace(/,/g, ''))).toFixed(account?.quoteCurrencyDecimalPlaces ?? 9))
                                                }

                                                formkikProps.handleBlur(event);
                                            }} />
                                    </Stack.Item>
                                    <Stack.Item grow >
                                        <Field name="total" label={t("total")}
                                            component={InputField} disabled={state.isLoading} readOnly
                                            suffix={state.currencyPairs.selected.at(0).quoteCurrencyCode} />
                                    </Stack.Item>
                                </Stack> : undefined}


                        </Stack>

                        <DialogFooter>
                            <PrimaryButton type="submit" disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("execute") : t("edit")}
                            >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </>
    )
}

