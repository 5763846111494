import { ComboBox, DefaultButton, DialogFooter, Dropdown, Icon, IconButton, IDropdownOption, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { dismissMessage, getClinetUsers, reset, get, resetUsers, setIsFilteredUsersSet, setStage1, setSelectedUserItems, add, update, resetMessage, getAccounts, setSelectedAccountItems, resetAccountsSet, setIsFilteredAccountSet, setIsAuthorizationRequired } from "./AccountAuthorizationFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, getAccountAuthorizedPermissions, normalizeKey, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, AuthorizedOperation, LimitationType, Operations } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { AddAccountAuthorizationRequest, AddAccountAuthorizationResponse, UpdateAccountAuthorizationRequest, UpdateAccountAuthorizationResponse, GetAccountAuthorizationRequest } from "../../../../repository/Accountant/account_autorization_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { UsersPage } from "../../../pages/Customers/Users/UsersPage";
import { LimitationsPage } from "../../../pages/Accountant/Limitations/LimitationsPage";
import { AccountTypesPage } from "../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";
import { AuthorizationForm } from "./AuthorizationForm";
import { GetAccountsRequest } from "../../../../repository/Accountant/account_pb";
import { AccountsPage } from "../../../pages/Accountant/Accounts/AccountsPage";

let req: AddAccountAuthorizationRequest;
let updateReq: UpdateAccountAuthorizationRequest;

let clintsReq: GetUsersRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;
let getAccountsReq: GetAccountsRequest;

export const AccountAuthorizationForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any,
        clients: TableState, requireAuth: boolean,
        accounts: TableState
    } = useAppSelector((state) => {

        return {
            isLoading: state.accountAuthorizationForm.isLoading, message: state.accountAuthorizationForm.message, stage1: state.accountAuthorizationForm.stage1,
            clients: state.accountAuthorizationForm.clients, requireAuth: state.accountAuthorizationForm.requireAuth,
            accounts: state.accountAuthorizationForm.accounts


        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddAccountAuthorizationRequest();
        updateReq = new UpdateAccountAuthorizationRequest();
        clintsReq = new GetUsersRequest();
        getAccountsReq = new GetAccountsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)
                getAccountsReq.setOrganization(wrapper)

            }
        }
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);

        getAccountsReq.setNumofresults(state.accounts.numberOfResults)
        getAccountsReq.setOrder(state.accounts.isDescending)
        getAccountsReq.setStatus(stat)
        if (props.renderObject) {

            dispatch(setSelectedAccountItems(([props.renderObject])))
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAccountAuthorizationRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        } else {
            req.setAccount(props.renderObject?.internalId)
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const type = new Int32Value();
        type.setValue(LimitationType.ACCOUNT_LIMITATION);
        clintsReq.setNumofresults(state.clients.numberOfResults)
        clintsReq.setOrder(state.clients.isDescending)

        if (props.renderObject?.authorizedClientId)
            dispatch(setSelectedUserItems(([{ id: props.renderObject.authorizedClientId, name: props.renderObject.authorizedClientName }])))



        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    const onClientsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetUsers())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredUsersSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }
    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getAccountsReq.setSearch(wrapper)
        getAccountsReq.setNextto(undefined)

        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
        return [];

    }

    return (
        <>
            <Popup isOpen={(currentAction == 101)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 100)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    client: undefined,
                    permessions: [],
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],

                }}

                validationSchema={Yup.object({

                    extraFields: inputs.extraFields,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let clients = state.clients.selected.at(0);

                        req.setAuthorizedclient(clients?.internalId);
                        const permessions: number[] = [];
                        values.permessions.forEach((e: any) => {
                            permessions.push(e)
                        })
                        req.setPermissionsList(permessions)

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddAccountAuthorizationResponse.AsObject) => {
                                if (res) {
                                    req.setAuthcode(undefined)

                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            accountId: props.renderObject?.internalId,
                                            accountName: props.renderObject?.name,
                                            accountCurrencyId: props.renderObject?.currencyId,
                                            accountCurrencyName: props.renderObject?.currencyName,
                                            accountCurrencyCode: props.renderObject?.currencyCode,
                                            accountCurrencyDecimalPlaces: props.renderObject?.currencyDecimalPlaces,
                                            accountCurrencyForm: props.renderObject?.currencyForm,
                                            accountCurrencySymbol: props.renderObject?.currencySymbol,
                                            authorizedClientId: clients?.id,
                                            authorizedClientFirstName: clients?.firstname,
                                            authorizedClientMiddleName: clients?.middlename,
                                            authorizedClientLastName: clients?.lastname,
                                            authorizedClientName: (clients?.firstname ?? "") + " " + (clients?.lastname ?? ""),
                                            permissions: permessions,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,





                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                req.setAuthcode(undefined)

                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setId(props.renderObject?.id);
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateAccountAuthorizationResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            extraFields: values.extraFields
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()

                        dispatch(resetMessage())
                        let account = props.renderObject;

                        req.setAuthcode(undefined)
                        if (account.isAuthorizationRequired || state.requireAuth) {
                            setCurrenctAction(13);
                            return;
                        }
                        formkikProps.handleSubmit();




                    }} >

                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                                <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={
                                    {
                                        account: props.renderObject,
                                        operation: AuthorizedOperation.ADD_ACCOUNT_AUTHORIZER,
                                        parameters: [{
                                            key: "AuthorizedClient", value: state.clients?.selected.at(0)?.username,
                                            viewValue: state.clients?.selected.at(0)?.name + " (" + state.clients?.selected.at(0)?.username + ")"
                                        },
                                        {
                                            key: "Permesions", value: JSON.stringify(formkikProps.values.permessions), viewValue: formkikProps.values.permessions?.map((a: Number) => {
                                                return t(normalizeKey(getAccountAuthorizedPermissions().find(x => x.key == a)?.text ?? "" + a));
                                            }).join(',')
                                        },
                                        ],
                                    }

                                } onSuccess={(e) => {
                                    if (req && e.authCode) {
                                        if (currentAction == 13) {
                                            var r = new StringValue();
                                            r.setValue(e.authCode)
                                            req.setAuthcode(r)
                                            setCurrenctAction(0)

                                            formkikProps.handleSubmit();


                                        }
                                    } else {
                                        setCurrenctAction(0)
                                    }
                                }} onCancel={() => { setCurrenctAction(0) }} />
                            </Popup>

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("account")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                    isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                    moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                    suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                            getAccountsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("account"), required: true }}

                                    disabled={state.isLoading || props.renderObject ? true : false}
                                    selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountsSet())
                                        getAccountsReq.setSearch(undefined)
                                        getAccountsReq.setNextto(undefined)

                                        dispatch(setIsFilteredAccountSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onAccountsFilterChanged}
                                    endButtons={state.accounts.selected?.at(0)?.isAuthorizationRequired ? [
                                        {
                                            title: t("more"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101);
                                            }
                                        }
                                    ] : [
                                        {
                                            title: t("more"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101);
                                            }
                                        },
                                        {
                                            title: state.requireAuth ? t("operationAuthorizationEnabled") : t("operationAuthorizationDisabled"), checked: state.requireAuth, disabled: false, iconProps: { iconName: !state.requireAuth ? "Unlock" : "Lock" },
                                            onClick: () => {
                                                dispatch(setIsAuthorizationRequired(!state.requireAuth))
                                            }
                                        }
                                    ]}
                                />

                            </Stack> : undefined}
                            {props.type == FormType.ADD ? <Stack>

                                <Label required
                                    disabled={state.isLoading}>{t("authorizedClient")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('clients')}
                                    isLoading={state.clients.items.length == 0 ? state.clients.isFetching : false}
                                    isSearching={state.clients.items.length > 0 ? state.clients.isFetching : false}
                                    moreSuggestionsAvailable={state.clients.hasMore && !state.clients.isFetching}
                                    suggestions={state.clients.items.length > 0 ? state.clients.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource + "" + e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.clients.items.length > 0 && !state.clients.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.clients.items.at(state.clients.items.length - 1).internalId);
                                            clintsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.clients.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedUserItems([state.clients.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("authorizedClient"), required: true }}

                                    disabled={state.isLoading}
                                    selectedItems={state.clients.selected.length > 0 ? state.clients.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource + "" + e?.imagePath : undefined } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedUserItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetUsers())
                                        clintsReq.setSearch(undefined)
                                        clintsReq.setNextto(undefined)
                                        dispatch(setIsFilteredUsersSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onClientsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}
                            {props.type == FormType.ADD ?
                                <Stack>
                                    <Label
                                        disabled={state.isLoading}>{t("permissions")}</Label>
                                    <Dropdown
                                        placeholder={t("permissions")}
                                        selectedKeys={formkikProps.values.permessions}
                                        disabled={state.isLoading}
                                        onChange={(event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                            if (item) {
                                                formkikProps.setFieldValue("permessions",
                                                    item.selected ? [...formkikProps.values.permessions, item.key] : formkikProps.values.permessions.filter(key => key !== item.key)
                                                );
                                            }
                                        }}
                                        multiSelect
                                        options={getAccountAuthorizedPermissions().map(e => {
                                            return { key: e.key, text: t(normalizeKey(e.text)) }
                                        })}
                                    />
                                </Stack>

                                : undefined}


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

