import { Nav, Stack, initializeIcons, INavLinkGroup, IBreadcrumbItem, Breadcrumb, INavLink, ThemeProvider, Panel, Layer, Text, Link, Persona, PersonaSize, IconButton } from '@fluentui/react';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { getPage } from '../../../app/Pages';
import { NavHeader } from '../../common/NavHeader/NavHeader';
import { setTitle } from '../../common/NavHeader/NavHeaderSlice';
import { currentOrganization, navDrawerStyle, navigatorLayout, pageSectionStyle } from './MasterLayout.styles';
import { useBoolean } from '@fluentui/react-hooks';
import { Settings } from '../../common/Settings/Settings';
import i18n from '../../../app/i18n';
import { useTranslation } from 'react-i18next';
import { Int32Value } from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiMessage, API_URL, clearSession, getHeaders, getSession, ORGANIZATION_LOGO_URL, STORAGE_URL } from '../../../app/Api';
import { GetOrganizationRequest, GetOrganizationResponse } from '../../../repository/UserManagement/organization_pb';
import { getOrganization, rest, setOrganization } from './MasterLayoutSlice';
import { getCurrentOrganization, normalizeKey, setCurrentOrganization } from '../../../app/Helpers';
import { navHeaderStyle } from '../../common/NavHeader/NavHeader.styles';
import { SessionExpiredDialog } from '../../common/SessionExpiredDialog/SessionExpiredDialog';


const navLinkGroups = (t: any, org?: string): INavLinkGroup[] => {
  return [
    {
      links: [
        {
          name: t("dashboard"),
          url: '/' + (org ? '?org=' + org : ''),
          key: 'dashboard',
          title: t("dashboard"),
        },

        {
          name: t("finManagment"),
          url: '/currencies' + (org ? '?org=' + org : ''),
          expandAriaLabel: t("expandAriaLabelfinManagment"),
          collapseAriaLabel: t("collapseAriaLabelfinManagment"),
          title: t("finManagment"),
          links: [
            {
              name: t("currencies"),
              url: '/currencies' + (org ? '?org=' + org : ''),
              key: 'currencies',
              title: t("currencies"),
            },
            {
              name: t("fees"),
              url: '/fees' + (org ? '?org=' + org : ''),
              key: 'fees',
              title: t("fees"),
            },
            {
              name: t("limitations"),
              url: '/limitations' + (org ? '?org=' + org : ''),
              key: 'limitations',
              title: t("limitations"),
            },
            {
              name: t("organizationClientPortalSetting"),
              url: '/client-portal-settings' + (org ? '?org=' + org : ''),
              key: 'organizationClientPortalSetting',
              title: t("organizationClientPortalSetting"),
            },

          ],
          isExpanded: true,
        },

        {
          name: t("treasuriesManagement"),
          url: '/treasuries'+ (org ? '?org=' + org : ''),
          expandAriaLabel: t("expandAriaLabelTreasuries"),
          collapseAriaLabel: t("collapseAriaLabelTreasuries"),
          title: t("treasuriesManagement"),
          links: [
            {
              name: t("treasuries"),
              url: '/treasuries' + (org ? '?org=' + org : ''),
              key: 'treasuriesManagement/treasuries',
              title: t("treasuries"),
            },
            {
              name: t("treasuryTypes"),
              url: '/treasury-types' + (org ? '?org=' + org : ''),
              key: 'treasuriesManagement/treasuryTypes',
              title: t("treasuryTypes"),
            },

          ],
          isExpanded: true,
        },


        {
          name: t("customers"),
          url: '/customers/users' + (org ? '?org=' + org : ''),
          expandAriaLabel: t("expandAriaLabelCustomers"),
          collapseAriaLabel: t("collapseAriaLabelCustomers"),
          title: t("customers"),

          links: [
            {
              name: t("profiles"),
              url: '/customers/users' + (org ? '?org=' + org : ''),
              key: 'customers/users',
              title: t("profiles"),
            },
            {
              name: t("clientTypes"),
              url: '/customers/user-types' + (org ? '?org=' + org : ''),
              key: 'customers/usersTypes',
              title: t("clientTypes"),
            },
            {
              name: t("subscriptions"),
              url: '/customers/roles' + (org ? '?org=' + org : ''),
              key: 'customers/roles',
              title: t("subscriptions"),
            },
            {
              name: t("groups"),
              url: '/customers/groups' + (org ? '?org=' + org : ''),
              key: 'customers/groups',
              title: t("groups"),
            },
            {
              name: t("services"),
              url: '/customers/privileges' + (org ? '?org=' + org : ''),
              key: 'customers/privileges',
              title: t("services"),
            },


          ],
          isExpanded: true,
        },
        {
          name: t("accountsManagement"),
          url: '/accounts' + (org ? '?org=' + org : ''),
          expandAriaLabel: t("expandAriaLabelAccounts"),
          collapseAriaLabel: t("collapseAriaLabelAccounts"),
          title: t("accountsManagement"),
          links: [
            {
              name: t("accounts"),
              url: '/accounts' + (org ? '?org=' + org : ''),
              key: 'accountsManagement/accounts',
              title: t("accounts"),
            },
            {
              name: t("transactionsStatement"),
              url: '/transactions' + (org ? '?org=' + org : ''),
              key: 'accountsManagement/transactions',
              title: t("transactionsStatement"),
            },
            {
              name: t("accountTypes"),
              url: '/account-types' + (org ? '?org=' + org : ''),
              key: 'accountsManagement/accountTypes',
              title: t("accountTypes"),
            },
            {
              name: t("reservedBalances"),
              url: '/reserved-balances' + (org ? '?org=' + org : ''),
              key: 'accountsManagement/reservedBalances',
              title: t("reservedBalances"),
            },

          ],
          isExpanded: true,
        },
        {
          name: t("trading"),
          url: '/orders'+ (org ? '?org=' + org : ''),
          expandAriaLabel: t("expandAriaLabelTrading"),
          collapseAriaLabel: t("collapseAriaLabelTrading"),
          title: t("trading"),
          links: [
            {
              name: t("categories"),
              url: '/categories' + (org ? '?org=' + org : ''),
              key: 'trading/categories',
              title: t("categories"),
            },
            {
              name: t("currencyPairs"),
              url: '/currency-pairs' + (org ? '?org=' + org : ''),
              key: 'trading/currencyPairs',
              title: t("currencyPairs"),
            },
            {
              name: t("orders"),
              url: '/orders' + (org ? '?org=' + org : ''),
              key: 'trading/orders',
              title: t("orders"),
            },
            {
              name: t("tradingScreen"),
              url: '/chart' + (org ? '?org=' + org : ''),
              key: 'trading/chart',
              title: t("tradingScreen"),
            },

          ],
          isExpanded: true,
        },
        {
          name: t("reports"),
          url: '/reports/treasuryBalanceReport' + (org ? '?org=' + org : ''),
          expandAriaLabel: t("expandAriaLabelReports"),
          collapseAriaLabel: t("collapseAriaLabelReports"),
          title: t("reports"),
          links: [
            {
              name: t("treasuryBalanceReport"),
              url: '/reports/treasuryBalanceReport' + (org ? '?org=' + org : ''),
              key: 'reports/treasuryBalanceReport',
              title: t("treasuryBalanceReport"),
            },
            {
              name: t("accountBalanceReport"),
              url: '/reports/accountBalanceReport' + (org ? '?org=' + org : ''),
              key: 'reports/accountBalanceReport',
              title: t("accountBalanceReport"),
            },
            {
              name: t("transactionTotalsReport"),
              url: '/reports/transactionTotalsReport' + (org ? '?org=' + org : ''),
              key: 'reports/transactionTotalsReport',
              title: t("transactionTotalsReport"),
            },
            {
              name: t("currencyExchangeRatesReport"),
              url: '/reports/currencyExchangeRatesReport' + (org ? '?org=' + org : ''),
              key: 'reports/currencyExchangeRatesReport',
              title: t("currencyExchangeRatesReport"),
            },
            {
              name: t("tradingReport"),
              url: '/reports/tradingReport' + (org ? '?org=' + org : ''),
              key: 'reports/tradingReport',
              title: t("tradingReport"),
            },
          ],
          isExpanded: true,
        },
        {
          name: t("management"),
          url: '/management/users' + (org ? '?org=' + org : ''),
          expandAriaLabel: t("expandAriaLabelManagement"),
          collapseAriaLabel: t("collapseAriaLabelManagement"),
          title: t("management"),
          links: [
            {
              name: t("users"),
              url: '/management/users' + (org ? '?org=' + org : ''),
              key: 'management/users',
              title: t("users"),

            },
            {
              name: t("usersTypes"),
              url: '/management/user-types' + (org ? '?org=' + org : ''),
              key: 'management/usersTypes',
              title: t("usersTypes"),
            },
            {
              name: t("roles"),
              url: '/management/roles' + (org ? '?org=' + org : ''),
              key: 'management/roles',
              title: t("roles"),
            },
            {
              name: t("groups"),
              url: '/management/groups' + (org ? '?org=' + org : ''),
              key: 'management/groups',
              title: t("groups"),
            },
            {
              name: t("privileges"),
              url: '/management/privileges' + (org ? '?org=' + org : ''),
              key: 'management/privileges',
              title: t("privileges"),
            },



          ],
          isExpanded: true,
        },
        {
          name: t("logs"),
          url: '/logs' + (org ? '?org=' + org : ''),
          key: 'logs',
          title: t("logs"),
        },
        {
          name: t("organizations"),
          url: '/organizations' + (org ? '?org=' + org : ''),
          key: 'organizations',
          title: t("organizations"),
        },
      ],
    },
  ];
}

//let req = new GetOrganizationRequest();
//let getOrganizationPromise: any;
let org: number = -1;
let promise: any;

export const MasterLayout: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {

    let current = getSession();

    if (!current) {
      clearSession();
      navigate("/login");

    }

    var l = searchParams.get('org');
    if (l) {
      org = Number(l);
      if (Number.isNaN(org)) {
        org = -1;
      } else {
        if (state.organization == undefined || state.organization.id != org) {
          let getOrgReq = new GetOrganizationRequest()
          getOrgReq.setId(org)
          promise?.abort()
          promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
          promise.unwrap().catch((e: any) => {
            window.location.href = "/";
          })
        }
      }
    } else {
      if (state.organization == undefined && current.organization?.id) {

        let getOrgReq = new GetOrganizationRequest()
        getOrgReq.setId(current.organization?.id)
        promise?.abort()
        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
      }
      org = -1;
    }
    return () => { //clean up
      dispatch(rest())
      promise?.abort();
      org = -1;
    }
  }, [])


  const handleBreadcrumbItemClick = (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
    if (ev && item) {
      ev.preventDefault();
      if (item.href)
        navigate(item.href + ((org == -1) ? "" : "?org=" + org));
    }
  }

  const handleNavClick = (event?: React.MouseEvent<HTMLElement>, element?: INavLink) => {
    if (event && element) {
      event.preventDefault();
      navigate(element.url);
    }
  }
  const state: { pages: IBreadcrumbItem[], organization: any, message: ApiMessage | undefined } = useAppSelector((state) => {
    var list: IBreadcrumbItem[] = [];

    var item = state.masterLayout.breadCrumbItem;
    while (item) {
      list.push({ text: t(normalizeKey(item.title)), key: item.key, data: item.nav, href: "/" + item.path, onClick: handleBreadcrumbItemClick });
      if (item.parentKey)
        item = getPage(item.parentKey);
      else {
        item = undefined;
        list[0].href = undefined; // current page not clickable
        list[0].onClick = undefined; // current page not clickable
      }
    }
    return {
      pages: list.reverse(), organization: state.masterLayout.currentOrganization, message: state.masterLayout.message,
    };
  })


  return (
    <Stack verticalFill horizontal >
      {state.message != undefined ? (state.message.data != 401) ? undefined :
        <SessionExpiredDialog />
        : null
      }
      <Settings />
      <Stack styles={navigatorLayout}>

        <Stack.Item grow styles={{ root: { overflow: 'auto' } }}>
          <Nav ariaLabel={t("breadcrumbAria")} styles={navDrawerStyle} groups={navLinkGroups(t, org == -1 ? undefined : org + "")} onLinkClick={handleNavClick} selectedKey={(state.pages.length > 0) ? state.pages[state.pages.length - 1].data : undefined} />
        </Stack.Item>
      </Stack>
      <Stack grow >

        <NavHeader />

        <Stack styles={pageSectionStyle}  >
          <Breadcrumb
            items={state.pages}
            ariaLabel={t("breadcrumbAria")}
            overflowAriaLabel={t("breadcrumbOverFlowAria")}
            focusZoneProps={{ handleTabKey: 1 }}
            styles={{ root: { padding: '0px 3% 0px 3%' } }}
          />

          <Outlet />

        </Stack>
      </Stack>
    </Stack >

  )
};
